var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('v-card',{staticStyle:{"border-radius":"0","background":"rgba(255, 255, 255, 0)","overflow":"hidden","height":"inherit"},attrs:{"flat":""}},[(_vm.eventParsed.input.createdByCustomer)?_c('div',{staticStyle:{"position":"relative"}},[_c('v-icon',{staticStyle:{"position":"absolute","right":"0","top":"0"},attrs:{"small":"","color":"white"}},[_vm._v("star")])],1):_vm._e(),(
      _vm.eventParsed.input.adminNote != null &&
      _vm.eventParsed.input.adminNote.length > 0
    )?_c('div',{staticStyle:{"position":"relative"}},[_c('v-icon',{staticStyle:{"position":"absolute","right":"15px","top":"0"},attrs:{"small":"","color":"white"}},[_vm._v("note")])],1):_vm._e(),(
      _vm.eventParsed.input.comment != null &&
      _vm.eventParsed.input.comment.length > 0
    )?_c('div',{staticStyle:{"position":"relative"}},[_c('v-icon',{staticStyle:{"position":"absolute","right":"30px","top":"0"},attrs:{"small":"","color":"white"}},[_vm._v("message")])],1):_vm._e(),_c('v-row',{staticClass:"pr-3",attrs:{"justify":"start"}},[_c('v-col',{attrs:{"cols":"auto"}},[_c('div',{staticStyle:{"margin-left":"5px"}},[_c('span',{staticStyle:{"color":"white","font-weight":"bold"}},[_vm._v(" "+_vm._s(_vm.getFirstWordOfString(_vm.event.name))+" ")])])]),_c('v-spacer')],1),(_vm.user.showTimeInEvent)?_c('div',[_c('span',{staticStyle:{"color":"white","margin-left":"5px"}},[_vm._v(_vm._s(_vm.formatTimeWithFormatForEvent(_vm.eventParsed.start) + "-" + _vm.formatTimeWithFormatForEvent(_vm.eventParsed.end)))])]):_vm._e(),(_vm.user.showServiceNameInEvent && !_vm.eventParsed.input.isCustom)?_c('div',{staticStyle:{"margin-left":"5px"}},[_c('span',{staticStyle:{"color":"white"}},[_vm._v(_vm._s(_vm.eventParsed.input.appointmentName))])]):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }