
import Vue from "vue";
import Component from "vue-class-component";
import { State, Getter, Action, Mutation, namespace } from "vuex-class";
import { Prop, Watch } from "vue-property-decorator";
import { IStaffMemberDTO } from "../../../../types/AutoGenerated/reinforcedTypings";
import { IStaffMemberPatch } from "../../../../store/staffMember";
import CompanyInfoField from "../../../global/CompanyInfoField.vue";
import Textfield from "../../../global/Textfield.vue";

@Component({
  name: "StaffMemberDetailsSettings",
  components: { CompanyInfoField, Textfield },
})
export default class StaffMemberDetailsSettings extends Vue {
  @Prop({}) staffMember!: IStaffMemberDTO;

  @Action patchStaffMember: (patch: IStaffMemberPatch) => Function;

  public editingStaffMember: Partial<IStaffMemberDTO> = {};
  public localStaffMember: IStaffMemberDTO | null = null;
  public isSaving: boolean = false;
  public isLoading: boolean = false;
  public color: string = "#4CAF50";
  public error: string | null = null;
  public isInitializing: boolean = true;

  @Getter user;
  @Getter public staffMembers;
  @Action public getStaffMembers: Function;

  @Watch("editingStaffMember", { deep: true })
  async onEditingStaffMemberChange() {
    if (!this.localStaffMember || this.isInitializing) return;
    await this.saveChanges();
  }

  @Watch("color")
  async onColorChange() {
    if (!this.localStaffMember || this.isInitializing) return;
    await this.saveChanges();
  }

  async saveChanges() {
    if (!this.localStaffMember) return;

    try {
      this.isSaving = true;
      let edit = { ...this.editingStaffMember, color: this.color };

      let patch: IStaffMemberPatch = {
        staffMemberId: this.localStaffMember.id,
        patch: edit,
      };
      await this.patchStaffMember(patch);
    } catch (err) {
      console.error("Error saving changes:", err);
      this.error = "Failed to save changes";
    } finally {
      this.isSaving = false;
    }
  }

  async mounted() {
    this.isLoading = true;
    try {
      if (this.staffMember) {
        // If staffMember is provided as prop, use it directly
        this.localStaffMember = this.staffMember;
        this.initializeData();
      } else {
        // Otherwise, try to get it from route params
        const staffId = this.$route.params.staffId;
        if (!staffId) {
          throw new Error("No staff member ID provided");
        }

        if (this.staffMembers.length === 0) {
          await this.getStaffMembers();
        }

        this.localStaffMember = this.staffMembers.find(
          (staff) => staff.id == staffId
        );

        if (!this.localStaffMember) {
          throw new Error("Staff member not found");
        }

        this.initializeData();
      }
    } catch (err) {
      this.error = err.message || "Failed to load staff member";
      console.error("Error loading staff member:", err);
    } finally {
      this.isLoading = false;
      this.$nextTick(() => {
        this.isInitializing = false;
      });
    }
  }

  initializeData() {
    if (!this.localStaffMember) return;

    this.color = this.localStaffMember.color || "#4CAF50";
    this.editingStaffMember = {
      phoneNumber: this.localStaffMember.phoneNumber,
      emailAddress: this.localStaffMember.emailAddress,
      firstName: this.localStaffMember.firstName,
      lastName: this.localStaffMember.lastName,
    };
  }

  get title() {
    return this.localStaffMember
      ? `${this.localStaffMember.firstName} ${this.localStaffMember.lastName}`
      : "";
  }

  get phone() {
    return this.localStaffMember?.phoneNumber || "";
  }

  get email() {
    return this.localStaffMember?.emailAddress || "";
  }
}
