
import { Component, Vue, Watch } from "vue-property-decorator";
import { Action, Getter, Mutation } from "vuex-class";
import muscleSelector from "../../components/global/muscleGroupSelector.vue";
import DatePicker from "../../components/global/DatePicker.vue";
import CustomInput from "../../components/global/styledComponents/CustomInput.vue";
import CustomButton from "../../components/global/styledComponents/customButton.vue";
import { IDialog } from "../../store/dialog";
import Loader from "../../components/global/Loader.vue";
import CustomerModal from "../../components/global/CustomerModal.vue";
import EditCustomer from "../../components/global/EditCustomer.vue";
import CustomerModalWindow from "../../components/global/CustomerModalWindow.vue";
import { getApiUrl, getStripePublicKey } from "../../main";
import { getFbConfig } from "../../Utilities/authConfig";
import firebase from "firebase/app";
import { getRequest } from "../../Utilities/axiosHelper";
import { formatDateStringHour } from "../../Utilities/dateUtility";

@Component({
  name: "DebugOverview",
  components: {
    CustomerModalWindow,
    EditCustomer,
    CustomerModal,
    Loader,
    CustomButton,
    CustomInput,
    DatePicker,
    muscleSelector,
  },
})
export default class DebugOverview extends Vue {
  public headers = [
    { text: "", align: "start", sortable: true, value: "severity" },
    { text: "Beskjed", sortable: true, value: "message" },
    { text: "Tid", sortable: true, value: "time" },
    { text: "Details", align: "start", sortable: true, value: "innerMessage" },
    {
      text: "Stack trace",
      align: "start",
      sortable: true,
      value: "stackTrace",
    },
  ];

  @Getter public user;
  @Getter public firebaseUsers;
  @Action public getFirebaseUsersAsAdmin: Function;
  @Action public addToDialogQueue: Function;
  @Action public getDebugEvents: Function;

  public customToken: string = "";

  isLoading: boolean = true;
  events = [];
  selectedEvents = [];

  statusFilters = ["Info", "Warning", "Error", "Critical"];

  get invoiceStatuses() {
    return ["Info", "Warning", "Error", "Critical"];
  }

  public async created(): Promise<any> {
    this.getFirebaseUsersAsAdmin();
    this.events = await this.getDebugEvents();
    this.isLoading = false;
  }

  getColorChip(eventId) {
    if (eventId == 0) return "blue";
    if (eventId == 1) return "orange";
    if (eventId == 2) return "red lighten-2";
    if (eventId == 3) return "red";
  }

  getLabel(eventId) {
    if (eventId == 0) return "Info";
    if (eventId == 1) return "Warning";
    if (eventId == 2) return "Error";
    if (eventId == 3) return "Critical";
  }
  eventIdToString(eventId) {
    if (eventId == 0) {
      return "Debug log";
    }
  }
  formatDateStringHour(date: Date) {
    return formatDateStringHour(date);
  }

  get eventsForTable() {
    return this.events.filter((x) => {
      return (
        this.statusFilters.includes(this.getLabel(x.eventId)) ||
        x.eventId == null
      );
    });
  }

  getColor(eventId) {
    if (eventId == "Info") return "blue";
    if (eventId == "Warning") return "orange";
    if (eventId == "Error") return "red lighten-2";
    if (eventId == "Critical") return "red";
    return "grey";
  }

  readComment(comment) {
    let dialog: IDialog = {
      text: comment,
      fullScreen: true,
      html: true,
    };
    this.addToDialogQueue(dialog);
  }

  get stripePublishKey() {
    return getStripePublicKey();
  }
  get apiUrl() {
    return getApiUrl();
  }
  get firebaseConfig() {
    return getFbConfig();
  }

  loginWithCustomToken() {
    firebase
      .auth()
      .signInWithCustomToken(this.customToken)
      .then((userCredential) => {
        // Signed in
        let user = userCredential.user;
        // ...
      })
      .catch((error) => {
        let errorCode = error.code;
        let errorMessage = error.message;

        let dialog: IDialog = {
          text: errorMessage,
        };
        this.addToDialogQueue(dialog);
        // ...
      });
  }

  async attemptAuthentication(uid) {
    this.customToken = await getRequest("Admin/token/admin?uid=" + uid);
    this.loginWithCustomToken();
  }
}
