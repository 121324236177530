
import Vue from "vue";
import Component from "vue-class-component";
import { logout } from "../../Utilities/authConfig";
import { State, Getter, Action, Mutation, namespace } from "vuex-class";
import { DialogMutations, DialogType, IDialog } from "../../store/dialog";
import firebaseAuth from "firebase/app";

@Component({
  name: "PersonalAccountSettings",
  components: {},
})
export default class PersonalAccountSettings extends Vue {
  @Action public addToDialogQueue: Function;
  @Getter public firebaseUser;
  @Getter public firebaseUserAccount;

  created() {}

  getRoleDisplayName(role) {
    if (role == 0) {
      return this.$t("role.administrator").toString();
    }
    if (role == 1) {
      return this.$t("role.user").toString();
    }
  }

  logoutConfirm() {
    let dialog: IDialog = {
      text: this.$t("logoutConfirm").toString(),
      action: logout,
      header: this.$t("logout").toString(),
      type: DialogType.Choice,
    };

    this.addToDialogQueue(dialog);
  }

  public forgot(): void {
    let dialog: IDialog = {
      type: DialogType.Choice,
      text: this.$t("forgotPassword", {
        email: this.firebaseUser.email,
      }).toString(),
      action: this.forgotYes,
    };
    this.addToDialogQueue(dialog);
  }

  forgotYes() {
    let vm = this;
    firebaseAuth
      .auth()
      .sendPasswordResetEmail(this.firebaseUser.email)
      .then(function () {
        vm.addToDialogQueue({
          text: vm.$t("passwordResetEmailSent").toString(),
        });
      })
      .catch(function (error) {
        vm.addToDialogQueue({ text: error.message });
      });
  }
}
