
import Vue from "vue";
import { Component, Prop, Watch } from "vue-property-decorator";
import { Action, Getter } from "vuex-class";
import { SnackbarObject } from "../../store/snackbar";
import Loader from "../global/Loader.vue";

@Component({
  name: "AppSnackbar",
  components: { Loader },
})
export default class AppSnackbar extends Vue {
  @Getter public snackbar!: SnackbarObject;
  @Action public postSnackbarMessage!: Function;

  isShowingSnackbar = false;
  snackbarDuration = 4000;
  snackbarTimeout: number | undefined;

  get displayingSnackbar(): SnackbarObject {
    return this.snackbar;
  }

  @Watch("snackbar")
  onChangeSnackbar(newVal: SnackbarObject): void {
    if (!newVal.message) return;

    this.isShowingSnackbar = true;

    if (this.snackbarTimeout != null) {
      clearTimeout(this.snackbarTimeout);
    }

    this.snackbarTimeout = setTimeout(() => {
      this.postSnackbarMessage(null);
      this.isShowingSnackbar = false;
    }, this.snackbarDuration);
  }
}
