
import { Component, Vue, Watch } from "vue-property-decorator";
import { Action, Getter, Mutation } from "vuex-class";
import router from "../../router";
import { IUser } from "../../store/user";
import { logout } from "@/Utilities/authConfig";
import { DialogType, IDialog } from "../../store/dialog";
import NotificationBell from "./NotificationBell.vue";

@Component({ name: "sidebar", components: { NotificationBell } })
export default class Sidebar extends Vue {
  public itemlist: Array<Object> = [
    {
      alt: "home",
      text: "home",
      image: "../img/dashboard.png",
      route: "/admin",
      icon: "home",
    },
    {
      alt: "customers",
      text: "customers",
      image: "../img/customers.png",
      route: "/kunder",
      icon: "account_circle",
    },
    {
      alt: "calendar",
      text: "calendar",
      image: "../img/calendars.png",
      route: "/kalender",
      icon: "calendar_today",
    },
    {
      alt: "settings",
      text: "settings",
      onlySmallMenu: true,
      image: "../img/settings.png",
      route: "/settings",
      icon: "calendar_today",
    },
  ];

  get appointmentTabs() {
    if (this.user == null) {
      return [];
    }
    if (this.user.enablePaymentModule) {
      return [
        {
          alt: "overview",
          children: [],
          text: "overview",
          image: "../img/overview.png",
          route: "/bestillinger",
          icon: "event_note",
        },
        {
          alt: "payments",
          text: "payments",
          image: "../img/paymenticon.png",
          route: "/betalinger",
          icon: "event_note",
        },
        {
          alt: "invoices",
          text: "invoices",
          image: "../img/commerce.png",
          route: "/faktura",
          icon: "event_note",
        },
        {
          alt: "statistics",
          text: "statistics",
          image: "../img/increasing-chart.png",
          route: "/statistikk",
          icon: "insert_chart",
        },
        {
          alt: "export",
          text: "export",
          image: "../img/export.png",
          route: "/eksporter",
          icon: "calendar_today",
        },
      ];
    } else {
      return [
        {
          alt: "overview",
          children: [],
          text: "overview",
          image: "../img/overview.png",
          route: "/bestillinger",
          icon: "event_note",
        },
        {
          alt: "statistikk",
          text: "statistics",
          image: "../img/increasing-chart.png",
          route: "/statistikk",
          icon: "insert_chart",
        },
        {
          alt: "eksporter",
          text: "export",
          image: "../img/export.png",
          route: "/eksporter",
          icon: "calendar_today",
        },
      ];
    }
  }

  public showListGroup = false;

  @Action public getCurrentUser: Function;
  @Action public addToDialogQueue: Function;
  @Action public getUnseenEvents: Function;
  @Getter public unseenEvents: Function;
  @Mutation public setMini: Function;
  @Getter public user: IUser;

  @Getter public firebaseUserAccount;

  @Getter mini: Boolean;

  public drawer: boolean = true;
  // public mini:boolean = false;

  // @Getter(getterStringUser.HAS_SUBSCRIPTION) hasSubscription:boolean;
  // @Mutation(mutationStringUser.SET_HAS_SUBSCRIPTION) setHasSubscription:(payload:boolean) => void;

  get getSidebarWidth() {
    if (this.mini) {
      return 80;
    } else {
      return 256;
    }
  }

  toggleMini() {
    this.setMini(!this.mini);

    if (this.mini) {
      localStorage.setItem("sidebarmini", "true");
    } else {
      localStorage.setItem("sidebarmini", "false");
    }
  }

  get displayProfilePicture() {
    return this.user != null && this.user.profilePictureUrl != null;
  }

  openChat(): void {
    window.open("http://m.me/availify/", "_blank");
  }

  public goToSettingsPage(): void {
    this.$router.push("/settings");
  }

  public async created(): Promise<void> {
    let defaultMini = localStorage.getItem("sidebarmini");
    if (defaultMini != null) {
      if (defaultMini == "true") {
        this.setMini(true);
      } else {
        this.setMini(false);
      }
    }
  }

  @Watch("$route")
  onChangeRoute(newRoute, oldroute) {
    if (
      newRoute.name == "Bestillinger" ||
      newRoute.name == "Bestilling" ||
      newRoute.name == "Faktura" ||
      newRoute.name == "Statistikk" ||
      newRoute.name == "Betalinger" ||
      newRoute.name == "Eksporter"
    ) {
      this.showListGroup = true;
    } else {
      this.showListGroup = false;
    }
  }
}
