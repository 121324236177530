import { Workbox } from "workbox-window";

let wb;
if ("serviceWorker" in navigator) {
  wb = new Workbox(`${process.env.BASE_URL}service-worker.js`);

  wb.addEventListener("waiting", () => {
    // Automatically skip waiting and activate the new service worker
    wb.messageSW({ type: "SKIP_WAITING" });
  });

  wb.addEventListener("controlling", () => {
    // Reload the page to apply the new service worker immediately
    window.location.reload();
  });

  wb.register();
} else {
  wb = null;
}

export default wb;
