
import { Component, Vue } from "vue-property-decorator";
import HeaderBar from "../../components/global/MainHeader.vue";
import MonthlyEarnings from "@/components/global/statistics/MonthlyEarned.vue";
import CommitChart from "../../components/global/CommitChart.vue";
import { Action, Getter } from "vuex-class";
import {
  actionStringStatistics,
  IEarnedThisAndPreviousYear,
  getterStringStatistics,
} from "../../store/statistics";
import Calendar from "@/abstract-classes/Calendar";
import {
  actionStringCustomers,
  getterStringCustomers,
} from "@/store/customers";
import Loader from "@/components/global/Loader.vue";
import { castMonthFromNumberToStringName } from "@/types/formatDateHelper";
import ServiceStats from "@/components/global/ServiceStats.vue";
import { IServiceStat } from "@/types/AutoGenerated/reinforcedTypings";

//@ts-ignore
@Component({
  name: "statistics",
  components: { ServiceStats, Loader, HeaderBar, MonthlyEarnings, CommitChart },
})
export default class statistics extends Calendar {
  isLoading: boolean = false;
  currentMonth: string = "";

  @Action(actionStringStatistics.GET_EARNED_THIS_AND_PREVIOUS_YEAR)
  getEarnedThisAndPreviousYear: () => Promise<IEarnedThisAndPreviousYear>;
  @Getter(getterStringStatistics.EARNED_THIS_AND_PREVIOUS_YEAR)
  earnedThisAndPreviousYear: IEarnedThisAndPreviousYear;
  @Getter(getterStringStatistics.MONEY_MADE) moneyMade: number;
  @Getter(getterStringStatistics.APPOINTMENTS_DONE) appointmentsDone: number;
  @Getter(getterStringCustomers.CUSTOMER_AMOUNT) customerAmount: number;
  @Action(actionStringStatistics.GET_MONEY_MADE) getMoneyMade: (
    currentMonth: number
  ) => Promise<number>;
  @Action(actionStringStatistics.GET_APPOINTMENTS_DONE) getAppointmentsDone: (
    currentMonth: number
  ) => Promise<number>;
  @Action(actionStringCustomers.GET_CUSTOMER_AMOUNT)
  getCustomerAmount: () => Promise<number>;
  @Action("getServiceStats") getServiceStats: () => Promise<
    Array<IServiceStat>
  >;
  @Action("getCustomerStats") getCustomerStats: () => Promise<Array<any>>;
  @Action public downloadAppointments: Function;

  @Getter serviceStats;
  @Getter customerStats;

  headers: any[] = [];
  customerHeaders: any[] = [];

  async downloadReport() {
    let data = await this.downloadAppointments();
    const url = window.URL.createObjectURL(new Blob([data]));

    const link = document.createElement("a");
    link.href = url;
    link.setAttribute("download", "rapport.xlsx"); //or any other extension
    document.body.appendChild(link);
    link.click();
  }

  async created(): Promise<any> {
    this.headers = [
      { text: this.$t("statistics.serviceName"), value: "serviceName" },
      { text: this.$t("statistics.bookings"), value: "bookings" },
    ];
    this.customerHeaders = [
      { text: this.$t("statistics.customers"), value: "customers" },
    ];
    this.isLoading = true;

    const p5 = this.getServiceStats();
    //  const p6 = this.getCustomerStats();
    const p1 = this.getMoneyMade((this.currMonth + 1) as number);
    const p2 = this.getAppointmentsDone((this.currMonth + 1) as number);
    const p3 = this.getEarnedThisAndPreviousYear();
    const p4 = this.getCustomerAmount();

    this.currentMonth = castMonthFromNumberToStringName(this.currMonth + 1);

    let allPromsises = await Promise.all([p1, p2, p3, p4, p5]);
    this.isLoading = false;
  }
}
