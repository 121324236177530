
import { Component, Vue, Prop } from "vue-property-decorator";
import { Getter } from "vuex-class";
import type { IAppointmentTypeDTO } from "@/types/AutoGenerated/reinforcedTypings";
import { DialogMutations, DialogType, type IDialog } from "@/store/dialog";
import TreatmentTypeForm from "./components/TreatmentTypeForm.vue";
import TreatmentTypeTable from "./components/TreatmentTypeTable.vue";
import Loader from "@/components/global/Loader.vue";

interface Category {
  id: string;
  title: string;
}

@Component({
  name: "treatment-types",
  components: {
    Loader,
    TreatmentTypeForm,
    TreatmentTypeTable,
  },
})
export default class TreatmentTypes extends Vue {
  @Prop({ default: false }) public isOnboarding!: boolean;
  @Prop({ default: false }) public extra!: boolean;

  @Getter public extraServices!: IAppointmentTypeDTO[];

  private isLoading = true;
  private showForm = false;
  private editingService: Partial<IAppointmentTypeDTO> | null = null;
  private isEditing = false;
  private isCreating = false;

  get services(): IAppointmentTypeDTO[] {
    const result = this.extra
      ? this.$store.getters.extraServices
      : this.$store.getters.appointmentType;
    return result || [];
  }

  get categories(): Category[] {
    const cats = this.$store.getters.categories || [];
    return cats.map((cat) => ({
      id: String(cat.id),
      title: cat.title,
    }));
  }

  get staffMembers() {
    return this.$store.getters.staffMembers || [];
  }

  get hasMultipleStaff() {
    return this.staffMembers.length > 1;
  }

  async loadData() {
    this.isLoading = true;
    try {
      await this.$store.dispatch("getCurrentUser");
      if (!this.services.length) {
        await this.$store.dispatch("getAppointmentTypes", this.extra);
        if (!this.extra) {
          await this.$store.dispatch("getCategories");
        }
      }
    } finally {
      this.isLoading = false;
    }
  }

  toggleForm() {
    this.editingService = { discountPrice: -1 };
    this.isCreating = true;
    this.showForm = !this.showForm;
  }

  startEdit(service: IAppointmentTypeDTO) {
    this.isEditing = true;
    this.editingService = { ...service };
    this.showForm = true;
  }

  cancelEdit() {
    this.isEditing = false;
    this.isCreating = false;
    this.editingService = null;
    this.showForm = false;
  }

  async saveTreatment(treatment: Partial<IAppointmentTypeDTO>) {
    this.isLoading = true;
    try {
      if (this.isEditing) {
        await this.$store.dispatch("editAppointmentType", treatment);
      } else {
        treatment.isExtraService = this.extra;
        await this.$store.dispatch("addNewAppointmentType", treatment);
      }
      this.cancelEdit();
    } finally {
      this.isLoading = false;
    }
  }

  confirmDelete(id: string) {
    const dialog: IDialog = {
      text: this.$t("dialog.confirmDeleteService").toString(),
      type: DialogType.Choice,
      header: this.$t("dialog.deleteServiceHeader").toString(),
      action: () => this.deleteTreatment(id),
    };
    this.$store.commit(DialogMutations.AddToDialogQueue, dialog);
  }

  async deleteTreatment(id: string) {
    await this.$store.dispatch("deleteAppointmentType", id);
  }

  async reorderTreatment(payload: { serviceId: string; direction: number }) {
    await this.$store.dispatch("changeServiceOrder", {
      serviceId: payload.serviceId,
      order: payload.direction,
    });
  }

  async updateCategory(payload: {
    serviceId: string;
    categoryId: string;
    order: number;
  }) {
    await this.$store.dispatch("changeCategoryOfService", {
      ...payload,
      categoryId: Number(payload.categoryId),
    });
  }

  async updateExtraServices(payload: {
    serviceId: string;
    extraServices: IAppointmentTypeDTO[];
  }) {
    await this.$store.dispatch("changeExtraService", payload);
  }

  async created() {
    await this.loadData();
  }
}
