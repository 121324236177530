"use strict";
/**
 * @param someDate | string | Date
 * @param formatSpaceType | FormatSpaceType | undefined
 **/

import { i18n } from "@/main";

export enum FormatSpaceType {
  DOT = "dot",
  LINE = "line",
  SLASH = "slash",
}

export function formatDate(
  someDate: string | Date,
  formatSpaceType?: FormatSpaceType
): string {
  let formattedDateAsString: string = "";
  let parsedDate: string = someDate.toString();
  let date: number = new Date(parsedDate).getDate();
  let month: number = new Date(parsedDate).getMonth() + 1;
  let year: number = new Date(parsedDate).getFullYear();
  let addedZeroToDate = date < 10 ? "0" : "";
  let addedZeroToMonth = month < 10 ? "0" : "";

  if (typeof formatSpaceType === "undefined") {
    formatSpaceType = FormatSpaceType.DOT;
    formattedDateAsString =
      addedZeroToDate + date + "." + addedZeroToMonth + month + "." + year;
  }

  if (formatSpaceType === FormatSpaceType.DOT) {
    formattedDateAsString =
      addedZeroToDate + date + "." + addedZeroToMonth + month + "." + year;
  }

  if (formatSpaceType === FormatSpaceType.LINE) {
    formattedDateAsString =
      addedZeroToDate + date + "-" + addedZeroToMonth + month + "-" + year;
  }

  if (formatSpaceType === FormatSpaceType.SLASH) {
    formattedDateAsString =
      addedZeroToDate + date + "/" + addedZeroToMonth + month + "/" + year;
  }

  return formattedDateAsString;
}

export function formatTime(
  hour: string | number,
  minute: string | number
): string {
  let formattedTimeAsString: string = "";
  let localHour: number;
  let localMinute: number;

  //cast to number
  localHour = hour as number;
  localMinute = minute as number;

  //Add zero logic
  let addedZeroToHour = localHour < 10 ? "0" : "";
  let addedZeroToMinute = localMinute < 10 ? "0" : "";

  formattedTimeAsString =
    addedZeroToHour + localHour + ":" + addedZeroToMinute + localMinute;

  return formattedTimeAsString;
}

export function castMonthFromNumberToStringName(dateNumber: number): string {
  let monthAsString: string = "";

  switch (dateNumber) {
    case 1:
      monthAsString = i18n.t("months.january").toString();
      break;
    case 2:
      monthAsString = i18n.t("months.february").toString();
      break;
    case 3:
      monthAsString = i18n.t("months.march").toString();
      break;
    case 4:
      monthAsString = i18n.t("months.april").toString();
      break;
    case 5:
      monthAsString = i18n.t("months.may").toString();
      break;
    case 6:
      monthAsString = i18n.t("months.june").toString();
      break;
    case 7:
      monthAsString = i18n.t("months.july").toString();
      break;
    case 8:
      monthAsString = i18n.t("months.august").toString();
      break;
    case 9:
      monthAsString = i18n.t("months.september").toString();
      break;
    case 10:
      monthAsString = i18n.t("months.october").toString();
      break;
    case 11:
      monthAsString = i18n.t("months.november").toString();
      break;
    case 12:
      monthAsString = i18n.t("months.december").toString();
      break;
    default:
      monthAsString = ""; // Handle invalid month numbers
  }

  return monthAsString;
}
