export default {
  welcome: "Welcome",
  months: {
    january: "January",
    february: "February",
    march: "March",
    april: "April",
    may: "May",
    june: "June",
    july: "July",
    august: "August",
    september: "September",
    october: "October",
    november: "November",
    december: "December",
  },
  days: {
    sunday: "Sun",
    monday: "Mon",
    tuesday: "Tue",
    wednesday: "Wed",
    thursday: "Thu",
    friday: "Fri",
    saturday: "Sat",
  },
  agendaTitle: "Agenda",
  update: {
    title: "Update",
  },
  subscriptionWarnings: {
    message1:
      "Hello! You have now tried the program for free for 14 days. We hope you find it useful.",
    message2:
      "If you wish to continue using the program, please purchase a subscription in 'Settings' -> 'Subscription'",
    message3: "If you have any questions, please contact us. Thank you!",
  },
  buttons: {
    gotoSubscription: "Go to Subscription",
    close: "Close",
    ok: "Ok",
    save: "Save",
    delete: "Delete",
    cancel: "Cancel",
    edit: "Edit",
    create: "Create",
  },

  noAppointmentsToday: "No appointments today!",
  readNotes: "Read notes",
  readCustomerComment: "Read customer's comment",
  dialog: {
    customerComment: "Customer's Comment",
    notes: "Notes",
    confirmDeleteService: "Are you sure you want to delete this service?",
    deleteServiceHeader: "Delete Service",
    cancel: "Cancel",
    save: "Save",
    confirm: "OK",
    no: "No",
    yes: "Yes",
  },
  messages: {
    updated: "Updated.",
    categoryChanged: "Service category has been changed.",
    categoryOrderChanged: "Category order has been changed.",
    serviceOrderChanged: "Service order has been changed.",
    categoryUpdated: "Category has been updated.",
    newCategoryAdded: "New category has been added.",
    categoryDeleted: "Category has been deleted.",
    newServiceAdded: "New service has been added.",
    couldNotAddService: "Could not add new service.",
    serviceDeleted: "Service has been deleted.",
    serviceUpdated: "Service has been updated.",
  },
  staff: "Staff",
  date: "Date",
  service: "Service",
  price: "Price",
  customerAccount: "Customer Account",
  automaticAssignExistingCustomerAccount:
    "Automatically assign existing customer account to new bookings based on field.",
  createAssignCustomerAccountAutomatically:
    "Create/Assign customer account automatically upon booking",
  customerAccountWillBeAssignedBasedOnPhoneNumber:
    "Customer account will be assigned to existing based on phone number.",
  newCustomField: "New Custom Field",
  selectFieldsCustomerFillsWhenBooking:
    "Select fields the customer fills out when booking",
  optional: "Optional",
  customFields: "Custom Fields",
  add: "Add",
  link: "Link",
  yourBookingLinkWillBe: "Your booking link will be {link}",
  connectSystemWithCommonBookingLink:
    "Connect the system with a common booking link",
  yourCommonBookingLinkWillBe: "Your common booking link will be {link}",
  bookingID: "Booking ID",
  commonID: "Common ID",
  locationName: "Location Name",
  showInformationBox: "Show Information Box",
  shouldInformationBoxBeDisplayed:
    "Should the information box be displayed on the booking page?",
  informationBox: "Information Box",
  informationForCustomerOnBookingPage:
    "Information for the customer on the front page of the booking page",
  cancellationInformation: "Cancellation Information",
  customizeInformationCustomerSeesWhenCancelling:
    "Customize the information the customer sees when cancelling",
  receiveEmailOnAppointment: "Receive Email on Appointment",
  doYouWantToReceiveEmailOnNewAppointment:
    "Do you want to receive an email when a new appointment is received?",
  whichEmailAddressToReceive:
    "Which email address should receive the email upon booking?",
  receiveSMSOnAppointment: "Receive SMS on Appointment",
  doYouWantToReceiveSMSOnNewAppointment:
    "Do you want to receive an SMS when a new appointment is received?",
  whichPhoneNumberToReceiveSMS:
    "Which phone number should receive the SMS upon booking?",
  ssn: "Social Security Number",
  birthdate: "Birthdate",
  gender: "Gender",
  city: "City",
  postalCode: "Postal Code",
  pause: "Pause",
  event: "Event",
  from: "From",
  to: "To",
  durationMismatch:
    "The duration of the booking differs from the service duration.",
  priceMismatch: "The price of the booking differs from the service price.",
  cancel: "Cancel",
  create: "Create",
  confirm: "Confirm",
  invalidTimeFormat: "Invalid time format",
  enterCustomerName: "You must enter a customer name.",
  selectService: "You must select a service.",
  editEndTimeWithoutStart:
    "Attempted to edit end time without setting start time.",
  minutesAbbreviation: "min",
  currency: "USD",
  creationCancelled: "Creation cancelled.",
  customEvent: "Custom event",
  monthlyEarningsTitle: "Earnings for {month}",
  totalCustomers: "Total Customers",
  earningsTrend: "Earnings Trend",
  servicesBooked: "Services Booked",
  moneyEarnedIn: "Earnings in {month}",
  appointmentsIn: "Appointments in {month}",
  appointmentBookingLinkTitle: "Appointment Booking Link",
  appointmentBookingLinkDescription:
    "Use the link below to book an appointment.",
  bookingLinkLabel: "Booking Link",
  copyLinkButton: "Copy Link",
  linkCopiedMessage: "Link copied to clipboard!",
  closePreview: "Close Preview",
  previewBookingPage: "Preview Booking Page",
  contactUs: "Contact Us",
  sidebar: {
    home: "Home",
    customers: "Customers",
    calendar: "Calendar",
    settings: "Settings",
    appointments: "Appointments",
    overview: "Overview",
    payments: "Payments",
    invoices: "Invoices",
    statistics: "Statistics",
    export: "Export",
    contactSupport: "Contact Support",
    log: "Log",
    notifications: "Notifications",
    journal: "Journal",
  },
  name: "Name",
  email: "Email",
  phoneNumber: "Phone Number",
  address: "Address",
  postalCodeCity: "Postal Code/City",
  edit: "Edit",
  created: "Created",
  deleteSelectedCustomers: "Delete selected customers",
  search: "Search",
  searchForCustomer: "Search for customer",
  noCustomersYet: "You have no customers yet",
  noSearchResults: "No search results",
  customerWithoutName: "Customer without name",
  confirmDeleteCustomers: "Are you sure you want to delete {count} customers?",
  deleteCustomerHeader: "Delete customer(s)?",
  customersDeleted: "{count} customers have been deleted",
  customer: {
    notFound: "Customer not found.",
    name: "Name",
    email: "Email",
    phoneNumber: "Phone Number",
    address: "Address",
    postalCodeCity: "Postal Code/City",
    edit: "Edit",
    created: "Created",
    deleteSelectedCustomers: "Delete selected customers",
    search: "Search",
    searchForCustomer: "Search for customer",
    noCustomersYet: "You have no customers yet",
    noSearchResults: "No search results",
    customerWithoutName: "Customer without name",
    confirmDeleteCustomers:
      "Are you sure you want to delete {count} customers?",
    deleteCustomerHeader: "Delete customer(s)?",
    customersDeleted: "{count} customers have been deleted",
    deleted: "Customer {name} has been deleted.",
    couldNotDelete: "Could not delete customer.",
    confirmDeleteCustomer: "Are you sure you want to delete this customer?",
  },
  calendar: {
    week: "Week",
    month: "Month",
    day: "Day",
    sideBySide: "Side-by-side",
    threeFourDays: "3-4 days",
    heading: "Calendar",
  },
  appointment: {
    deleteSelectedAppointments: "Delete selected appointments",
    filter: "Filter",
    all: "All",
    showMoreCustomerInfo: "Show more customer info",
    noAppointments: "No appointments",
    select: "Select",
    loading: "Loading... Please wait...",
    status: {
      ny: "New",
      invoiceCreated: "Invoice Created",
      invoiceSent: "Invoice Sent",
      invoicePaid: "Invoice Paid",
      ferdig: "Completed",
    },
    comment: "Comment",
    adminNote: "Note",
    created: "Created",
    number: "Number",
    service: "Service",
    time: "Time",
    customer: "Customer",
    appointmentStatus: "Status",
  },
  companyInfo: {
    companyName: "Company Name",
    companyNameDescription: "The name of the company as shown to the customer",
    companyAddress: "Company Address",
    companyAddressDescription:
      "The address of your company as shown to the customer",
    phoneNumber: "Phone Number",
    phoneNumberDescription:
      "The phone number of your company as shown to the customer",
    email: "Email",
    emailDescription: "The email of your company as shown to the customer",
    companyLogo: "Company Logo",
    companyLogoDescription: "The logo of your company as shown to the customer",
  },
  snackbar: {
    imageTooLarge: "Image must be smaller than 200 KB",
    invalidImageFormat: "Image must be in JPG or PNG format",
    enterServiceName: "Service name must be provided.",
    negativeDiscountPrice: "Discount price cannot be negative.",
    enterDuration: "Duration in minutes must be provided.",
    durationMustBeNumber: "Duration in minutes must be a number.",
    priceMustBeNumber: "Price must be a number.",
    negativePrice: "Price cannot be negative.",
    invalidDuration: "Invalid duration.",
    durationExceedsLimit: "A single service cannot last longer than 12 hours.",
  },
  account: {
    registerNewAccount: "Register New Account",
    editAccount: "Edit Account",
    allAccounts: "All Accounts",
    lastSignIn: "Last signed in: {date}",
    created: "Created: {date}",
    yourAccount: "Your Account",
  },
  role: {
    administrator: "Administrator",
    user: "User",
  },
  logout: "Log out",
  changePassword: "Change Password",
  logoutConfirm: "Do you want to log out?",
  forgotPassword: "Do you want to send a password reset link to {email}?",
  passwordResetEmailSent: "A password reset email has been sent to you.",
  subscription: {
    yourPlan: "Your Plan",
    nextPayment: "Next Payment",
    previousPayment: "Previous Payment",
    paymentInformation: "Payment Information",
    cardEnding: "Visa ending in {card}",
    noTrial:
      "Your trial period has expired. Please purchase a subscription to continue using the program.",
    paymentIssue: "There is an issue with your payment. Please contact us.",
    trialDaysLeft: "You have {days} days left of your free trial.",
    cancelledWithDate:
      "The subscription will not renew next month. It is active until {date}.",
    cancelledWithoutDate:
      "The subscription will not renew next month. It is active until the end of the month.",
    active: "You have an active subscription.",
    yearlyWithSMS:
      "You have a subscription valid until {date} with SMS included.",
    monthlyCost:
      "The subscription costs 250kr per month, no binding period. Cancel anytime. Upon cancellation, the subscription will last until the next payment date.",
    automaticPayment:
      "The payment is automatic per month. If you prefer an invoice instead, feel free to ask us.",
    smsCost:
      "If you have chosen to send receipts/reminders via SMS, or SMS notification upon booking, it costs 1kr per SMS and will be added automatically to the payment.",
    purchase: "Purchase subscription",
    reactivate: "Reactivate subscription",
    cancel: "Cancel subscription",
    cancelSubscriptionConfirm:
      "Are you sure you want to cancel your subscription?",
    subscription: "Subscription",
  },
  createCustomer: "Create Customer",
  editCustomer: "Edit Customer",
  lastName: "Last Name",
  dateOfBirth: "Date of Birth",
  male: "Male",
  female: "Female",
  selectInsurance: "Select Insurance",
  noInsuranceData: "Insurances can be added in settings.",
  customerUpdated: "Customer updated.",
  customerCreated: "Customer created.",
  settings: {
    general: "General",
    companyInfo: "Company Information",
    subscription: "Subscription",
    accounts: "Accounts",
    services: "Services",
    extraServices: "Extra Services",
    categories: "Categories",
    resources: "Resources",
    options: "Options",
    staff: "Staff",
    calendar: "Calendar",
    functions: "Functions",
    newsletter: "Newsletter",
    customerAccounts: "Customer Accounts",
    invoicesAndPayments: "Invoices and Payments",
    booking: "Booking",
    information: "Information",
    alerts: "Alerts",
    bookingSide: "Booking Page",
    customerInformation: "Customer Information",
    customerAccount: "Customer Accounts",
    workingHours: "Working Hours",
    friday: "Vacation Days",
    googleCalendar: "Google Calendar",
    overview: "Overview",
    customFields: "Custom Fields",
    giftCards: "Gift Cards",
    customerFollowUp: "Customer Follow-Up",
    cashRegisterSystem: "Cash Register System",
    multipleLocations: "Multiple Locations",
    system: "System",
    auditLog: "Audit Log",
    staffUpdated: "Staff updated.",
    staffDeleted: "Staff deleted.",
  },
  resourceName: "Resource Name",
  description: "Description",
  save: "Save",
  quantity: "Quantity",
  multipleServicesQuestion:
    "Should the customer be able to choose multiple services in one booking?",
  fields: "Fields",
  showServicePrice: "Show price for services",
  serviceName: "Service Name",
  sort: "Sort",
  duration: "Duration",
  category: "Category",
  extraServices: "Extra Services",
  boolean: {
    yes: "Yes",
    no: "No",
    on: "On",
    off: "Off",
  },
  categories: "Categories",
  services: "Services",
  staffMembersOverview: {
    minOneStaffRequired:
      "You must have at least one staff member for the program to function.",
    confirmDeleteStaff: "Do you want to delete this staff member?",
    staff: "Staff",
    yourStaff: "Your Staff",
    newStaff: "New Staff",
    firstName: "First Name",
    lastName: "Last Name",
    emailAddress: "Email Address",
    phoneNumber: "Phone Number",
    created: "Created",
    delete: "Delete",
    lastLoggedIn: "Last Logged In",
    editStaff: "Edit Staff",
  },
  staffMemberDetails: {
    firstName: "First Name",
    lastName: "Last Name",
    phoneNumber: "Phone Number",
    emailAddress: "Email Address",
    colorForCalendar: "Color for Calendar",
    selectColor:
      "Select 'Color' under 'Calendar' -> 'Show staff in event' to choose a color for staff.",
    save: "Save",
  },
  staffMemberGoogleCalendar: {
    syncGoogleCalendar: "Synchronize Google Calendar with Availify",
    syncGoogleCalendarWithAvailify: "Synchronize Google Calendar with Availify",
    benefit1: "Get bookings directly into your Google and Apple calendars.",
    benefit2:
      "Events in Google/Apple calendars will automatically be added to Availify and blocked for booking.",
    start: "Start",
    connectGoogleAccount: "Connect to a Google account",
    clickButtonToLogin: "Click the button to log in",
    continue: "Continue",
    addEventsToAppleCalendar: "Add Google Calendar events to Apple Calendar",
    useAppleDevices:
      "Do you use iPhone/iPad? Synchronize Google with Apple Calendar.",
    addGoogleCalendarToApp: "Add Google Calendar to the 'Calendar' app",
    step1: "Open the device settings on your iPhone or iPad.",
    step2: "Scroll and tap on 'Calendar'.",
    step3: "Tap 'Accounts' -> 'Add Account' -> 'Google'",
    // ... other steps as needed ...
    setAsDefaultCalendar: "Set Google Calendar as default calendar",
    defaultStep1: "Open the device settings on your iPhone or iPad.",
    defaultStep2: "Scroll and tap on 'Calendar'.",
    // ... other steps as needed ...
    done: "Done",
    synchronizedWithGoogle: "You are synchronized with Google Calendar",
    syncBenefit1:
      "Your appointments are now synchronized between Google and your Apple calendar on Mac/iPhone/iPad.",
    syncBenefit2:
      "When you or the customer book/change/remove appointments in Availify, these times will be updated in your Google and Apple calendars as events.",
    syncBenefit3:
      "You can also create new events in your Google and Apple calendars, which will be marked as busy for online booking in Availify.",
    syncBenefit4:
      "If you change or remove events in your Google and Apple calendars, they will also be changed/removed in Availify.",
    howToConnectAppleCalendar: "How to connect Apple Calendar to iPhone/iPad",
    removeSync: "Remove Synchronization",
    confirmRemoveSync: "Are you sure you want to remove synchronization?",
  },
  vacationDays: {
    markDayAs: "Mark day as:",
    available: "Available",
    notAvailable: "Not Available",
  },
  workingHours: {
    timeRequired: "Time is required",
    invalidFormat: "Invalid format (HH:MM)",
    invalidTimeFormat:
      "Could not update opening hours. Check if you have entered the correct format (HH:MM)",
    updated: "Opening hours updated.",
    availableOnThisDay: "Available on this day: {value}",
    openingHourFor: "Opening hour for {day}",
    closingHourFor: "Closing hour for {day}",
    breaks: "Breaks",
    start: "Start",
    end: "End",
    addBreak: "Add Break",
    save: "Save",
  },
  generalBookingSettings: {
    customerVerification: "Customer Verification",
    customerVerificationDescription:
      "What must the customer verify to book an appointment?",
    cancellationModification: "Cancellation/Modification of Appointments",
    cancellationModificationDescription:
      "Should the customer be able to change/cancel the appointment?",
    minimumHoursBefore: "Minimum hours before the appointment",
    minimumHoursBeforeDescription:
      "Number of hours before the appointment that the customer can cancel",
    remindersReceipts: "Reminders/Receipts to Customers",
    remindersReceiptsDescription:
      "How should receipts/reminders for the appointment be sent?",
    alwaysSendReceipt: "Always send receipt",
    alwaysSendReceiptDescription:
      "Box does not need to be checked by the customer",
    alwaysSendReminder: "Always send reminder",
    alwaysSendReminderDescription:
      "Box does not need to be checked by the customer",
    bookingRestrictions: "Booking Restrictions",
    bookingRestrictionsDescription:
      "How many days in advance can a customer book?",
    earlyBooking: "How early can a customer book before the appointment?",
    earlyBookingDescription:
      "Number of hours before the appointment can be booked",
    noVerification: "No Verification",
    email: "Email",
    sms: "SMS",
    auditVerificationChanged: "Changed setting 'Customer Verification'",
    auditCancellationChanged:
      "Changed setting 'Cancellation/Modification of Appointments'",
    auditHoursToCancelChanged:
      "Changed setting 'Minimum hours before appointment cancellation' to {hours} hours",
    auditReceiptReminderChanged:
      "Changed setting 'Reminders/Receipts to Customers'",
    auditMaxDaysChanged:
      "Changed setting 'Booking Restrictions' number of days a customer can book in advance to {days} days.",
    auditMinHoursChanged:
      "Changed setting 'Booking Restrictions' how early a customer can book before the appointment {hours} hours.",
    emailRequired:
      "Email field has been set to required because information is sent via email.",
    phoneRequired:
      "Phone number field has been set to required because receipts and reminders are sent via SMS.",
    days: "Days",
    hours: "Hours",
  },
  bookingDistribution: {
    timeDivision: "Time Division",
    selectTimeSlots: "Select time slots that can be booked.",
    confirmChange:
      "Are you sure you want to change the time division to {minutes} minutes?",
    changeTimeFormat: "Change Time Format",
    auditChange: "Changed time division to {minutes} minutes for booking",
  },
  // CalendarStyleSettings.vue
  hideDaysOutsideWorkingHours: "Hide days outside working hours",
  cannotActivateWithMultipleStaff: "Cannot be activated with multiple staff",
  events: "Events",
  showServiceNameInEvent: "Show service name in event",
  showStaffInEvent: "Show staff in event",
  showTimeInEvent: "Show time in event",
  color: "Color",

  // EmailListSettings.vue
  newsletter: "Newsletter",
  askCustomersToSignUpForNewsletter:
    "Ask customers to sign up for newsletter upon booking",
  subscribers: "Subscribers",
  noSubscribersYet: "Here will be an overview of all subscribers",
  signedUp: "Signed Up",
  loading: "Loading... Please wait...",
  downloadExcel: "Download Excel (.xlsx)",
  exportAppointments: "Export all appointments",
  eventsOverview: {
    message: "Message",
    time: "Time",
    comment: "Comment",
    booking: "Booking",
    receipt: "Receipt",
    reminder: "Reminder",
    seen: "Seen",
    new: "New",
    previous: "Previous",
    noEvents: "You have no events",
    guest: "Guest",
    critical: "Critical",
    info: "Info",
    warning: "Warning",
    customer: "Customer",
    errorWithSmsSending:
      "Something went wrong with SMS sending. SMS verification has been turned off",
    timeAddedToExistingCustomer:
      "Booked time was automatically added to existing customer - but there were differences",
    timeCancelled: "Booked time was cancelled",
    timeChanged: "Booked time was changed",
    newCustomer: "New customer.",
    customerComment: "Customer Comment",
    bookingCancelled: "Booking cancelled",
    bookingChanged: "Booking changed",
  },
  onboarding: {
    user: "User",
    company: "Company",
    workingHours: "Working Hours",
    services: "Services",
    finish: "Finish",
    pickWorkingHours: "Pick Working Hours",
    createServices: "Create Services",
    yourUser: "Your User",
    yourCompany: "Your Company",
  },
  auditOverview: {
    event: "Event",
    time: "Time",
    comment: "Comment",
    noChanges: "Here will be an overview of all changes you make",
    customer: "Customer",
    booking: "Booking",
    settings: "Settings",
    staff: "Staff",
    track: "Track",
    service: "Service",
    readComment: "Read comment on log",
  },
  customerModal: {
    notes: "Notes",
    createdByCustomer: "Created by customer via online booking",
    createdByStaff: "Created by staff",
    statistics: {
      appointmentsThisMonth: "Appointments this month",
      appointmentsOverall: "Appointments overall",
      totalUsedForServicesThisMonth: "Total used for services this month",
      totalUsedForServicesOverall: "Total used for services overall",
    },
    payments: "Payments",
    newJournal: "Add New Journal",
    buttons: {
      cancel: "Cancel",
      save: "Save",
    },
    dialog: {
      unsavedChangesWarning:
        "You have unsaved changes in the journal. If you leave the page, your changes will be lost.",
      exitConfirmation:
        "You have unsaved changes in the journal. Are you sure you want to exit?",
    },
    journal: "Journal",
    generalText: "General Text",
  },
  nameIsRequired: "Name is required",
  amountMustBeOneOrHigher: "Amount must be 1 or higher",
  resources: {
    deleteConfirmation: "Are you sure you want to delete this resource?",
    deleteHeader: "Delete Resource",
    name: "Name",
    description: "Description",
    quantity: "Quantity",
    createResource: "Create Resource",
    editResource: "Edit Resource",
    nameDescription: "Name of the resource",
    descriptionDescription: "Description of the resource",
    quantityDescription: "Quantity of the resource",
  },
  functions: {
    contactUs: "Contact Us",
    customerLogin: "Customer Login",
    invoicesAndPayments: "Invoices and Payments",
    customFields: "Custom Fields",
    newsletter: "Newsletter",
    giftCards: "Gift Cards",
    customerFollowUp: "Customer Follow-Up",
  },
  login: {
    fillEmailPassword: "Email and password must be filled out.",
    passwordsMustMatch: "Passwords must match.",
    emailInUse: "This email address is already in use.",
    invalidEmail: "Invalid email address.",
    weakPassword: "Password must be at least 6 characters.",
    userNotFound: "User not found.",
    wrongEmailPassword: "Wrong email or password.",
    fillEmailForReset: "Please fill in your email to reset the password.",
    sendNewPasswordTo: "Send new password to {email}?",
    emailSent: "An email has been sent to you.",
    tryFree: "Try free for 14 days",
    repeatPassword: "Repeat password",
    password: "Password",
    register: "Register",
    haveAccount: "Have an account?",
    loginHere: "Login here",
    login: "Login",
    forgot: "Forgot?",
    next: "Next",
    firstName: "First Name",
    lastName: "Last Name",
    phoneNumber: "Phone Number",
    phoneNumberRequired: "Phone number must be 8 digits.",
    firstNameRequired: "First name is required.",
    lastNameRequired: "Last name is required.",
  },
  systemsSettings: {
    selectLanguage: "Select Language",
    languageDescription:
      "Select the language for the system, both for the dashboard and the booking site.",
    language: "Language",
    timeFormat: "Time Format",
    timeFormatDescription:
      "Select the time format for the system, both for the dashboard and the booking site.",
    timeZone: "Time Zone",
    timeZoneDescription:
      "Select the time zone for the system. Contact us to change.",
    defaultCountryCode: "Default Country Code",
    defaultCountryCodeDescription:
      "Select the default country code for the system. This will be used for setting the default country code on the booking page, and determining the country code for SMS to the staff.",
  },
  user: {
    updateFailed: "Could not update user.",
    smsVerificationError: "Cannot use SMS verification without a subscription.",
    bookingLinkExists:
      "This company name already exists. Is it your company? Contact us.",
    loginError:
      "Something went wrong with login. If this problem persists, please contact us.",
    imageUploaded: "Image has been uploaded.",
    imageUploadError: "Image is too large or wrong format. Use JPG or PNG.",
  },
  hideInBooking: "Hide in Booking",
  discount: "Discount",
  newPrice: "New Price",
  assignToAllStaff: "Assign to All Staff",
  appointmentSummary: {
    fromOnlineBooking: "From online booking",
    assignStaff: "Assign staff",
    yourNotes: "Your notes",
  },
  appointmentMenu: {
    moveAppointmentMessage:
      "Click on a time in the calendar to move the appointment.",
    confirmDeleteAppointment:
      "Are you sure you want to delete this appointment?",
    deleteAppointmentHeader: "Delete Appointment",
    appointmentDeleted: "The appointment has been deleted.",
    edit: "Edit",
    delete: "Delete",
    findInCalendar: "Find in Calendar",
  },
  customerModalMessages: {
    customerUpdated: "Customer updated.",
    customerUpdateFailed: "Customer update failed.",
  },
  multipleServices: "Multiple Services",
  emailAddress: "Email Address",
  commitChart: {
    currentYear: "Earned {year}",
    previousYear: "Earned {year}",
  },
  calendarMessages: {
    moveAppointmentTo: "Move appointment to",
  },
  appointments: {
    creatingAppointment: "Creating appointment...",
    appointmentCreated: "The appointment has been created!",
    overlappingAppointment:
      "The booking overlaps with another appointment. Do you still want to create the appointment?",
    alreadyBooked:
      "The appointment you are trying to book has already been booked by someone else.",
    overlappingWithNext:
      "The appointment you are trying to book overlaps with the next appointment. Please choose a shorter time.",
  },
  customerName: "Customer Name",
  createAppointmentMessage: {
    invalidTimeFormat: "Invalid time format",
    creationCancelled: "Creation cancelled.",
    enterCustomerName: "You must enter a customer name.",
    selectService: "You must select a service.",
    editEndTimeWithoutStart:
      "Attempted to edit end time without setting start time.",
    createNewCustomer: "Create new customer for {name}",
    customEvent: "Custom event",
    createAppointment: "Create Appointment",
    appointmentCreationFailed:
      "Appointment creation failed. Please contact customer service.",
  },
  bookingPageCustomerSettings: {
    auditLogs: {
      changedAssignmentType:
        "Changed setting 'Automatic  assignment new customer' to {setting}",
      changedCustomerAccountCreation:
        "Changed setting 'Create/Assign customer account automatically upon booking' to {setting}",
    },
  },
  bookingPageGeneralSettings: {
    auditLogs: {
      changedBookingLink: "Changed setting 'Booking-ID' to {link}",
      changedCommonLink: "Changed setting 'Common-ID' to {link}",
      changedLocationName: "Changed setting 'Location name' to {name}",
    },
    validation: {
      bookingLinkTooShort: "Booking link must contain at least 3 characters",
    },
  },
  mobileHeader: {
    home: "Home",
    calendar: "Calendar",
    customers: "Customers",
    new: "New",
    orders: "Orders",
    settings: "Settings",
    log: "Log",
    statistics: "Statistics",
    overview: "Overview",
    booking: "Booking",
    staff: "Staff",
  },
  password: "Password",
  dialogMessages: {
    confirm: "Confirm",
    cancel: "Cancel",
    no: "No",
  },
  $vuetify: {
    badge: "Badge",
    close: "Close",
    dataIterator: {
      noResultsText: "No matching records found",
      loadingText: "Loading items...",
    },
    dataTable: {
      itemsPerPageText: "Rows per page:",
      ariaLabel: {
        sortDescending: "Sorted descending.",
        sortAscending: "Sorted ascending.",
        sortNone: "Not sorted.",
        activateNone: "Activate to remove sorting.",
        activateDescending: "Activate to sort descending.",
        activateAscending: "Activate to sort ascending.",
      },
      sortBy: "Sort by",
    },
    dataFooter: {
      itemsPerPageText: "Items per page:",
      itemsPerPageAll: "All",
      nextPage: "Next page",
      prevPage: "Previous page",
      firstPage: "First page",
      lastPage: "Last page",
      pageText: "{0}-{1} of {2}",
    },
    datePicker: {
      itemsSelected: "{0} selected",
      nextMonthAriaLabel: "Next month",
      nextYearAriaLabel: "Next year",
      prevMonthAriaLabel: "Previous month",
      prevYearAriaLabel: "Previous year",
    },
    noDataText: "No data available",
    carousel: {
      prev: "Previous visual",
      next: "Next visual",
      ariaLabel: {
        delimiter: "Carousel slide {0} of {1}",
      },
    },
    calendar: {
      moreEvents: "{0} more",
    },
    fileInput: {
      counter: "{0} files",
      counterSize: "{0} files ({1} in total)",
    },
    timePicker: {
      am: "AM",
      pm: "PM",
    },
    pagination: {
      ariaLabel: {
        wrapper: "Pagination Navigation",
        next: "Next page",
        previous: "Previous page",
        page: "Goto Page {0}",
        currentPage: "Current Page, Page {0}",
      },
    },
    rating: {
      ariaLabel: {
        icon: "Rating {0} of {1}",
      },
    },
    statistics: {
      serviceName: "Service",
      bookings: "Bookings",
      customers: "Customers",
    },
  },
  serviceCategories: {
    nameRequired: "Name is required",
    confirmDelete: "Are you sure you want to delete this category?",
    deleteHeader: "Delete service",
    editCategory: "Edit category",
    name: "Name",
    cancel: "Cancel",
    save: "Save",
    categoryName: "Category name",
    create: "Create",
    sort: "Sort",
    table: {
      name: "Name",
      sort: "Sort",
    },
  },
  bookingPageInformationSettings: {
    auditLogs: {
      changedInformationBoxText: "Changed the text in the information box.",
      changedShowInformationBox:
        "Changed the setting 'Show information box' to {setting}",
    },
  },
  serviceOptions: {
    auditLogs: {
      changedShowServicePrice:
        "Changed the setting 'Show service price' to {setting}",
      changedMultipleServices:
        "Changed the setting 'Multiple services' to {setting}",
    },
  },
  bookingPageFieldSettings: {
    auditLogs: {
      changedMultipleServices:
        "Changed the setting 'Customer field' to {field}",
    },
  },
  routes: {
    login: "Login",
    login_: "Login", // Note: "login " has a trailing space; adjusted key to "login_"
    Settings: "Settings",
    CompanyInfo: "Company Info",
    SystemSettings: "System Settings",
    Services: "Services",
    Categories: "Categories",
    Resources: "Resources",
    Subscription: "Subscription",
    Accounts: "Accounts",
    BookingAlerts: "Booking Alerts",
    CustomerInformation: "Customer Information",
    Booking: "Booking",
    BookingSide: "Booking Side",
    Options: "Alternativer",
    Staff: "Staff",
    CalendarSettings: "Calendar Settings",
    Newsletter: "Newsletter",
    AuditLog: "Audit Log",
    ExtraServices: "Extra Services",
    BookingInformation: "Booking Information",
    CustomerAccount: "Customer Account",
    Kunder: "Customers",
    Kunde: "Customer",
    Kalender: "Calendar",
    KalenderBestilling: "Calendar Booking",
    Hjem: "Home",
    oAuthCallBack: "OAuth Callback",
    Onboarding: "Onboarding",
    muscleGroupSelector: "Muscle Group Selector",
    Statistikk: "Statistics",
    Eksporter: "Export",
    Bestillinger: "Orders",
    Bestilling: "Order",
    Faktura: "Invoice",
    Betalinger: "Payments",
    Gavekort: "Gift Cards",
    Tjenester: "Services",
    Hendelser: "Events",
    Logg: "Log",
    AdminLogg: "Admin Log",
    Debug: "Debug",
    Register: "Register",
    StaffWorkingHours: "Working Hours",
    StaffGoogleCalendar: "Google Calendar",
    StaffFriday: "Vacation Days",
    StaffServices: "Services",
    StaffInformation: "Staff Information",
    StaffOverview: "Staff Overview",
  },
  fieldsettings: {
    hideDaysOutsideWorkingHours: "Hide days outside working hours",
    showServiceNameInEvent: "Show service name in event",
    showTimeInEvent: "Show time in event",
    showStaffInEvent: "Show staff in event",
  },
  statistics: {
    serviceName: "Service",
    bookings: "Bookings",
    customers: "Customers",
  },
  all: "All",
  createService: {
    title: "Create Service",
    assignToAllStaff: "Assign to All Staff",
    assignToAllStaffDescription:
      "If checked, the service will be assigned to all staff members.",
    discount: "Discount",
    discountDescription: "If checked, the service will have a discount.",
    newPrice: "New Price",
    newPriceDescription: "The new price of the service.",
    hideInBooking: "Hide in Booking",
    hideInBookingDescription: "Hide the service in the booking page.",
    serviceName: "Service Name",
    serviceNameDescription: "The name of the service.",
    description: "Description",
    descriptionDescription: "The description of the service.",
    price: "Price",
    priceDescription: "The price of the service.",
    duration: "Duration",
    durationDescription: "The duration of the service in minutes.",
    durationForStaff: "Duration for Staff",
    durationForStaffDescription:
      "The duration in minutesof the service for staff to complete.",
    durationForCustomer: "Duration for Customer",
    durationForCustomerDescription:
      "The duration in minutes of the service as displayed to the customers.",
    color: "Color",
    colorDescription: "The color of the service in the calendar.",
    showCustomColor: "Show custom color",
    showCustomColorDescription:
      "If checked, the service will have a custom color.",
    customColor: "Custom color",
    customColorDescription: "The custom color of the service.",
    discountPrice: "Discount price",
    discountPriceDescription: "The discount price of the service.",
    discountPriceChange: "Discount price changed",
    discountPriceChangeDescription:
      "The discount price of the service has been changed.",
    validation: {
      priceRequired: "Price is required",
      durationRequired: "Duration is required",
      serviceNameRequired: "Service name is required",
      descriptionRequired: "Description is required",
      durationMustBeNumber: "Duration must be a number",
      priceMustBeNumber: "Price must be a number",
      durationPositive: "Duration must be positive",
      durationExceedsLimit: "Duration exceeds limit",
      invalidDiscountPrice: "Invalid discount price",
      discountPriceMustBeNumber: "Discount price must be a number",
      priceNotNegative: "Price must be positive",
      discountPriceNotNegative: "Discount price must be positive",
    },
  },

  validation: {
    nameRequired: "Name is required",
  },
  writeHere: "Write here...",
  registerFirebase: {
    email: "Email",
    password: "Password",
    passwordAgain: "Password again",
    register: "Register",
    emailAlreadyInUse: "Email already in use",
    invalidEmail: "Invalid email",
    passwordTooWeak: "Password too weak",
    emailAndPasswordRequired: "Email and password are required",
    passwordsMustMatch: "Passwords must match",
  },
};
