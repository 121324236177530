import Vue from "vue";
import { IAuditLogDTO } from "../types/AutoGenerated/reinforcedTypings";

export type AuditLoggerType = Vue & {
  logAudit(message: string, action?: number, type?: number): void;
};

export default Vue.extend({
  methods: {
    logAudit(message: string, action: number = 3, type: number = 4): void {
      const audit: Partial<IAuditLogDTO> = {
        action,
        type,
        comment: "",
        message,
        hideForUser: true,
      };

      // @ts-ignore - vuex store will be available at runtime
      this.$store.dispatch("postAuditLog", audit);
    },
  },
});
