export const toolbarConfig = {
  TreatmentTypes: {
    actions: [
      {
        icon: "mdi-plus",
        handler: "toggleForm",
        tooltip: "Add Treatment Type",
      },
    ],
  },
  ServiceCategories: {
    actions: [
      {
        icon: "mdi-plus",
        handler: "toggleForm",
        tooltip: "Add Service Category",
      },
    ],
  },
  Resources: {
    actions: [
      {
        icon: "mdi-plus",
        handler: "toggleAdd",
        tooltip: "Add Resource",
      },
    ],
  },
  StaffMembersOverview: {
    actions: [
      {
        icon: "mdi-plus",
        handler: "openNewStaffMember",
        tooltip: "Add Staff Member",
      },
    ],
  },
};
