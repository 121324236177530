
import SettingsMobile from "./SettingsMobile.vue";
import { Component, Vue } from "vue-property-decorator";
@Component({
  name: "settings-compnent",
  components: {
    SettingsMobile,
  },
})
export default class Settings extends Vue {}
