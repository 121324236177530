
import Vue from "vue";
import Component from "vue-class-component";
import { State, Getter, Action, Mutation, namespace } from "vuex-class";
import {
  IAppointmentTypeDTO,
  IStaffMemberDTO,
} from "../../../../types/AutoGenerated/reinforcedTypings";
import { Prop } from "vue-property-decorator";
import Loader from "../../../global/Loader.vue";

@Component({
  name: "StaffMemberServicesSettings",
  components: { Loader },
})
export default class StaffMemberServicesSettings extends Vue {
  @Prop({}) staffMember!: IStaffMemberDTO;

  public loading: boolean = false;
  public error: string | null = null;
  public localStaffMember: IStaffMemberDTO | null = null;

  @Action public getAppointmentTypes: Function;
  @Action public setServiceToStaffMember: Function;
  @Action public getStaffMembers: Function;
  @Getter public appointmentType: IAppointmentTypeDTO[];
  @Getter public staffMembers;

  async mounted() {
    try {
      this.loading = true;

      // Initialize staff member
      if (this.staffMember) {
        this.localStaffMember = this.staffMember;
      } else {
        const staffId = this.$route.params.staffId;
        if (!staffId) {
          throw new Error("No staff member ID provided");
        }

        if (this.staffMembers.length === 0) {
          await this.getStaffMembers();
        }

        this.localStaffMember = this.staffMembers.find(
          (staff) => staff.id == staffId
        );

        if (!this.localStaffMember) {
          throw new Error("Staff member not found");
        }
      }

      // Load appointment types
      await this.getAppointmentTypes();
    } catch (err) {
      this.error = err.message || "Failed to load staff member services";
      console.error("Error loading staff member services:", err);
    } finally {
      this.loading = false;
    }
  }

  getName(service: IAppointmentTypeDTO): string {
    return service?.title || "";
  }

  staffMemberHasService(service: IAppointmentTypeDTO): boolean {
    if (!this.localStaffMember?.services) return false;

    const foundService = this.localStaffMember.services.find((x) => {
      return x.id == service.id;
    });
    return foundService != null;
  }

  async onServiceSwitch(
    service: IAppointmentTypeDTO,
    event: boolean
  ): Promise<void> {
    if (!this.localStaffMember) return;

    try {
      const payload = {
        serviceId: service.id,
        staffMemberId: this.localStaffMember.id,
        enabled: event,
      };
      await this.setServiceToStaffMember(payload);
    } catch (err) {
      this.error = "Failed to update service";
      console.error("Error updating service:", err);
    }
  }
}
