import {
  Module,
  VuexModule,
  Mutation,
  Action,
  MutationAction,
} from "vuex-module-decorators";
import {
  IAppointmentForWeeklyCalender,
  IAuditLogDTO,
  IStaffMemberDTO,
} from "../types/AutoGenerated/reinforcedTypings";

import { isMobile, isiPad, isDesktop } from "../Utilities/deviceUtility";
import { i18n } from "../main";

@Module({ namespaced: true })
export default class CalendarModule extends VuexModule {
  // State
  calendarType: string = "week";
  intervalMinutes: number = 30;
  currentStaffMember: number = 0;
  events: IAppointmentForWeeklyCalender[] = [];
  filteredEvents: IAppointmentForWeeklyCalender[] = [];
  selectedEvent: IAppointmentForWeeklyCalender | null = null;
  showSettings: boolean = false;
  startTime: string = "";
  monthName: string = "";

  // Getters
  get getMonthName() {
    return this.monthName;
  }

  get getCurrentStaffMember() {
    return this.currentStaffMember;
  }

  get getCalendarType() {
    return this.calendarType;
  }

  get getIntervalMinutes() {
    return this.intervalMinutes;
  }

  get getEvents() {
    return this.events;
  }

  get getFilteredEvents() {
    return this.filteredEvents;
  }

  get getSelectedEvent() {
    return this.selectedEvent;
  }

  get getShowSettings() {
    return this.showSettings;
  }

  get staffMemberCategories() {
    const staffMembers = [...this.context.rootGetters["staffMembers"]];

    return staffMembers.map((x) => {
      return x.fullName.trim();
    });
  }

  get calendarOptions() {
    return [
      { value: "week", text: i18n.t("calendar.week") },
      { value: "month", text: i18n.t("calendar.month") },
      { value: "day", text: i18n.t("calendar.day") },
      { value: "category", text: i18n.t("calendar.sideBySide") },
      { value: "4day", text: i18n.t("calendar.threeFourDays") },
    ];
  }

  get staffMemberOptions() {
    // Access staffMembers from another module, e.g., 'staffModule'
    const staffMembers = [...this.context.rootGetters["staffMembers"]];

    const allOptions: Partial<IStaffMemberDTO> = {
      fullName: i18n.t("all").toString(),
      id: 0,
    };

    staffMembers.unshift(allOptions);
    return staffMembers;
  }

  // MutationActions - combines mutations and actions
  @MutationAction
  async setMonthName(name: string) {
    return { monthName: name };
  }

  @MutationAction
  async updateCalendarType(type: string) {
    localStorage.setItem("calendarmode", type);
    return { calendarType: type };
  }

  @MutationAction({ mutate: ["currentStaffMember"] })
  async updateCurrentStaffMember(staffMember: number) {
    localStorage.setItem("currentstaffmember", staffMember.toString());
    return { currentStaffMember: staffMember };
  }

  @MutationAction
  async updateIntervalMinutes(minutes: number) {
    localStorage.setItem("calendar-zoom", minutes.toString());
    return { intervalMinutes: minutes };
  }

  @MutationAction({ mutate: ["events", "filteredEvents"] })
  async setEvents(events: IAppointmentForWeeklyCalender[]) {
    const filtered =
      this.currentStaffMember === 0
        ? events
        : events.filter(
            (x: IAppointmentForWeeklyCalender) =>
              x.staffMemberId === this.currentStaffMember
          );

    return {
      events,
      filteredEvents: filtered,
    };
  }

  @MutationAction
  async selectEvent(event: IAppointmentForWeeklyCalender | null) {
    return { selectedEvent: event };
  }

  @MutationAction
  async toggleSettings(show: boolean) {
    return { showSettings: show };
  }

  @MutationAction
  async setStartTime(time: string) {
    return { startTime: time };
  }

  @Action({ commit: "setFilteredEvents" })
  filterEvents() {
    const filtered =
      this.currentStaffMember === 0
        ? this.events
        : this.events.filter(
            (x: IAppointmentForWeeklyCalender) =>
              x.staffMemberId === this.currentStaffMember
          );
    return filtered;
  }

  @Mutation
  setFilteredEvents(filteredEvents: IAppointmentForWeeklyCalender[]) {
    this.filteredEvents = filteredEvents;
  }
}
