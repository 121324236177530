
import Vue from "vue";
import Component from "vue-class-component";
import NotificationBell from "../global/NotificationBell.vue";
import { Prop } from "vue-property-decorator";

@Component({
  name: "NavigationItems",
  components: { NotificationBell },
})
export default class NavigationItems extends Vue {
  @Prop({ required: true }) selectedIndex!: number;

  get iconSize() {
    const width =
      window.innerWidth ||
      document.documentElement.clientWidth ||
      document.body.clientWidth;
    return width < 420 ? 24 : 32;
  }

  get itemList() {
    const width = document.documentElement.clientWidth;

    const mobileItems = [
      {
        index: 0,
        alt: this.$t("mobileHeader.home"),
        text: this.$t("mobileHeader.home"),
        image: "../img/mobile/dashboard.svg",
        image2: "../img/mobile/dashboard_fill.svg",
        route: "/admin",
        icon: "dashboard",
      },
      {
        index: 1,
        alt: this.$t("mobileHeader.calendar"),
        text: this.$t("mobileHeader.calendar"),
        image: "../img/mobile/calendar.svg",
        image2: "../img/mobile/calendar_fill.svg",
        route: "/kalender",
        icon: "calendar",
      },
    ];

    const iPadItems = [
      ...mobileItems,
      {
        index: 2,
        alt: this.$t("mobileHeader.customers"),
        text: this.$t("mobileHeader.customers"),
        image: "../img/mobile/customer.svg",
        image2: "../img/mobile/customer_fill.svg",
        route: "/kunder",
        icon: "account_circle",
      },
      {
        index: 3,
        alt: this.$t("mobileHeader.orders"),
        text: this.$t("mobileHeader.orders"),
        image: "../img/mobile/overview.svg",
        image2: "../img/mobile/overview_fill.svg",
        route: "/bestillinger",
        icon: "inbox",
      },
      {
        index: 5,
        alt: this.$t("mobileHeader.settings"),
        text: this.$t("mobileHeader.settings"),
        image: "../img/mobile/settings.svg",
        image2: "../img/mobile/settings_fill.svg",
        action: () => this.$emit("settings-click"),
        icon: "calendar_today",
      },
    ];

    return width > 800 ? iPadItems : mobileItems;
  }
}
