
import { Component, Vue } from "vue-property-decorator";
import HeaderBar from "../../components/global/MainHeader.vue";
import MonthlyEarnings from "@/components/global/statistics/MonthlyEarned.vue";
import { LANG, Language } from "../../main";
import CommitChart from "../../components/global/CommitChart.vue";
import { Action, Getter } from "vuex-class";
import {
  actionStringStatistics,
  IEarnedThisAndPreviousYear,
  getterStringStatistics,
} from "../../store/statistics";
import Calendar from "@/abstract-classes/Calendar";
import {
  actionStringCustomers,
  getterStringCustomers,
} from "@/store/customers";
import Loader from "@/components/global/Loader.vue";
import { castMonthFromNumberToStringName } from "@/types/formatDateHelper";

@Component({
  name: "export-page",
  components: { Loader, HeaderBar },
})
export default class ExportPage extends Vue {
  public language: any | null = null;
  isLoading: boolean = false;

  @Action public downloadAppointments: Function;

  async downloadReport() {
    let data = await this.downloadAppointments();
    const url = window.URL.createObjectURL(new Blob([data]));

    const link = document.createElement("a");
    link.href = url;
    link.setAttribute("download", "rapport.xlsx"); //or any other extension
    document.body.appendChild(link);
    link.click();
  }

  async created(): Promise<any> {}
}
