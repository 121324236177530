
import { Component, Prop, Vue } from "vue-property-decorator";
import { Getter, Action } from "vuex-class";
import { IStaffMemberDTO } from "@/types/AutoGenerated/reinforcedTypings";

@Component({
  name: "CalendarSettingsDialog",
})
export default class CalendarSettingsDialog extends Vue {
  @Getter("staffMemberOptions", { namespace: "calendar" })
  staffMemberOptions!: Array<Partial<IStaffMemberDTO>>;

  @Getter("getCalendarType", { namespace: "calendar" })
  getCalendarType!: string;
  @Action("updateCalendarType", { namespace: "calendar" })
  updateCalendarType!: (type: string) => void;

  @Getter("getCurrentStaffMember", { namespace: "calendar" })
  getCurrentStaffMember!: number;
  @Action("updateCurrentStaffMember", { namespace: "calendar" })
  updateCurrentStaffMember!: (staffMember: number) => void;

  @Getter("getIntervalMinutes", { namespace: "calendar" })
  getIntervalMinutes!: number;
  @Action("updateIntervalMinutes", { namespace: "calendar" })
  updateIntervalMinutes!: (minutes: number) => void;

  @Getter("calendarOptions", { namespace: "calendar" })
  calendarOptions!: Array<any>;

  menu: boolean = false;

  intervalOptions = [15, 30, 60, 90];

  get calendarType() {
    return this.getCalendarType;
  }

  set calendarType(value: string) {
    this.updateCalendarType(value);
  }

  get selectedOption() {
    return this.getIntervalMinutes;
  }

  set selectedOption(value: number) {
    this.updateIntervalMinutes(value);
  }

  get currentStaffMember() {
    return this.getCurrentStaffMember;
  }

  set currentStaffMember(value: number) {
    this.updateCurrentStaffMember(value);
  }
}
