
// @ts-nocheck

import { Bar, Line, Doughnut, Pie } from "vue-chartjs";
import { Component, Vue, Prop } from "vue-property-decorator";
import { LANG, Language } from "../../main";
import { IServiceStat } from "@/types/AutoGenerated/reinforcedTypings";

@Component({
  extends: Doughnut, // this is important to add the functionality to your component
})
export default class CommitChart extends Vue<Doughnut> {
  @Prop() statistics: Array<IServiceStat>;

  public language: any | null = null;
  public gradient: any | null;
  public gradient2: any | null;

  public option: any = {
    // scales: {
    //   yAxes: [{
    //     ticks: {
    //       beginAtZero: true
    //     },
    //     gridLines: {
    //       display: false
    //     }
    //   }],
    //   xAxes: [{
    //     gridLines: {
    //       display: false
    //     }
    //   }]
    // },
    // legend: {
    //   display: true
    // },
    responsive: true,
    maintainAspectRatio: false,
  };

  public mounted(): void {
    // Overwriting base render method with actual data.
    const canvas: any = this.$refs.canvas;
    //
    // this.gradient = canvas.getContext('2d').createLinearGradient(0, 0, 0, 450)
    // this.gradient2 = canvas.getContext('2d').createLinearGradient(0, 0, 0, 450)
    //
    // this.gradient.addColorStop(0, 'rgba(135, 106, 255, 0.8)')
    // this.gradient.addColorStop(0.5, 'rgba(135, 106, 255, 0.5)');
    // this.gradient.addColorStop(1, 'rgba(135, 106, 255, 0)');
    //
    // this.gradient2.addColorStop(0, 'rgba(86, 220, 182, 0.5)')
    // this.gradient2.addColorStop(0.5, 'rgba(86, 220, 182, 0.5)');
    // this.gradient2.addColorStop(1, 'rgba(86, 220, 182, 0.5)');

    let data = this.statistics;

    this.renderChart(
      {
        labels: data.map((x) => x.serviceName),
        datasets: [
          {
            borderColor: "#876AFF",
            pointBackgroundColor: "#876AFF",
            borderWidth: 1,
            pointBorderColor: "#876AFF",
            backgroundColor: data.map((x) => x.color),
            data: data.map((x) => x.bookings),
          },
        ],
      } /*End of chart data*/,

      this.option
    );
  }
}
