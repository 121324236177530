
import Vue from "vue";
import { Component, Prop, Watch } from "vue-property-decorator";
import AuditLogger, { AuditLoggerType } from "../../mixins/AuditLogger";

interface NavigationItem {
  alt: string;
  text: string;
  image: string;
  image2?: string;
  route?: string;
  icon: string;
  action?: () => void;
  children?: any[];
  onlySmallMenu?: boolean;
}

@Component({
  name: "NavigationDrawer",
  mixins: [AuditLogger],
})
export default class NavigationDrawer extends Vue implements AuditLoggerType {
  @Prop({ required: true }) value!: boolean;
  @Prop({ required: true }) width!: number;

  selectedItem = null;

  // Implement the mixin method to satisfy TypeScript
  logAudit!: (message: string, action?: number, type?: number) => void;

  get drawerVisible(): boolean {
    return this.value;
  }

  set drawerVisible(value: boolean) {
    this.$emit("input", value);
  }

  get navigationItems(): NavigationItem[] {
    const mobileItems: NavigationItem[] = [
      {
        alt: this.$t("mobileHeader.customers"),
        text: this.$t("mobileHeader.customers"),
        image: "../img/customers.png",
        image2: "../img/white-icons/customers2.png",
        route: "/kunder",
        icon: "account_circle",
      },
      {
        alt: this.$t("mobileHeader.orders"),
        children: [],
        text: this.$t("mobileHeader.overview"),
        image: "../img/overview.png",
        route: "/bestillinger",
        icon: "event_note",
      },
      {
        alt: this.$t("mobileHeader.settings"),
        text: this.$t("mobileHeader.settings"),
        onlySmallMenu: true,
        image: "../img/settings.png",
        action: () => this.openSettings(),
        icon: "calendar_today",
      },
      {
        alt: this.$t("mobileHeader.log"),
        text: this.$t("mobileHeader.log"),
        image: "../img/commerce.png",
        image2: "../img/commerce.png",
        route: "/logg",
        icon: "post",
      },
      {
        alt: this.$t("mobileHeader.statistics"),
        text: this.$t("mobileHeader.statistics"),
        image: "../img/increasing-chart.png",
        route: "/statistikk",
        icon: "insert_chart",
      },
    ];

    const tabletItems: NavigationItem[] = [
      {
        alt: this.$t("mobileHeader.log"),
        text: this.$t("mobileHeader.log"),
        image: "../img/commerce.png",
        image2: "../img/commerce.png",
        route: "/logg",
        icon: "post",
      },
      {
        alt: this.$t("mobileHeader.statistics"),
        text: this.$t("mobileHeader.statistics"),
        image: "../img/increasing-chart.png",
        route: "/statistikk",
        icon: "insert_chart",
      },
    ];

    return this.width > 800 ? tabletItems : mobileItems;
  }

  handleItemClick(item: NavigationItem): void {
    if (item.action) {
      item.action();
    } else if (item.route) {
      this.logAudit(" went to route from navigation drawer: " + item.route);
      this.$router.push(item.route);
    }
  }

  openSettings(): void {
    this.logAudit(" opened settings dialog from navigation drawer");
    this.drawerVisible = false;
    this.$emit("settings-click");
  }
}
