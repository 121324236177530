var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',[(_vm.editingCustomer != null)?_c('edit-customer',{attrs:{"customer":_vm.editingCustomer},on:{"finish":function($event){_vm.editingCustomer = null}}}):_vm._e(),(_vm.viewingCustomer != null)?_c('customer-modal-window',{attrs:{"customer":_vm.viewingCustomer},on:{"finish":function($event){_vm.viewingCustomer = null},"delete":_vm.deletedCustomer}}):_vm._e(),(_vm.isLoading && _vm.filteredCustomers.length == 0)?_c('div',[_c('div',{staticClass:"treatment-widget"},[_c('loader')],1)]):_vm._e(),_c('v-card',{attrs:{"flat":""}},[(!_vm.isLoading || _vm.customers.length > 0)?_c('div',[_c('v-card-text',[_c('customer-actions',{attrs:{"is-showing-form":_vm.isShowingCustomerForm,"has-selected-customers":!_vm.hasSelectedCustomers},on:{"toggle-form":_vm.toggleCustomerForm,"delete-selected":_vm.deleteSelectedCustomers},scopedSlots:_vm._u([{key:"search",fn:function(){return [(!_vm.displayChoice)?_c('customer-search-bar',{model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}}):_vm._e()]},proxy:true}],null,false,543404854)}),(
            !_vm.isShowingCustomerForm && _vm.customers.length > 0 && _vm.displayChoice
          )?_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.searchQuery),expression:"searchQuery"}],staticClass:"search-for-user",attrs:{"type":"text","placeholder":_vm.$t('customer.searchForCustomer').toString()},domProps:{"value":(_vm.searchQuery)},on:{"keyup":_vm.filterCustomers,"input":function($event){if($event.target.composing)return;_vm.searchQuery=$event.target.value}}}):_c('div',[(!_vm.isLoading && _vm.displayChoice)?_c('div',{staticClass:"no-customers-wrapper"},[_c('h3',[_vm._v(_vm._s(_vm.$t("customer.noCustomersYet").toString()))])]):_vm._e()]),(
            _vm.filteredCustomers.length == 0 &&
            !_vm.isLoading &&
            _vm.searchQuery.length > 0
          )?_c('div',{staticClass:"no-customers-wrapper"},[_c('h3',[_vm._v(_vm._s(_vm.$t("customer.noSearchResults").toString()))])]):_vm._e()],1),_c('v-dialog',{attrs:{"max-width":"500"},model:{value:(_vm.isShowingCustomerForm),callback:function ($$v) {_vm.isShowingCustomerForm=$$v},expression:"isShowingCustomerForm"}},[(_vm.isShowingCustomerForm)?_c('create-customer',{on:{"close":function($event){_vm.isShowingCustomerForm = false},"created":_vm.onCreatedCustomer}}):_vm._e()],1),(!_vm.displayChoice)?_c('customer-table',{attrs:{"customers":_vm.filteredCustomers,"search":_vm.search,"is-mobile":_vm.isMobile()},on:{"edit":_vm.editCustomer},model:{value:(_vm.selectedCustomers),callback:function ($$v) {_vm.selectedCustomers=$$v},expression:"selectedCustomers"}}):_c('customer-list',{attrs:{"customers":_vm.filteredCustomers},on:{"delete":_vm.deletedCustomer}})],1):_vm._e()])],1)
}
var staticRenderFns = []

export { render, staticRenderFns }