
import Vue from "vue";
import { Component } from "vue-property-decorator";
import Layout from "../Layout.vue";

@Component({
  name: "MainContent",
  components: { Layout },
})
export default class MainContent extends Vue {
  get requireAuth(): boolean {
    return (
      this.$route.meta.requiresAuth != null &&
      this.$route.meta.requiresAuth === true
    );
  }

  get hasLayout(): boolean {
    return (
      this.$route.meta.hasLayout == null || this.$route.meta.hasLayout === true
    );
  }
}
