
import { Component, Vue } from "vue-property-decorator";
import SettingsGroup from "@/components/Settings/SettingsGroup.vue";
import SettingsListItem from "@/components/Settings/SettingsListItem.vue";
import StaffSettings from "@/components/Settings/StaffSettings.vue";
import { createSettingsData } from "@/data/settingsData";
import { IStaffMemberDTO } from "@/types/AutoGenerated/reinforcedTypings";
import { Getter, Action } from "vuex-class";

// Import all settings components
import CompanyInfoCard from "@/components/global/CompanyInfoCard.vue";
import SystemSettings from "@/components/Settings/SystemSettings.vue";
import TreatmentTypes from "@/components/Settings/Services/TreatmentTypes.vue";
import ServiceCategories from "@/components/global/ServiceCategories.vue";
import Resources from "@/components/Settings/Services/Resources.vue";
import SubscriptionInformation from "@/components/Settings/SubscriptionInformation.vue";
import AccountSettings from "@/components/Settings/AccountSettings.vue";
import BookingNotificationSettings from "@/components/Settings/Booking/BookingNotificationSettings.vue";
import BookingPageFieldSettings from "@/components/Settings/Booking/Booking-page/BookingPageFieldSettings.vue";
import GeneralBookingSettings from "@/components/Settings/Booking/GeneralBookingSettings.vue";
import BookingPageGeneralSettings from "@/components/Settings/Booking/Booking-page/BookingPageGeneralSettings.vue";
import ServiceOptions from "@/components/Settings/Services/ServiceOptions.vue";
import StaffMembersSettings from "@/components/Settings/StaffMembers/StaffMembersOverview.vue";
import CalendarStyleSettings from "@/components/Settings/Calendar/CalendarStyleSettings.vue";
import EmailListSettings from "@/components/Settings/Functions/EmailListSettings.vue";
import ExtraServices from "@/components/Settings/Services/ExtraServices.vue";
import BookingPageInformationSettings from "@/components/Settings/Booking/Booking-page/BookingPageInformationSettings.vue";
import BookingPageCustomerSettings from "@/components/Settings/Booking/Booking-page/BookingPageCustomerSettings.vue";

// Import staff-related components
import StaffMemberDetailsSettings from "@/components/Settings/StaffMembers/StaffMember/StaffMemberDetailsSettings.vue";
import StaffMemberServicesSettings from "@/components/Settings/StaffMembers/StaffMember/StaffMemberServicesSettings.vue";
import WorkingHours from "@/components/Settings/StaffMembers/StaffMember/WorkingHours.vue";
import VacationDays from "@/components/Settings/StaffMembers/StaffMember/VacationDays.vue";
import StaffMemberGoogleCalendarSettings from "@/components/Settings/StaffMembers/StaffMember/StaffMemberGoogleCalendarSettings.vue";
import StaffMembersOverview from "@/components/Settings/StaffMembers/StaffMembersOverview.vue";
import AuditOverview from "@/pages/admin/AuditOverview.vue";

import { toolbarConfig } from "./toolbarconfig";

@Component({
  components: {
    SettingsGroup,
    SettingsListItem,
    StaffSettings,
    CompanyInfoCard,
    SystemSettings,
    TreatmentTypes,
    ServiceCategories,
    Resources,
    SubscriptionInformation,
    AccountSettings,
    BookingNotificationSettings,
    BookingPageFieldSettings,
    GeneralBookingSettings,
    BookingPageGeneralSettings,
    ServiceOptions,
    StaffMembersSettings,
    CalendarStyleSettings,
    EmailListSettings,
    ExtraServices,
    BookingPageInformationSettings,
    BookingPageCustomerSettings,
    StaffMemberDetailsSettings,
    StaffMemberServicesSettings,
    WorkingHours,
    VacationDays,
    StaffMemberGoogleCalendarSettings,
    StaffMembersOverview,
    AuditOverview,
  },
})
export default class SettingsMobile extends Vue {
  settings = null;
  dialog = false;
  currentComponent = null;
  currentSettingTitle = "";
  componentProps = {};

  @Getter public staffMembers: IStaffMemberDTO[];
  @Action public getStaffMembers: () => Promise<void>;

  // Map routes to component names and titles
  routeComponentMap = {};

  get currentToolbarActions() {
    return toolbarConfig[this.currentComponent]?.actions || [];
  }

  get isMobile() {
    return /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
      navigator.userAgent
    );
  }

  handleAction(handlerName) {
    const component = this.$refs.currentComponentRef;
    if (component && typeof component[handlerName] === "function") {
      component[handlerName]();
    } else {
      console.warn(
        `Handler ${handlerName} is not defined on ${this.currentComponent}`
      );
    }
  }

  showSettingsDialog(route: string, disabled: boolean): void {
    if (!disabled && route) {
      const componentInfo = this.routeComponentMap[route];
      if (componentInfo) {
        this.currentComponent = componentInfo.component;
        this.currentSettingTitle = componentInfo.title;
        this.dialog = true;
      }
    }
  }

  handleStaffSettingsDialog(dialogInfo: {
    component: string;
    title: string;
    props: any;
  }) {
    this.currentComponent = dialogInfo.component;
    this.currentSettingTitle = dialogInfo.title;
    this.componentProps = dialogInfo.props || {};
    this.dialog = true;
  }

  created() {
    this.getStaffMembers();
    this.settings = createSettingsData();
    this.routeComponentMap = {
      "/settings/company-info": {
        component: "CompanyInfoCard",
        title: this.$t("settings.companyInfo").toString(),
      },
      "/settings/system": {
        component: "SystemSettings",
        title: this.$t("settings.system").toString(),
      },
      "/settings/services": {
        component: "TreatmentTypes",
        title: this.$t("settings.services").toString(),
      },
      "/settings/categories": {
        component: "ServiceCategories",
        title: this.$t("settings.categories").toString(),
      },
      "/settings/audit-log": {
        component: "AuditOverview",
        title: this.$t("settings.auditLog").toString(),
      },
      "/settings/resources": {
        component: "Resources",
        title: this.$t("settings.resources").toString(),
      },
      "/settings/subscription": {
        component: "SubscriptionInformation",
        title: this.$t("settings.subscription").toString(),
      },
      "/settings/accounts": {
        component: "AccountSettings",
        title: this.$t("settings.accounts").toString(),
      },
      "/settings/booking/alerts": {
        component: "BookingNotificationSettings",
        title: this.$t("settings.alerts").toString(),
      },
      "/settings/booking/customer-information": {
        component: "BookingPageFieldSettings",
        title: this.$t("settings.customerInformation").toString(),
      },
      "/settings/booking": {
        component: "GeneralBookingSettings",
        title: this.$t("settings.booking").toString(),
      },
      "/settings/booking/side": {
        component: "BookingPageGeneralSettings",
        title: this.$t("settings.bookingSide").toString(),
      },
      "/settings/options": {
        component: "ServiceOptions",
        title: this.$t("settings.options").toString(),
      },
      "/settings/staff": {
        component: "StaffMembersSettings",
        title: this.$t("settings.staff").toString(),
      },
      "/settings/calendar": {
        component: "CalendarStyleSettings",
        title: this.$t("settings.calendar").toString(),
      },
      "/settings/functions/newsletter": {
        component: "EmailListSettings",
        title: this.$t("settings.newsletter").toString(),
      },
      "/settings/extra-services": {
        component: "ExtraServices",
        title: this.$t("settings.extraServices").toString(),
      },
      "/settings/booking/information": {
        component: "BookingPageInformationSettings",
        title: this.$t("settings.information").toString(),
      },
      "/settings/booking/customer-account": {
        component: "BookingPageCustomerSettings",
        title: this.$t("settings.customerAccount").toString(),
      },
    };

    if (!this.isMobile) {
      let current = this.routeComponentMap["/settings/company-info"];
      this.currentComponent = current.component;
      this.currentSettingTitle = current.title;
    }
  }
}
