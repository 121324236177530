
import Vue from "vue";
import { Component, Watch } from "vue-property-decorator";
import { Action, Getter } from "vuex-class";
import Loader from "./components/global/Loader.vue";
import Layout from "./components/Layout.vue";
import DialogQueueDisplay from "./components/global/DialogQueueDisplay.vue";
import Sidebar from "./components/global/Sidebar.vue";
import MainHeader from "./components/global/MainHeader.vue";
import MobileHeader from "./components/MobileHeader.vue";
import RouteHeader from "./components/RouteHeader.vue";
import AppSnackbar from "./components/layout/AppSnackbar.vue";
import SettingsDialog from "./components/layout/SettingsDialog.vue";
import NavigationDrawer from "./components/layout/NavigationDrawer.vue";
import MainContent from "./components/layout/MainContent.vue";
import AuditLogger, { AuditLoggerType } from "./mixins/AuditLogger";

// Extend Vue type to include $workbox
declare module "vue/types/vue" {
  interface Vue {
    $workbox: {
      addEventListener: (event: string, callback: () => void) => void;
      messageSW: (message: { type: string }) => void;
    };
  }
}

// Extend IUser type to include language
interface IUser {
  language?: string;
  [key: string]: any;
}

@Component({
  name: "App",
  components: {
    MobileHeader,
    MainHeader,
    Sidebar,
    DialogQueueDisplay,
    Loader,
    Layout,
    RouteHeader,
    AppSnackbar,
    SettingsDialog,
    NavigationDrawer,
    MainContent,
  },
  mixins: [AuditLogger],
})
export default class App extends Vue implements AuditLoggerType {
  @Getter public user!: IUser | undefined;
  @Getter public hasResolvedUser!: boolean | undefined;
  @Action public getCurrentUser!: Function;

  settingsOpen = false;
  width = 0;
  height = 0;

  // Implement the mixin method to satisfy TypeScript
  logAudit!: (message: string, action?: number, type?: number) => void;

  get mobileNavigation() {
    return this.$store.getters.mobileNavigation;
  }

  set mobileNavigation(v) {
    this.$store.commit("setMobileNavigation", v);
  }

  @Watch("user")
  onChangeUser(newUser: IUser) {
    if (
      newUser?.language != null &&
      newUser.language !== "" &&
      newUser.language !== localStorage.getItem("locale")
    ) {
      this.$i18n.locale = newUser.language;
      localStorage.setItem("locale", newUser.language);
    }
  }

  isMobile(): boolean {
    return /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
      navigator.userAgent
    );
  }

  getDimensions(): void {
    this.width = document.documentElement.clientWidth;
    this.height = document.documentElement.clientHeight;
  }

  openSettings(): void {
    this.logAudit(" opened settings dialog");
    this.settingsOpen = true;
  }

  get requireAuth(): boolean {
    return (
      this.$route.meta.requiresAuth != null &&
      this.$route.meta.requiresAuth === true
    );
  }

  get hasLayout(): boolean {
    return (
      this.$route.meta.hasLayout == null || this.$route.meta.hasLayout === true
    );
  }

  get showMobileToolbar(): boolean {
    return (
      this.$route.meta.hideMobileHeader == null ||
      this.$route.meta.hideMobileHeader === false
    );
  }

  async mounted(): Promise<void> {
    if (this.isMobile()) {
      this.getDimensions();
      window.addEventListener("resize", this.getDimensions);
    }
  }

  async created(): Promise<void> {
    if (this.$workbox) {
      this.$workbox.addEventListener("waiting", () => {
        this.$workbox.messageSW({ type: "SKIP_WAITING" });
      });
    }
  }
}
