// @ts-ignore
import Vue from "vue";
// @ts-ignore
import Vuex from "vuex";
import { user } from "./user";
import { appointmentTypes } from "./appointmentTypes";
import { customers } from "./customers";
import { customerJournals } from "./customerJournals";
import { appointments } from "./appointments/appointments";
import { workingHours } from "./workingHours";
import { snackbar } from "./snackbar";
import { onboarding } from "./Onboarding";
import { company } from "./company";
import { notifications } from "./notifications";
import { checkout } from "./checkout";
import { statistics } from "./statistics";
import { muscleComment } from "./muscleComment";
import { customerAccounts } from "./customerAccounts";
import { dialog } from "./dialog";
import { events } from "./Events";
import { invoices } from "./Invoices";
import { insurances } from "./Insurances";
import { payments } from "./Payments";
import { layout } from "./layout";
import { staffMember } from "./staffMember";
import { emailList } from "./emailList";
import { resources } from "./resources";
import { customFields } from "./customFields";
import CalendarModule from "./calendar";

// import customers from './customers';

export interface RootState {}

Vue.use(Vuex);

export const store = new Vuex.Store({
  modules: {
    user,
    appointmentTypes,
    customers,
    customerJournals,
    appointments,
    workingHours,
    snackbar,
    onboarding,
    company,
    notifications,
    checkout,
    statistics,
    muscleComment,
    dialog,
    customerAccounts,
    events,
    invoices,
    insurances,
    payments,
    layout,
    staffMember,
    emailList,
    resources,
    customFields,
    calendar: CalendarModule,
  },
});
