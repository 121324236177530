
import { Component, Prop, Vue } from "vue-property-decorator";
import Sidebar from "@/components/global/Sidebar.vue";
import HeaderBar from "../../components/global/MainHeader.vue";
import { AppointmentInfo } from "@/store/appointments/appointmentTypings";
import { getRequest } from "@/Utilities/axiosHelper";
import Loader from "@/components/global/Loader.vue";
import { formatDate, formatTime } from "@/types/formatDateHelper";
import CustomButton from "../../components/global/styledComponents/customButton.vue";
import { DialogType, IDialog } from "@/store/dialog";
import { Action, Getter, Mutation } from "vuex-class";
import CustomerModal from "../../components/global/CustomerModal.vue";
import CustomerModalWindow from "../../components/global/CustomerModalWindow.vue";
import CreateAppointment from "../../components/global/CreateAppointment.vue";
import { IUser } from "@/store/user";
import {
  IAppointmentDTO,
  IAppointmentInfoDTO,
  IAuditLogDTO,
} from "@/types/AutoGenerated/reinforcedTypings";
import { IAppointmentPatch } from "@/store/appointments/appointments";
import {
  formatDateString,
  formatDateStringHour,
} from "@/Utilities/dateUtility";

@Component({
  name: "Appointment-list",
  components: {
    CreateAppointment,
    CustomerModalWindow,
    CustomerModal,
    CustomButton,
    Loader,
    Sidebar,
    HeaderBar,
  },
})
export default class AppointmentList extends Vue {
  public toggleIndex: number | null = null;

  @Prop({}) customerId;
  @Prop({ default: true }) viewCustomerDetails;
  @Prop({ default: true }) showPaid;
  @Prop({ default: false }) chooseAppointment;
  @Prop({ default: true }) tableActions;
  @Action public addToDialogQueue: Function;
  @Action patchAppointment: Function;
  @Action public postSnackbarMessage: Function;
  @Action public deleteBookedAppointment: Function;

  @Action postAuditLog: Function;

  @Action public getAppointmentsForOverview: Function;
  @Getter public appointmentsForOverview: Function;
  @Getter public user: IUser;

  @Action updateAppointment: Function;

  @Action public selectDateToBook: Function;
  @Mutation public setDefaultCustomerToBook: Function;
  displayChoice = false;
  displayChoice2 = false;
  viewingCustomer = null;

  options = {};

  statusFilters = [];

  private defaultSorting: string = "created";
  private defaultDesc: string = "true";

  get invoiceStatuses() {
    if (this.user.enablePaymentModule) {
      return [
        "Ny",
        "Faktura opprettet",
        "Faktura sendt",
        "Faktura betalt",
        "Ferdig",
      ];
    } else {
      return ["Ny", "Ferdig"];
    }
  }

  readingNote: AppointmentInfo = null;

  appointments: AppointmentInfo[] | null = null;
  isLoading: boolean = true;

  public search: string = "";
  selectedAppointments = [];
  tags = [];
  private bookOpen: boolean = false;
  private isReadingNoteDialog: boolean = false;

  async created() {
    let overViewSorting = localStorage.getItem("overviewsorting");
    let desc = localStorage.getItem("overviewsortingdesc");
    let savedFilters = localStorage.getItem("statusfilter");
    if (savedFilters != null) {
      this.statusFilters = savedFilters.split(",");
    } else {
      this.statusFilters = [
        "Ny",
        "Faktura opprettet",
        "Faktura sendt",
        "Faktura betalt",
        "Ferdig",
      ];
    }

    if (overViewSorting != null) {
      this.defaultSorting = overViewSorting;
    }
    if (desc != null) {
      this.defaultDesc = desc;
    }

    let vm = this;
    await vm.getAppointments();
  }

  formatTimeCorrectly(hour: string | number, minute: string | number): string {
    return formatTime(hour, minute);
  }

  formatDateStringHour(date: Date) {
    return formatDateStringHour(date);
  }
  formatDateString(date: Date) {
    return formatDateString(date);
  }
  get hasAppointments(): boolean {
    if (this.customerId == null) {
      return this.appointmentsForOverview.length > 0 || !this.isLoading;
    } else {
      return this.appointments != null || !this.isLoading;
    }
  }
  deletedCustomer() {
    this.viewingCustomer = null;
  }
  public get headers() {
    if (this.viewCustomerDetails) {
      if (!this.displayChoice2) {
        let array = [
          {
            text: this.$t("appointment.number"),
            sortable: true,
            value: "number",
          },
          {
            text: this.$t("appointment.service"),
            sortable: true,
            value: "appointmentName",
          },
          { text: this.$t("appointment.time"), sortable: true, value: "date" },
          {
            text: this.$t("appointment.customer"),
            sortable: true,
            value: "customerName",
          },
          {
            text: this.$t("appointment.appointmentStatus"),
            value: "status",
            sortable: true,
          },
          {
            text: this.$t("appointment.comment"),
            value: "comment",
            sortable: false,
          },
          {
            text: this.$t("appointment.adminNote"),
            value: "adminNote",
            sortable: false,
          },
          {
            text: this.$t("appointment.created"),
            sortable: true,
            value: "created",
          },
        ];

        if (!this.showPaid) {
          this.statusFilters = ["Ny"];
        }

        if (this.chooseAppointment) {
          array.splice(0, 0, {
            text: "",
            value: "selectAppointment",
            sortable: true,
          });
        }

        return array;
      } else {
        let array = [
          {
            text: this.$t("appointment.number"),
            sortable: true,
            value: "number",
          },
          {
            text: this.$t("appointment.service"),
            sortable: true,
            value: "appointmentName",
          },
          { text: this.$t("appointment.time"), sortable: true, value: "date" },
          {
            text: this.$t("appointment.customer"),
            sortable: true,
            value: "customerName",
          },
          {
            text: this.$t("appointment.email"),
            sortable: true,
            value: "customerEmail",
          },
          { text: this.$t("appointment.phone"), value: "customerPhone" },
          {
            text: this.$t("appointment.address"),
            value: "customerAddress",
            sortable: false,
          },
          {
            text: this.$t("appointment.postalCode"),
            value: "customerPostalCode",
            sortable: false,
          },
          {
            text: this.$t("appointment.createdByCustomer"),
            value: "createdByCustomer",
            sortable: false,
          },
          {
            text: this.$t("appointment.status"),
            value: "status",
            sortable: true,
          },
          {
            text: this.$t("appointment.comment"),
            value: "comment",
            sortable: false,
          },
          {
            text: this.$t("appointment.adminNote"),
            value: "adminNote",
            sortable: false,
          },
          {
            text: this.$t("appointment.created"),
            sortable: true,
            value: "created",
          },
        ];

        if (this.chooseAppointment) {
          array.splice(0, 0, {
            text: "",
            value: "selectAppointment",
            sortable: true,
          });
        }

        return array;
      }
    } else {
      return [
        {
          text: this.$t("appointment.number"),
          sortable: true,
          value: "number",
        },
        {
          text: this.$t("appointment.service"),
          sortable: true,
          value: "appointmentName",
        },
        { text: this.$t("appointment.time"), sortable: true, value: "date" },
        // {text: this.$t('appointment.createdByCustomer'), value: 'createdByCustomer', sortable: false},
        {
          text: this.$t("appointment.status"),
          value: "status",
          sortable: true,
        },
        {
          text: this.$t("appointment.comment"),
          value: "comment",
          sortable: false,
        },
        {
          text: this.$t("appointment.adminNote"),
          value: "adminNote",
          sortable: false,
        },
        {
          text: this.$t("appointment.created"),
          sortable: true,
          value: "created",
        },
      ];
    }
  }
  get appointmentsForDataTable() {
    let returnValue: any = []; //TODO: Type appointment for overview

    if (this.customerId == null) {
      returnValue = this.appointmentsForOverview;
    } else {
      returnValue = this.appointments;
    }
    let filteredByStatus = returnValue.filter((x) => {
      return this.statusFilters.includes(x.status) || x.status == null;
    });

    if (this.defaultSorting == null) {
      return filteredByStatus;
    }

    let sortValue = this.defaultSorting;

    let desc = true;
    if (this.defaultDesc != null) {
      desc = this.defaultDesc == "true";
    }

    if (desc) {
      return filteredByStatus.sort(this.dynamicSortDesc(sortValue));
    } else {
      return filteredByStatus.sort(this.dynamicSort(sortValue));
    }
  }
  dynamicSortDesc(property) {
    let sortOrder = 1;
    if (property[0] === "-") {
      sortOrder = -1;
      property = property.substr(1);
    }
    return function (a, b) {
      /* next line works with strings and numbers,
       * and you may want to customize it to your needs
       */
      let result =
        a[property] > b[property] ? -1 : a[property] < b[property] ? 1 : 0;
      return result * sortOrder;
    };
  }
  dynamicSort(property) {
    let sortOrder = 1;
    if (property[0] === "-") {
      sortOrder = -1;
      property = property.substr(1);
    }
    return function (a, b) {
      /* next line works with strings and numbers,
       * and you may want to customize it to your needs
       */
      let result =
        a[property] < b[property] ? -1 : a[property] > b[property] ? 1 : 0;
      return result * sortOrder;
    };
  }
  getDesc() {
    if (this.defaultDesc != null) {
      return this.defaultDesc == "true";
    }
    return true;
  }
  getSort() {
    let overViewSorting = localStorage.getItem("overviewsorting");
    if (overViewSorting != null) {
      return overViewSorting;
    }
    return this.defaultSorting;
  }
  sortByChanged(newSortBy) {
    this.defaultSorting = newSortBy;
    localStorage.setItem("overviewsorting", newSortBy);
  }
  sortByChangedDesc(newSortDesc) {
    this.defaultDesc = newSortDesc.toString();
    localStorage.setItem("overviewsortingdesc", newSortDesc.toString());
  }
  async getAppointments() {
    if (this.customerId == null) {
      this.appointments = await this.getAppointmentsForOverview();
    } else {
      this.appointments = await getRequest(
        "appointment/current/customer/" + this.customerId
      );
    }
    this.isLoading = false;
  }
  onStatusChange() {
    localStorage.setItem("statusfilter", this.statusFilters.join(","));
  }
  readComment(comment) {
    this.postSnackbarMessage(comment);
  }
  readNote(note) {
    this.readingNote = note;
    this.isReadingNoteDialog = true;
  }

  async updateAdminComment(event, selectedEvent: IAppointmentInfoDTO) {
    let update: Partial<IAppointmentDTO> = {
      adminNote: selectedEvent.adminNote,
    };

    const payload: IAppointmentPatch = {
      appointmentId: selectedEvent.id,
      patch: update,
    };

    this.isReadingNoteDialog = false;
    this.readingNote = null;

    await this.patchAppointment(payload);
  }

  onSelectedAppointment(item) {
    this.$emit("selected", item);
  }
  editCustomer(item) {
    this.$router.push("/kunder/" + item.customerId);
  }
  viewCustomer(item) {
    this.viewingCustomer = item.customerId;
  }
  logShowMoreCustomerInfo() {
    this.postAuditLog({
      action: "show_more_customer_info",
      details: {
        showMore: this.displayChoice2,
      },
    });
  }
  get hasSelectedAppointments() {
    return this.selectedAppointments.length === 0;
  }
  get hasSelectedAllFilters() {
    return this.statusFilters.length === this.invoiceStatuses.length;
  }
  getColor(status) {
    switch (status) {
      case "Ny":
        return "light";
      case "Faktura opprettet":
        return "orange";
      case "Faktura sendt":
        return "yellow";
      case "Faktura betalt":
        return "green";
      case "Ferdig":
        return "#80cc74";
      default:
        return "grey";
    }
  }
  deleteSelectedAppointments() {
    let vm = this;

    let dialog: IDialog = {
      text:
        vm.selectedAppointments.length +
        " " +
        this.$t("appointmentList.dialog.delete_appointments.message"),
      type: DialogType.Choice,
      header: this.$t("appointmentList.dialog.delete_appointments.header"),
      action: function () {
        let audit: IAuditLogDTO = {
          action: "delete_appointments",
          details: {
            appointments: vm.selectedAppointments.map((x) => x.id),
          },
        };

        vm.postAuditLog(audit);

        vm.postSnackbarMessage(
          vm.selectedAppointments.length +
            " " +
            this.$t(
              "appointmentList.dialog.delete_appointments.confirmationMessage"
            )
        );
        vm.selectedAppointments.forEach((x) => {
          vm.deleteBookedAppointment({ appointmentId: x.id });
        });
        vm.selectedAppointments = [];
      },
    };

    this.addToDialogQueue(dialog);
  }
}
