
import Vue from "vue";
import Component from "vue-class-component";
import { Action, Getter } from "vuex-class";
import { IStaffMemberDTO } from "@/types/AutoGenerated/reinforcedTypings";
import { Watch } from "vue-property-decorator";
import { DialogType, IDialog } from "@/store/dialog";
import ResponsiveTable from "@/components/global/ResponsiveTable.vue";

@Component({
  name: "StaffMembersOverview",
  components: {
    ResponsiveTable,
  },
})
export default class StaffMembersOverview extends Vue {
  public createNewStaffMember: boolean = false;
  public editStaffMember: boolean = false;
  public newStaffMember: Partial<IStaffMemberDTO> | null = null;
  public selectedStaff: IStaffMemberDTO | null = null;

  @Action postStaffMember!: Function;
  @Action deleteStaffMember!: Function;
  @Action public getStaffMembers!: Function;
  @Action public addToDialogQueue!: Function;
  @Action public updateStaffMember!: Function;
  @Getter public staffMembers!: IStaffMemberDTO[];

  @Watch("createNewStaffMember")
  onNewStaffMemberToggle() {
    this.resetNewStaffMember();
  }

  created() {
    this.getStaffMembers();
    this.resetNewStaffMember();
  }

  resetNewStaffMember(): void {
    this.newStaffMember = {
      firstName: "",
      lastName: "",
      emailAddress: "",
      phoneNumber: "",
    };
  }

  get headers() {
    return [
      { text: "", align: "start", sortable: true, value: "severity" },
      {
        text: this.$t("staffMembersOverview.firstName"),
        sortable: true,
        value: "firstName",
      },
      {
        text: this.$t("staffMembersOverview.lastName"),
        sortable: true,
        value: "lastName",
      },
      {
        text: this.$t("staffMembersOverview.emailAddress"),
        sortable: true,
        value: "emailAddress",
      },
      {
        text: this.$t("staffMembersOverview.phoneNumber"),
        sortable: true,
        value: "phoneNumber",
      },
      {
        text: this.$t("staffMembersOverview.lastLoggedIn"),
        sortable: true,
        value: "lastLoggedIn",
      },
      {
        text: this.$t("staffMembersOverview.created"),
        align: "start",
        sortable: true,
        value: "created",
      },
      {
        text: this.$t("staffMembersOverview.actions"),
        align: "center",
        sortable: false,
        value: "actions",
      },
    ];
  }

  get items() {
    return this.staffMembers;
  }

  openNewStaffMember(): void {
    this.createNewStaffMember = true;
  }

  cancelNewStaffMember(): void {
    this.createNewStaffMember = false;
  }

  editStaff(staff: IStaffMemberDTO): void {
    this.selectedStaff = { ...staff };
    this.editStaffMember = true;
  }

  async confirmNewStaffMember(): Promise<void> {
    try {
      if (!this.newStaffMember) return;

      // Validate required fields
      if (
        !this.newStaffMember.firstName ||
        !this.newStaffMember.lastName ||
        !this.newStaffMember.emailAddress ||
        !this.newStaffMember.phoneNumber
      ) {
        this.addToDialogQueue({
          text: this.$t("staffMembersOverview.allFieldsRequired").toString(),
        });
        return;
      }

      await this.postStaffMember(this.newStaffMember);
      this.createNewStaffMember = false;
    } catch (error) {
      console.error(error);
      this.addToDialogQueue({
        text: this.$t("staffMembersOverview.errorCreatingStaff").toString(),
      });
    }
  }

  async confirmEditStaff(): Promise<void> {
    try {
      if (!this.selectedStaff) return;

      // Validate required fields
      if (
        !this.selectedStaff.firstName ||
        !this.selectedStaff.lastName ||
        !this.selectedStaff.emailAddress ||
        !this.selectedStaff.phoneNumber
      ) {
        this.addToDialogQueue({
          text: this.$t("staffMembersOverview.allFieldsRequired").toString(),
        });
        return;
      }

      await this.updateStaffMember(this.selectedStaff);
      this.editStaffMember = false;
    } catch (error) {
      console.error(error);
      this.addToDialogQueue({
        text: this.$t("staffMembersOverview.errorUpdatingStaff").toString(),
      });
    }
  }

  async confirmDeleteStaff(staff: IStaffMemberDTO): Promise<void> {
    if (this.staffMembers.length === 1) {
      this.addToDialogQueue({
        text: this.$t("staffMembersOverview.minOneStaffRequired").toString(),
      });
      return;
    }

    try {
      await this.deleteStaffMember(staff.id);
    } catch (error) {
      console.error(error);
      this.addToDialogQueue({
        text: this.$t("staffMembersOverview.errorDeletingStaff").toString(),
      });
    }
  }

  deleteStaff(staff: IStaffMemberDTO): void {
    const vm = this;
    const dialog: IDialog = {
      text: this.$t("staffMembersOverview.confirmDeleteStaff").toString(),
      type: DialogType.Choice,
      action: function () {
        vm.confirmDeleteStaff(staff);
      },
    };
    this.addToDialogQueue(dialog);
  }
}
