
import { Component, Prop, Vue } from "vue-property-decorator";
import CalendarSettingsDialog from "./CalendarSettingsDialog.vue";

@Component({
  components: {
    CalendarSettingsDialog,
  },
})
export default class CalendarMobileHeader extends Vue {
  @Prop({ required: true }) monthName!: string;
  @Prop({ default: false }) showSettings!: boolean;

  prev() {
    this.$emit("prev");
  }

  next() {
    this.$emit("next");
  }
}
