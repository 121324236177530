
import Vue from "vue";
import Component from "vue-class-component";
import { Action, Getter } from "vuex-class";
import ToolTip from "../../../ToolTip.vue";
import CompanyInfoField from "../../../global/CompanyInfoField.vue";
import {
  IAuditLogDTO,
  IUserDTO,
} from "../../../../types/AutoGenerated/reinforcedTypings";
import { IUser } from "../../../../store/user";

@Component({
  name: "BookingPageInformationSettings",
  components: {
    ToolTip,
    CompanyInfoField,
  },
})
export default class BookingPageInformationSettings extends Vue {
  public showInformationBox: boolean = false;
  public informationBoxText: string = "";
  public cancellationBoxText: string = "";

  @Action public postSnackbarMessage!: Function;
  @Getter public user!: IUserDTO;
  @Action public patchUser!: Function;
  @Action public postAuditLog!: Function;

  created() {
    this.showInformationBox = this.user.showInformationBox;
    this.informationBoxText = this.user.informationBoxText;
    this.cancellationBoxText = this.user.cancellationBoxText;
  }

  async saveChanges() {
    if (this.showInformationBox !== this.user.showInformationBox) {
      const audit: Partial<IAuditLogDTO> = {
        action: 1,
        type: 2,
        comment: "",
        message: this.$t(
          "bookingPageInformationSettings.auditLogs.changedShowInformationBox",
          {
            setting: this.booleanToOnOff(this.showInformationBox),
          }
        ).toString(),
        hideForUser: false,
      };
      await this.postAuditLog(audit);
    }
    if (this.informationBoxText !== this.user.informationBoxText) {
      const audit: Partial<IAuditLogDTO> = {
        action: 1,
        type: 2,
        comment: "",
        message: this.$t(
          "bookingPageInformationSettings.auditLogs.changedInformationBoxText"
        ).toString(),
        hideForUser: false,
      };
      await this.postAuditLog(audit);
    }

    const update: Partial<IUserDTO> = {
      showInformationBox: this.showInformationBox,
      informationBoxText: this.informationBoxText,
      cancellationBoxText: this.cancellationBoxText,
    };

    await this.patchUser(update);
  }

  booleanToOnOff(value: boolean): string {
    return value ? this.$t("boolean.on") : this.$t("boolean.off");
  }

  get isOldDomain() {
    return window.location.toString().includes("vergba");
  }
}
