import { render, staticRenderFns } from "./TreatmentTypeForm.vue?vue&type=template&id=48a89abe&scoped=true&"
import script from "./TreatmentTypeForm.vue?vue&type=script&lang=ts&"
export * from "./TreatmentTypeForm.vue?vue&type=script&lang=ts&"
import style0 from "./TreatmentTypeForm.vue?vue&type=style&index=0&id=48a89abe&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "48a89abe",
  null
  
)

export default component.exports