import firebase from "firebase/app";

import { store } from "../store/store";
import { AxiosRequestConfig } from "axios";

export interface AuthConfig {
  headers: {
    Authorization: string;
  };
  credentials: boolean;
  responseType?: string;
}

let fb_apiKey = "AIzaSyBQqkT7q1GezrmpiggWDEZPmiVLGIKYc4g";
let fb_authDomain = "timebestiller.firebaseapp.com";
let fb_databaseURL = "https://timebestiller.firebaseio.com";
let fb_projectId = "timebestiller";
let fb_storageBucket = "timebestiller.appspot.com";
let fb_messagingSenderId = "605449339616";
let fb_appId = "1:605449339616:web:40f5469e2d3f05c4";

try {
  let new_fb_apiKey = "";
  let new_fb_authDomain = "";
  let new_fb_databaseURL = "";
  let new_fb_projectId = "l";
  let new_fb_storageBucket = "";
  let new_fb_messagingSenderId = "";
  let new_fb_appId = "";
  // @ts-ignore
  new_fb_apiKey = azurevar_fb_apiKey_token_endazurevar;
  // @ts-ignore
  new_fb_authDomain = azurevar_fb_authDomain_token_endazurevar;
  // @ts-ignore
  new_fb_databaseURL = azurevar_fb_databaseURL_token_endazurevar;
  // @ts-ignore
  new_fb_projectId = azurevar_fb_projectId_token_endazurevar;
  // @ts-ignore
  new_fb_storageBucket = azurevar_fb_storageBucket_token_endazurevar;
  // @ts-ignore
  new_fb_messagingSenderId = azurevar_fb_messagingSenderId_token_endazurevar;
  // @ts-ignore
  new_fb_appId = azurevar_fb_appId_token_endazurevar;

  fb_apiKey = new_fb_apiKey;
  fb_authDomain = new_fb_authDomain;
  fb_databaseURL = new_fb_databaseURL;
  fb_projectId = new_fb_projectId;
  fb_storageBucket = new_fb_storageBucket;
  fb_messagingSenderId = new_fb_messagingSenderId;
  fb_appId = new_fb_appId;
} catch (error) {
  if (window.location.hostname !== "localhost") {
    console.warn("One or more firebase config settings were undefined.");
  }
}

try {
  if (process.env.VUE_APP_FB_API_KEY != null) {
    fb_apiKey = process.env.VUE_APP_FB_API_KEY;
  }
  if (process.env.VUE_APP_FB_AUTH_DOMAIN != null) {
    fb_authDomain = process.env.VUE_APP_FB_AUTH_DOMAIN;
  }
  if (process.env.VUE_APP_FB_DATABASE_URL != null) {
    fb_databaseURL = process.env.VUE_APP_FB_DATABASE_URL;
  }
  if (process.env.VUE_APP_FB_PROJECT_ID != null) {
    fb_projectId = process.env.VUE_APP_FB_PROJECT_ID;
  }
  if (process.env.VUE_APP_FB_STORAGE_BUCKET != null) {
    fb_storageBucket = process.env.VUE_APP_FB_STORAGE_BUCKET;
  }
  if (process.env.VUE_APP_FB_MESSAGING_SENDER_ID != null) {
    fb_messagingSenderId = process.env.VUE_APP_FB_MESSAGING_SENDER_ID;
  }
  if (process.env.VUE_APP_FB_APP_ID != null) {
    fb_appId = process.env.VUE_APP_FB_APP_ID;
  }
} catch (e) {
  console.error("Failed with environent variables");
}

const FB_CONFIG = {
  apiKey: fb_apiKey,
  authDomain: fb_authDomain,
  databaseURL: fb_databaseURL,
  projectId: fb_projectId,
  storageBucket: fb_storageBucket,
  messagingSenderId: fb_messagingSenderId,
  appId: fb_appId,
};

// console.log("FB config: ", FB_CONFIG);

export function getFbConfig() {
  return FB_CONFIG;
}

firebase.initializeApp(FB_CONFIG);

export const askForPermissioToReceiveNotifications = async () => {
  try {
    const messaging = firebase.messaging();
    await messaging.requestPermission();
    const token = await messaging.getToken();

    return token;
  } catch (error) {
    console.error(error);
  }
};

export async function getToken() {
  let currentUser: any = firebase.auth().currentUser;
  if (currentUser == null) {
    console.warn("Current user is null");
    await firebase.auth().signOut();
    return "NULL";
  }
  let idToken = await currentUser.getIdToken(true);
  return idToken;
}

async function authConfig(): Promise<AxiosRequestConfig> {
  let token = await getToken();
  //@ts-ignore
  let authConfig: any = {
    headers: { Authorization: "bearer " + token },
    credentials: true,
  };
  return authConfig;
}

export async function googleAuthConfig(): Promise<AxiosRequestConfig> {
  let token = await getToken();

  const headers = {
    "Content-Type": "application/octet-stream; charset=utf-8",
    "X-Requested-With": "JXMLHttpRequest",
  };

  //@ts-ignore
  let authConfig: any = {
    headers: {
      Authorization: "bearer " + token,
      "X-requested-With": "JXMLHttpRequest",
    },
    credentials: true,
    "Content-type": "application/octet-stream; charset=utf-8",
  };
  return authConfig;
}

export function logout(): Promise<void> {
  store.commit("setIsLoggedIn", false);
  return new Promise((resolve, reject) => {
    firebase
      .auth()
      .signOut()
      .then((x) => {
        return resolve(x);
      });
  });
}

export function isLoggedIn(): boolean {
  return firebase.auth().currentUser !== null;
}

export default authConfig;
