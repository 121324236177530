var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',[_c('v-row',[_c('v-col',[_c('v-row',[_c('v-card',{attrs:{"flat":""}},[_c('v-card-text',[_c('h2',[_vm._v(_vm._s(_vm.$t("selectFieldsCustomerFillsWhenBooking")))])]),_c('v-card-text',[_c('v-checkbox',{attrs:{"label":_vm.$t('name'),"readonly":_vm.customerRequiredName === true,"disabled":_vm.customerRequiredName === true},on:{"change":function($event){return _vm.saveFieldChange('customerRequiredName', $event)}},model:{value:(_vm.customerRequiredName),callback:function ($$v) {_vm.customerRequiredName=$$v},expression:"customerRequiredName"}}),_c('v-row',[_c('v-col',[_c('v-checkbox',{attrs:{"label":_vm.$t('email'),"readonly":_vm.customerReceiptAndReminderMode === 'email' ||
                    _vm.verificationType == 1,"disabled":_vm.customerReceiptAndReminderMode === 'email' ||
                    _vm.verificationType == 1},on:{"change":function($event){return _vm.saveFieldChange('customerRequiredEmail', $event)}},model:{value:(_vm.customerRequiredEmail),callback:function ($$v) {_vm.customerRequiredEmail=$$v},expression:"customerRequiredEmail"}})],1),_c('v-col',[(_vm.customerRequiredEmail)?_c('v-checkbox',{attrs:{"label":_vm.$t('optional'),"readonly":_vm.customerReceiptAndReminderMode === 'email' ||
                    _vm.verificationType == 1,"disabled":_vm.customerReceiptAndReminderMode === 'email' ||
                    _vm.verificationType == 1},on:{"change":function($event){return _vm.saveFieldChange('customerRequiredEmailOptional', $event)}},model:{value:(_vm.customerRequiredEmailOptional),callback:function ($$v) {_vm.customerRequiredEmailOptional=$$v},expression:"customerRequiredEmailOptional"}}):_vm._e()],1)],1),_c('v-row',[_c('v-col',[_c('v-checkbox',{attrs:{"label":_vm.$t('phoneNumber'),"readonly":_vm.useCustomerAccounts ||
                    _vm.customerReceiptAndReminderMode === 'sms',"disabled":_vm.useCustomerAccounts ||
                    _vm.customerReceiptAndReminderMode === 'sms'},on:{"change":function($event){return _vm.saveFieldChange('customerRequiredPhoneNumber', $event)}},model:{value:(_vm.customerRequiredPhoneNumber),callback:function ($$v) {_vm.customerRequiredPhoneNumber=$$v},expression:"customerRequiredPhoneNumber"}})],1),_c('v-col',[(_vm.customerRequiredPhoneNumber)?_c('v-checkbox',{attrs:{"label":_vm.$t('optional'),"readonly":_vm.useCustomerAccounts ||
                    _vm.customerReceiptAndReminderMode === 'sms',"disabled":_vm.useCustomerAccounts ||
                    _vm.customerReceiptAndReminderMode === 'sms'},on:{"change":function($event){return _vm.saveFieldChange(
                      'customerRequiredPhoneNumberOptional',
                      $event
                    )}},model:{value:(_vm.customerRequiredPhoneNumberOptional),callback:function ($$v) {_vm.customerRequiredPhoneNumberOptional=$$v},expression:"customerRequiredPhoneNumberOptional"}}):_vm._e()],1)],1),_c('v-checkbox',{attrs:{"label":_vm.$t('address')},on:{"change":function($event){return _vm.saveFieldChange('customerRequiredAddress', $event)}},model:{value:(_vm.customerRequiredAddress),callback:function ($$v) {_vm.customerRequiredAddress=$$v},expression:"customerRequiredAddress"}}),_c('v-checkbox',{attrs:{"label":_vm.$t('postalCode')},on:{"change":function($event){return _vm.saveFieldChange('customerRequiredPostalCode', $event)}},model:{value:(_vm.customerRequiredPostalCode),callback:function ($$v) {_vm.customerRequiredPostalCode=$$v},expression:"customerRequiredPostalCode"}}),_c('v-checkbox',{attrs:{"label":_vm.$t('city')},on:{"change":function($event){return _vm.saveFieldChange('customerRequiredCity', $event)}},model:{value:(_vm.customerRequiredCity),callback:function ($$v) {_vm.customerRequiredCity=$$v},expression:"customerRequiredCity"}}),_c('v-checkbox',{attrs:{"label":_vm.$t('ssn')},on:{"change":function($event){return _vm.saveFieldChange('customerRequiredSSN', $event)}},model:{value:(_vm.customerRequiredSSN),callback:function ($$v) {_vm.customerRequiredSSN=$$v},expression:"customerRequiredSSN"}}),_c('v-checkbox',{attrs:{"label":_vm.$t('birthdate')},on:{"change":function($event){return _vm.saveFieldChange('customerRequiredBirthdate', $event)}},model:{value:(_vm.customerRequiredBirthdate),callback:function ($$v) {_vm.customerRequiredBirthdate=$$v},expression:"customerRequiredBirthdate"}}),_c('v-checkbox',{attrs:{"label":_vm.$t('gender')},on:{"change":function($event){return _vm.saveFieldChange('customerRequiredGender', $event)}},model:{value:(_vm.customerRequiredGender),callback:function ($$v) {_vm.customerRequiredGender=$$v},expression:"customerRequiredGender"}})],1)],1)],1)],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }