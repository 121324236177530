// @ts-ignore
import axios, { AxiosRequestConfig } from "axios";
// @ts-ignore
import { GetterTree, MutationTree, ActionTree } from "vuex";
import { APIURL2, i18n } from "@/main";
import authConfig, { AuthConfig } from "@/Utilities/authConfig";
import { actionStringSnackbar } from "@/store/snackbar";
import { getCustomers } from "@/Utilities/endpoints";
import { getCustomer } from "@/Utilities/endpoints";
import { getCustomerStatistics } from "@/Utilities/endpoints";

// Interface
export interface customerState {
  customers: Array<ICustomer>;
  customerAmount: number;
  defaultCustomerToBook: ICustomer | null;
}

// Interface Model
export interface ICustomer {
  id: number;
  adminId: string;
  firstName: string;
  lastName: string;
  phoneNumber: string;
  emailAddress: string;
  gender: string;
  dateOfBirth: Date | null;
  address: string;
  postalCode: string;
  city: string;
  generalInfo: string | null;
  insuranceId: number;
}

// Mutation enums
export const enum mutationStringCustomers {
  setCustomers = "setCustomers",
  SET_CUSTOMER_AMOUNT = "setCustomerAmount",
}
export const enum getterStringCustomers {
  CUSTOMER_AMOUNT = "customerAmount",
}

export const enum actionStringCustomers {
  getCustomers = "getCustomers",
  GET_CUSTOMERS = "getCustomers",
  GET_CUSTOMER = "getCustomer",
  ADD_CUSTOMER = "addCustomer",
  GET_CUSTOMER_AMOUNT = "getCustomerAmount",
  UPDATE_CUSTOMER = "updateCustomer",
}

export const state: customerState = {
  customers: [],
  customerAmount: 0,
  defaultCustomerToBook: null,
};

export const getters: GetterTree<any, customerState> = {
  customers: (state) => state.customers,
  customerAmount: (state) => state.customerAmount,
  defaultCustomerToBook: (state) => state.defaultCustomerToBook,
};

export const mutations: MutationTree<customerState> = {
  setCustomers(state: customerState, payload: Array<ICustomer>) {
    state.customers = payload;
  },
  setCustomerAmount(state: customerState, payload: number) {
    state.customerAmount = payload;
  },
  setDefaultCustomerToBook(state: customerState, payload: ICustomer) {
    state.defaultCustomerToBook = payload;
  },
};

export const actions: ActionTree<customerState, any> = {
  async getCustomers({ commit }): Promise<ICustomer[]> {
    try {
      let customers = await getCustomers();
      commit(mutationStringCustomers.setCustomers, customers);
      //@ts-ignore
      return customers;
    } catch (e) {
      console.error(e);
      throw Error("Could not load customers");
    }
  },

  async getCustomer({ commit }, customerId: string): Promise<ICustomer[]> {
    try {
      let customer = await getCustomer(customerId);
      //@ts-ignore
      return customer;
    } catch (e) {
      throw Error("Could not load customer");
    }
  },
  async getCustomerStatistics(
    { commit },
    customerId: string
  ): Promise<ICustomer[]> {
    try {
      let customer = await getCustomerStatistics(customerId);
      //@ts-ignore
      return customer;
    } catch (e) {
      throw Error("Could not load customer");
    }
  },

  async getCustomerAmount({ commit }) {
    let header: AxiosRequestConfig = await authConfig();

    axios
      .get(APIURL2 + "Customer/current", header)
      .then((res) => {
        commit(mutationStringCustomers.SET_CUSTOMER_AMOUNT, res.data.length);
      })
      .catch((err) => {
        console.log(err);
      });
  },

  async addCustomer(
    { commit, dispatch },
    newCustomer: Partial<ICustomer>
  ): Promise<ICustomer> {
    let header: AxiosRequestConfig = await authConfig();
    return new Promise((resolve, reject) => {
      axios
        .post(APIURL2 + "Customer", newCustomer, header)
        .then((res) => {
          dispatch(actionStringCustomers.getCustomers);
          return resolve(res.data);
        })
        .catch((err) => {
          console.log(err);
          reject(err);
        });
    });
  },

  async deleteCustomer({ dispatch }, customerId: string) {
    let header: AxiosRequestConfig = await authConfig();
    axios
      .delete(APIURL2 + "customer?id=" + customerId, header)
      .then(() => {
        dispatch(actionStringCustomers.getCustomers);
      })
      .catch((err) => {
        console.log(err);
      });
  },
  async updateCustomer({ commit, dispatch }, customer: Partial<ICustomer>) {
    let header: AxiosRequestConfig = await authConfig();
    axios
      .put(APIURL2 + "Customer", customer, header)
      .then(() => {
        dispatch(actionStringCustomers.getCustomers);
        dispatch(
          actionStringSnackbar.postSnackbarMessage,
          i18n.t("customerModalMessages.customerUpdated").toString()
        );
      })
      .catch((err) => {
        console.log(err);
        dispatch(
          actionStringSnackbar.postSnackbarMessage,
          i18n.t("customerModalMessages.customerUpdateFailed").toString()
        );
      });
  },
};

export const customers = {
  actions,
  mutations,
  getters,
  state,
};
