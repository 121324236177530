
import { Component, Vue } from "vue-property-decorator";
import Sidebar from "@/components/global/Sidebar.vue";
import HeaderBar from "@/components/global/Sidebar.vue";
import { Action, Getter, Mutation } from "vuex-class";
import SelectWorktime from "@/components/global/selectWorktime.vue";
import Treatment_types from "@/components/Settings/Services/TreatmentTypes.vue";
import RegisterUser from "@/components/global/onboarding/RegisterUser.vue";
import RegisterCompany from "@/components/global/onboarding/RegisterCompany.vue";

@Component({
  name: "Onboarding",
  components: {
    RegisterCompany,
    RegisterUser,
    Treatment_types,
    SelectWorktime,
    Sidebar,
    HeaderBar,
  },
})
export default class Onboarding extends Vue {
  public defaultColor: string = "#876AFF";
  public el = -1;
  public currentProgress: string;

  @Action public getCurrentUser: Function;
  @Mutation public setUser: Function;
  @Action public getAllWorkingDays: Function;

  validateCompanyInputBeforeNextStep(isValidated) {
    isValidated ? (this.el = 3) : (this.el = 2);
  }

  validateUserInputBeforeNextStep(isValidated) {
    isValidated ? (this.el = 2) : (this.el = 1);
  }

  completeOnboarding(): void {
    this.$router.push("/admin");
  }

  setBodyBacckgroundOnMobile(color: string): void {
    let windowWidth: number = window.innerWidth;
    if (windowWidth < 800) {
      document.body.style.backgroundColor = color;
    }
  }

  //Lifecycle
  public async created(): Promise<any> {
    this.setBodyBacckgroundOnMobile("white");
    this.el = 1;
    let user = await this.getCurrentUser();
    this.getAllWorkingDays();
    this.setUser(user);
  }

  destroyed(): void {
    this.setBodyBacckgroundOnMobile("#ECECF3");
  }
}
