
import { Component, Vue, Prop, Model } from "vue-property-decorator";

@Component({
  name: "base-form-dialog",
})
export default class BaseFormDialog extends Vue {
  //@Model("input", { type: Boolean, default: false }) readonly value!: boolean;
  @Prop({ required: true }) title!: string;
  @Prop({ required: true }) isEditing!: boolean;
  @Prop({ required: true }) isCreating!: boolean;
  @Prop({ default: false }) isSaving!: boolean;
  @Prop({ default: "" }) saveText!: string;
  @Prop({ default: "" }) createText!: string;
  @Prop({ default: "" }) cancelText!: string;
  @Prop({ default: 660 }) maxWidth!: number;

  private isValid: boolean = false;

  //   get dialogModel() {
  //     return this.value;
  //   }

  //   set dialogModel(value: boolean) {
  //     this.$emit("input", value);
  //   }

  public validate(): boolean {
    const form = this.$refs.form as any;
    return form ? form.validate() : false;
  }
}
