
import { Component, Vue, Prop } from "vue-property-decorator";
import { Action, Getter } from "vuex-class";
import { actionStringCustomers, ICustomer } from "../../store/customers";
import { IInsurance, InsuranceActions } from "../../store/Insurances";
import {
  ICustomerDTO,
  IUserDTO,
} from "../../types/AutoGenerated/reinforcedTypings";
import BaseFormDialog from "@/components/global/BaseFormDialog.vue";
import CompanyInfoField from "@/components/global/CompanyInfoField.vue";
import Textfield from "@/components/global/Textfield.vue";

@Component({
  name: "CreateCustomer",
  components: { BaseFormDialog, CompanyInfoField, Textfield },
})
export default class CreateCustomer extends Vue {
  @Prop() editingCustomer?: ICustomerDTO;
  @Prop() defaultName?: string;
  @Prop() defaultPhone?: string;
  @Prop() defaultEmail?: string;

  @Action(actionStringCustomers.ADD_CUSTOMER) private addCustomer!: (
    newCustomer: ICustomer
  ) => Promise<ICustomer>;
  @Action private postSnackbarMessage!: Function;
  @Action private updateCustomer!: (payload: Partial<ICustomer>) => void;
  @Action(InsuranceActions.GET) private getInsurances!: () => Promise<
    Array<IInsurance>
  >;

  @Getter private insurances!: IInsurance[];
  @Getter private user!: IUserDTO;

  private isSaving = false;
  private customerData: Partial<ICustomerDTO> = {
    firstName: "",
    lastName: "",
    phoneNumber: "",
    emailAddress: "",
    address: "",
    postalCode: "",
    city: "",
    insuranceId: 0,
    sendSmsReminder: false,
    sendEmailReminder: false,
  };

  // Validation rules
  private nameRules = [];

  created() {
    this.nameRules = [
      (v: string) => !!v || this.$t("validation.nameRequired").toString(),
    ];

    if (this.editingCustomer) {
      this.customerData = { ...this.editingCustomer };
    }
    this.getInsurances();

    // Apply defaults
    if (this.defaultName) this.customerData.firstName = this.defaultName;
    if (this.defaultPhone) this.customerData.phoneNumber = this.defaultPhone;
    if (this.defaultEmail) this.customerData.emailAddress = this.defaultEmail;
  }

  private validateField(fieldName: string) {
    const baseForm = this.$refs.baseForm as any;
    if (baseForm) {
      baseForm.validate();
    }
  }

  private async onSave() {
    const baseForm = this.$refs.baseForm as any;
    if (baseForm && baseForm.validate()) {
      this.isSaving = true;
      await this.updateCustomer(this.customerData as ICustomer);
      this.postSnackbarMessage(this.$t("customerUpdated").toString());
      this.$emit("created");
    }
  }

  private async onCreate() {
    const baseForm = this.$refs.baseForm as any;
    if (baseForm && baseForm.validate()) {
      this.isSaving = true;
      await this.addCustomer(this.customerData as ICustomer);
      this.postSnackbarMessage(this.$t("customerCreated").toString());
      this.$emit("created");
    }
  }

  private onCancel() {
    this.$emit("close");
  }
}
