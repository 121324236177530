
import Vue from "vue";
import Component from "vue-class-component";
import { Getter, Action } from "vuex-class";
import RegisterFirebaseAccount from "../RegisterFirebaseAccount.vue";
import PersonalAccountSettings from "./PersonalAccountSettings.vue";
import { Watch } from "vue-property-decorator";

@Component({
  name: "AccountSettings",
  components: { PersonalAccountSettings, RegisterFirebaseAccount },
})
export default class AccountSettings extends Vue {
  @Action public addToDialogQueue!: Function;

  @Getter firebaseUser: any;
  @Getter public firebaseUsers: any[];
  @Action public getFirebaseUsersAsAdmin!: Function;
  @Action public addFirebaseUser!: Function;
  @Action public patchFirebaseUser!: Function;

  public addingNewAccount: boolean = false;
  public newAccount: any = {};

  public roles = [
    { name: "Bruker", id: 1 },
    { name: "Administrator", id: 0 },
  ];
  private isEditing: boolean = false;

  @Watch("addingNewAccount")
  onIsEditingNewAccount(newValue: boolean) {
    if (!newValue) this.isEditing = false;
  }

  initNewAccount() {
    this.newAccount = {
      email: "",
      role: 1,
      password: "",
      password2: "",
      name: "",
    };
  }

  created() {
    this.getFirebaseUsersAsAdmin();
    this.initNewAccount();
  }

  async addNewAccount() {
    await this.addFirebaseUser(this.newAccount);
    this.addingNewAccount = false;
  }

  async saveAccountChanges(): Promise<void> {
    try {
      const update = {
        name: this.newAccount.name,
        companyRole: this.newAccount.role,
      };

      const payload = {
        firebaseUserId: this.newAccount.userId,
        patch: update,
      };

      await this.patchFirebaseUser(payload);
    } catch (error) {
      console.error(error);
    }
  }

  getRoleDisplayName(role: number): string {
    if (role === 0) {
      return "Administrator";
    }
    if (role === 1) {
      return "Bruker";
    }
    return "";
  }

  openNewAccountDialog() {
    this.initNewAccount();
    this.addingNewAccount = true;
  }

  editUser(account: any) {
    this.isEditing = true;
    this.addingNewAccount = true;
    this.newAccount = { ...account };
  }
}
