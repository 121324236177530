
import { Component, Prop, Vue, Watch } from "vue-property-decorator";
import { Action, Getter, Mutation } from "vuex-class";
import DatePicker from "@/components/global/DatePicker.vue";
import CustomButton from "@/components/global/styledComponents/customButton.vue";
import CreateCustomer from "./CreateCustomer.vue";
import {
  allHoursDividedByQuartersWithinOpeningTimes,
  isValidDate,
} from "@/Utilities/dateUtility";
import { IDialog } from "@/store/dialog";
import { CalendarInterval } from "@/store/workingHours";
import {
  IAppointmentTypeDTO,
  IPostAppointmentDTO,
  IStaffMemberDTO,
  IUserDTO,
} from "@/types/AutoGenerated/reinforcedTypings";
import { formatDate, FormatSpaceType } from "@/types/formatDateHelper";
import { ICustomer } from "@/store/customers";

@Component({
  name: "create-appointment",
  components: { CreateCustomer, CustomButton, DatePicker },
})
export default class CreateAppointment extends Vue {
  //Fields
  public searchQuery: string = "";
  public showCustomerList: boolean = false;
  public currentCustomerChoice: string = "";
  public selectedServiceId: number = 0;
  public selectedServiceIds: number[] = [];

  public selectedCustomerId: number = 0;
  public isShowingNewCustomerForm: boolean = false;
  public newAppointment: Partial<IPostAppointmentDTO>;
  public hasValidatedCustomer: boolean = false;

  @Action updateAppointment: Function;

  //Vuex
  @Action public getCustomers: Function;
  @Action public addToDialogQueue: Function;
  @Action public deleteCustomer: Function;
  @Action public getAppointmentTypes: Function;
  @Action public bookAppointment: Function;
  @Action public postSnackbarMessage: Function;
  @Getter public customers: Array<ICustomer>;
  @Getter public user: IUserDTO;
  @Getter public appointmentType: Array<Partial<IAppointmentTypeDTO>>;
  // @Getter public selectedDate:selectedDate;
  @Getter public selectHour: string;

  @Getter public defaultCustomerToBook: number;
  @Mutation public setDefaultCustomerToBook: Function;
  @Action public selectDateToBook: Function;
  @Action public selectHourToBook: Function;

  @Prop({}) defaultSelectedServiceIds: number[];
  @Prop({}) defaultSelectedCustomerId: number;
  @Prop({}) defaultOverrideCustomerName: string;
  @Prop({}) defaultOverrideDuration: number;
  @Prop({}) defaultCustomerCreateEmail: string;
  @Prop({}) defaultCustomerCreatePhone: string;
  @Prop({}) defaultOverridePrice: number;
  @Prop({}) defaultOverrideStaffMember: number;

  @Prop({}) open: boolean;

  @Prop({}) shoulScroll: boolean;
  @Prop({ default: false }) edit: boolean;
  @Prop({ default: 0 }) editingAppointmentId: number;
  @Getter public bookingSettingsIntervals;
  @Action getBookingSettingsIntervals: Function;

  @Action public getStaffMembers: Function;
  @Getter public staffMembers: IStaffMemberDTO[];

  public canSubmitNewAppointment = true;
  public menu = false;
  public serviceOptions = [];
  private newCustomerDefaultName: string = "";

  private overrideDuration: number = -1;
  private overridePrice: number = -1;
  private overrideCustomerName: string = "";
  private overrideStaffMember: number = -1;

  public allHoursValid: boolean = false;
  public appointmentTypeItems = [];

  public fromTimeToSelect: string = "";
  public eventDescription = "";

  public workingHourRule = [
    (v) => {
      let pattern = /^(0[0-9]|1[0-9]|2[0-3]):[0-5][0-9]$/;
      return (v) =>
        (v && pattern.test(v)) || this.$t("invalidTimeFormat").toString();
    },
  ];
  private failedLastEndTimeValidation: boolean = false;
  private failedLastStartTimeValidation: boolean = false;

  private sendSmsReminder: boolean = false;
  private sendEmailReminder: boolean = false;

  public saving: boolean = false;

  @Watch("open")
  openHandler(newValue) {
    if (newValue == false) {
      //The dialog has closed the dialog for adding appointment - reset values
      this.overrideDuration = -1; //Duration
      this.overridePrice = -1; //Duration
      this.selectedCustomerId = 0; //Customer
      this.selectedServiceId = 0; //Service
      this.selectedServiceIds = []; //Service
      this.overrideStaffMember = -1; //Staff member
      this.selectHourToBook(null); //Hour
      this.selectDateToBook(null); //Date
      this.overrideCustomerName = "";
      let toForm: any = this.$refs.toform;
      let fromForm: any = this.$refs.fromform;
      if (toForm != null) toForm.resetValidation();
      if (fromForm != null) fromForm.resetValidation();
    } else {
      this.fillInDefaultsFromProps();
      this.insertCustomEventServiceInServiceList();
    }
  }

  get useMultipleServices() {
    return this.user.multipleServices;
  }
  public created(): void {
    this.fillInDefaultsFromProps();
    this.insertCustomEventServiceInServiceList();
    let toForm: any = this.$refs.toform;
    let fromForm: any = this.$refs.fromform;
    if (toForm != null) toForm.resetValidation();
    if (fromForm != null) fromForm.resetValidation();
  }
  async mounted() {
    if (this.staffMembers == null || this.staffMembers.length === 0) {
      await this.getStaffMembers();
    }
    await this.getBookingSettingsIntervals();

    if (
      this.defaultCustomerToBook != null &&
      this.defaultCustomerToBook !== 0
    ) {
      this.selectCustomerForAppointment(this.defaultCustomerToBook);
    }
  }

  destroyed() {
    this.setDefaultCustomerToBook(null);
    this.selectCustomerForAppointment(null);
  }

  //Methods
  public filterCustomers(): void {}

  get hasSelectedService() {
    return this.selectedServiceId !== 0 || this.selectedServiceIds.length > 0;
  }

  validateSelectedCustomerFromCurrentCustomers(): boolean {
    let selectHourSplit = this.selectHour.split(":");
    let hour: number = parseInt(selectHourSplit[0]) as number;
    let min: number = parseInt(selectHourSplit[1]) as number;

    if (isNaN(hour) || isNaN(min) || this.selectHour.length !== 5) {
      this.postSnackbarMessage(this.$t("invalidTimeFormat").toString());
      return false;
    }
    if (this.selectedServiceId != -1) {
      if (this.selectedCustomerName == "") {
        this.postSnackbarMessage(this.$t("enterCustomerName").toString());
        return false;
      }
      if (!this.hasSelectedService) {
        this.postSnackbarMessage(this.$t("selectService").toString());
        return false;
      }
    }

    return true;
  }

  sum(total, num) {
    return total + num;
  }

  get originalServicePrice() {
    let services: IAppointmentTypeDTO[] = this.getSelectedServices();

    if (services.length == 0) {
      return "";
    }
    let prices = services.map((x) => x.price);

    return prices.reduce(this.sum);
  }

  get selectedServicePrice(): number {
    let services: IAppointmentTypeDTO[] = this.getSelectedServices();

    if (services.length == 0) {
      return -1;
    }
    if (this.overridePrice != -1) {
      return this.overridePrice;
    } else {
      let prices = services.map((x) => {
        if (x != null) {
          if (x.discountPrice === -1 || x.discountPrice == null) {
            return x.price;
          } else {
            return x.discountPrice;
          }
        }
      });

      return prices.reduce(this.sum);
    }
  }

  get selectedServiceDuration() {
    let services: IAppointmentTypeDTO[] = this.getSelectedServices();

    if (services.length == 0) {
      return -1;
    }

    let durations = services.map((x) => (x ? x.durationInMinutes : 0));

    let totalDuration = durations.reduce(this.sum);
    return totalDuration;
  }

  get selectHourEndTime() {
    let services: IAppointmentTypeDTO[] = this.getSelectedServices();

    if (services.length == 0) {
      return "";
    }

    if (this.selectHour == null || this.selectHour === "") {
      return [];
    }

    let dur = this.selectedServiceDuration;

    if (this.overrideDuration != -1) {
      dur = this.overrideDuration;
    }

    let date = new Date(
      this.getDateOfSelectedDateAndSelectedHour().getTime() + dur * 60000
    );

    let hourFormat: string = date.getHours().toString() as string;
    if (date.getHours() < 10) hourFormat = "0" + hourFormat;

    let minuteFormat: string = date.getMinutes().toString() as string;
    if (date.getMinutes() < 10) minuteFormat = "0" + minuteFormat;

    return hourFormat + ":" + minuteFormat;
  }

  onCustomerNameChange(event) {
    this.overrideCustomerName = event;
  }

  onCustomerChange(event) {
    let select: any = this.$refs.customeraccountselect;
    select.blur();
    if (event != null && event != 0 && event != -1) {
      this.overrideCustomerName = "";
    } else {
      this.selectedCustomerId = 0;
      this.overrideCustomerName = "";
    }
  }

  get selectedCustomerName() {
    if (this.overrideCustomerName != "") {
      return this.overrideCustomerName;
    }
    if (this.selectedCustomerId != null) {
      let customer = this.customers.find(
        (x) => x.id == this.selectedCustomerId
      );
      if (customer == null) {
        return "";
      } else {
        return customer.firstName;
      }
    }
  }

  hasNotSelectedService() {
    return this.selectedServiceId != 0 && this.selectedServiceIds.length == 0;
  }

  getSelectedServices(): IAppointmentTypeDTO[] {
    if (typeof this.appointmentType.find != "function") {
      return null;
    }

    let array = [];

    if (this.hasNotSelectedService()) {
      let service: IAppointmentTypeDTO = this.appointmentTypeItems.find(
        (x) => x.id == this.selectedServiceId
      );
      if (service == null) {
        return [];
      }
      array.push(service);
      return array;
    } else {
      let services: IAppointmentTypeDTO[] = this.selectedServiceIds.map(
        (id) => {
          return this.appointmentTypeItems.find((x) => x.id == id);
        }
      );

      return services;
    }
  }

  public selectCustomerForAppointment(customer: number): void {
    if (customer != null && customer !== 0) {
      // this.searchQuery = customer.firstName;
      // this.currentCustomerChoice = customer.firstName;
      this.selectedCustomerId = customer;
    } else {
      this.searchQuery = null;
      this.currentCustomerChoice = null;
      this.selectedCustomerId = 0;
    }

    this.showCustomerList = false;
  }

  get selectedDateISO() {
    let chars = "2020-06-07T07:30:00";

    if (this.selectedDate == null) {
      return "";
    }
    if (typeof this.selectedDate.toISOString == "function") {
      return this.selectedDate.toISOString().substr(0, chars.length);
    } else {
      let x = new Date(
        this.selectedDate.year,
        this.selectedDate.month,
        this.selectedDate.day,
        5,
        5
      );
      return x.toISOString().substr(0, chars.length);
    }
  }

  set selectedDateISO(value) {
    if (value == "") {
      return;
    }
    let date = new Date(value);
    if (isValidDate(date)) {
      this.selectedDate = date;
    } else {
      console.warn("Selected not valid date: ", date);
    }
  }

  get selectedDate() {
    //Must do this due to get/set ... maybe debug later
    return this.$store.getters.selectedDate;
  }

  set selectedDate(value) {
    if (value == null) {
      return;
    }
    let selectedDateToGetAppointmentsFrom = {
      day: value.getDate(),
      month: value.getMonth(),
      year: value.getFullYear(),
    };

    this.selectDateToBook(selectedDateToGetAppointmentsFrom);
  }

  get prettySelectedDate() {
    if (this.selectedDate == null) {
      return "Velg dato";
    }
    let date = new Date(
      this.selectedDate.year,
      this.selectedDate.month,
      this.selectedDate.day
    );
    return formatDate(date, FormatSpaceType.DOT);
  }

  public setDateToBook(): void {
    let hour: number = parseInt(this.selectHour.substr(0, 2)) as number;
    let minute: number = parseInt(this.selectHour.substr(3, 2)) as number;
    let day: number = parseInt(this.selectedDate.day) as number;
    let month: number = parseInt(this.selectedDate.month + 1) as number;
    let year: number = parseInt(this.selectedDate.year) as number;

    if (isNaN(this.overrideDuration)) {
      let dialog: IDialog = {
        text: this.$t("createAppointmentMessage.invalidTimeFormat").toString(),
      };
      this.addToDialogQueue(dialog);
      return;
    }

    let serviceIds = []; // this.selectedServiceId;
    if (this.selectedServiceId != 0 && this.selectedServiceId != null) {
      serviceIds.push(this.selectedServiceId);
    } else {
      serviceIds = this.selectedServiceIds;
    }

    let newAppointment: Partial<IPostAppointmentDTO> = {
      customerId: this.selectedCustomerId,
      customerName: this.overrideCustomerName,
      serviceIds: serviceIds,
      year: year,
      month: month,
      day: day,
      hour: hour,
      minute: minute,
      duration: this.overrideDuration,
      price: this.overridePrice,
      smsReminder: this.sendSmsReminder,
      emailReminder: this.sendEmailReminder,
      id: 0,
      description: this.eventDescription,
      staffMemberId: this.overrideStaffMember,
    };

    this.newAppointment = newAppointment;

    if (serviceIds.length == 0) {
      this.postSnackbarMessage(
        this.$t("createAppointmentMessage.selectService").toString()
      );
      this.hasValidatedCustomer = false;
      return;
    }
  }

  public async bookNewAppointment(): Promise<void> {
    this.saving = true;
    this.setDateToBook(); //Setting this.appointment
    if (this.validateSelectedCustomerFromCurrentCustomers()) {
      if (
        this.newAppointment.hour < 0 ||
        this.newAppointment.hour > 23 ||
        this.newAppointment.minute < 0 ||
        this.newAppointment.minute > 59
      ) {
        let dialog: IDialog = {
          text: this.$t(
            "createAppointmentMessage.invalidTimeFormat"
          ).toString(),
        };
        this.addToDialogQueue(dialog);
        return;
      }
      try {
        await this.bookAppointment(this.newAppointment);
        this.$emit("added");
      } catch (e) {
        let dialog: IDialog = {
          text: this.$t(
            "createAppointmentMessage.appointmentCreationFailed"
          ).toString(),
        };
        this.addToDialogQueue(dialog);
      } finally {
        this.saving = false;
      }
    }
  }

  public async editAppointment(): Promise<void> {
    this.saving = true;
    this.setDateToBook(); //Setting this.appointment

    let update = this.newAppointment;
    update.id = this.editingAppointmentId;

    await this.updateAppointment(update);

    this.$emit("added");
    this.$emit("confirm");
    this.$emit("close");

    this.saving = false;
  }

  public toggleNewCustomerForm(): void {
    this.isShowingNewCustomerForm = !this.isShowingNewCustomerForm;
  }

  //Computed
  public get filteredCustomers() {
    if (this.customers != null) {
      return this.customers.filter((customer) => {
        if (customer.firstName != null) {
          return customer.firstName
            .toUpperCase()
            .match(this.searchQuery.toUpperCase());
        }
      });
    }
    return null;
  }

  insertCustomEventServiceInServiceList() {
    if (Array.isArray(this.appointmentType)) {
      //Do npt have custom event as an option in edit
      let allServices = this.appointmentType;

      if (!this.edit) {
        let busyService: Partial<IAppointmentTypeDTO> = {
          title: this.$t("customEvent"),
          id: -1,
          price: 0,
          durationInMinutes: 30,
        };
        allServices.unshift(busyService);
      }

      this.appointmentTypeItems = allServices;
    }
  }

  fillInDefaultsFromProps() {
    this.getCustomers();
    this.getAppointmentTypes();
    this.canSubmitNewAppointment = true;
    this.fromTimeToSelect = this.selectHour;
    this.sendEmailReminder = false;
    this.sendSmsReminder = false;

    if (this.defaultSelectedServiceIds != null) {
      if (
        this.defaultSelectedServiceIds.length == 1 &&
        this.user.multipleServices == false
      ) {
        this.selectedServiceId = this.defaultSelectedServiceIds[0];
      } else {
        this.selectedServiceIds = this.defaultSelectedServiceIds;
      }
    }
    if (this.defaultSelectedCustomerId != null) {
      this.selectedCustomerId = this.defaultSelectedCustomerId;
      //FOX
    }
    if (this.defaultOverrideDuration != null) {
      this.overrideDuration = this.defaultOverrideDuration;
    }
    if (this.defaultOverridePrice != null) {
      this.overridePrice = this.defaultOverridePrice;
    }
    if (this.defaultOverrideStaffMember != null) {
      this.overrideStaffMember = this.defaultOverrideStaffMember;
    }

    if (
      this.defaultOverrideCustomerName != null &&
      this.defaultOverrideCustomerName != "" &&
      (this.defaultSelectedCustomerId == null ||
        this.defaultSelectedCustomerId == 0 ||
        this.defaultSelectedCustomerId == -1)
    ) {
      this.overrideCustomerName = this.defaultOverrideCustomerName;
    }
  }

  //Lifecycles

  @Watch("appointmentType")
  onValueChanged() {
    this.serviceOptions = this.getServiceOptions();
  }

  get hoursToChoose() {
    if (this.selectedDate == null) {
      return [];
    }

    const date: Date = this.getDateOfSelectedDate();
    const weekday = date.getDay();

    let interval: any = null;

    if (this.staffMembers.length === 1) {
      interval = this.bookingSettingsIntervals[0];
    } else {
      interval = this.bookingSettingsIntervals.find((x) => {
        return x.staffMemberId === this.overrideStaffMember;
      });
    }

    if (interval == null) interval = this.bookingSettingsIntervals[0];

    let workingHoursForThisDay: CalendarInterval = interval.intervals[weekday];

    console.log("Working hours for this day: ", workingHoursForThisDay);

    return allHoursDividedByQuartersWithinOpeningTimes(
      workingHoursForThisDay.openHour,
      workingHoursForThisDay.openMinute,
      workingHoursForThisDay.closeHour,
      workingHoursForThisDay.closeMinute,
      true
    );
  }

  private getDateOfSelectedDate(): Date {
    return new Date(
      this.selectedDate.year,
      this.selectedDate.month,
      this.selectedDate.day,
      1,
      0
    );
  }
  private getDateOfSelectedDateAndSelectedHour(): Date {
    let selectHourSplit = this.selectHour.split(":");
    //@ts-ignore
    let hour: number = selectHourSplit[0] as number;
    //@ts-ignore
    let min: number = selectHourSplit[1] as number;

    return new Date(
      this.selectedDate.year,
      this.selectedDate.month,
      this.selectedDate.day,
      hour,
      min
    );
  }

  get hoursToChooseForEnd() {
    if (this.selectHour == null || this.selectHour === "") {
      return [];
    }

    const date = this.getDateOfSelectedDateAndSelectedHour();

    let hours = date.getHours();
    let minutes = date.getMinutes();

    const weekday = date.getDay();

    if (this.selectedServiceId == -1) {
      return allHoursDividedByQuartersWithinOpeningTimes(hours, minutes, 24, 0);
    }

    let interval: any = null;

    if (this.staffMembers.length === 1) {
      interval = this.bookingSettingsIntervals[0];
    } else {
      interval = this.bookingSettingsIntervals.find((x) => {
        return x.staffMemberId === this.overrideStaffMember;
      });
    }

    if (interval == null) interval = this.bookingSettingsIntervals[0];

    let workingHoursForThisDay: CalendarInterval = interval.intervals[weekday];

    return allHoursDividedByQuartersWithinOpeningTimes(
      hours,
      minutes,
      workingHoursForThisDay.closeHour,
      workingHoursForThisDay.closeMinute,
      true
    );
  }

  selectNewHourInstead(event) {
    let pattern = /^(0[0-9]|1[0-9]|2[0-3]):[0-5][0-9]$/;
    let isValid = pattern.test(event);
    if (isValid) {
    }

    this.fromTimeToSelect = event;
    if (isValid) {
      this.selectHourToBook(this.fromTimeToSelect);
      this.failedLastStartTimeValidation = false;
    } else {
      this.failedLastStartTimeValidation = true;
    }
  }

  changeOverridePrice(event) {
    this.overridePrice = parseInt(event);
  }

  selectNewHourEndInstead(event: string) {
    let pattern = /^(0[0-9]|1[0-9]|2[0-3]):[0-5][0-9]$/;

    let isValid = pattern.test(event) || event === "24:00"; //Cheap hack
    if (!isValid) {
      this.failedLastEndTimeValidation = true;
      return;
    }
    this.failedLastEndTimeValidation = false;

    if (this.selectHour == null) {
      console.warn(this.$t("editEndTimeWithoutStart").toString());
      return;
    }

    let selectHourSplit = this.selectHour.split(":");
    //@ts-ignore
    let selectedHour: number = selectHourSplit[0] as number;
    //@ts-ignore
    let selectedMin: number = selectHourSplit[1] as number;

    let selectedStartDate = new Date(
      this.selectedDate.year,
      this.selectedDate.month,
      this.selectedDate.day,
      selectedHour,
      selectedMin
    );

    let newEndSplit = event.split(":");
    //@ts-ignore
    let newEndHour: number = newEndSplit[0] as number;
    //@ts-ignore
    let newEndMinute: number = newEndSplit[1] as number;

    let newEndDate = new Date(
      this.selectedDate.year,
      this.selectedDate.month,
      this.selectedDate.day,
      newEndHour,
      newEndMinute
    );

    //@ts-ignore
    this.overrideDuration = (newEndDate - selectedStartDate) / (1000 * 60); //Difference in minutes
  }

  getServiceOptions() {
    return this.appointmentType.map((x) => {
      return `${x.title} | ${x.durationInMinutes}${this.$t(
        "minutesAbbreviation"
      )} | ${x.price} ${this.$t("currency")}`;
    });
  }

  get isValidPrice() {
    return !isNaN(this.selectedServicePrice) && this.selectedServicePrice != -1;
  }

  cancelCreating() {
    this.saving = false;
    this.$emit("close");
    this.postSnackbarMessage(this.$t("creationCancelled").toString());
  }

  customerFilter(item, queryText) {
    const textOne = item.firstName.toLowerCase();

    this.newCustomerDefaultName = queryText;

    return textOne.includes(queryText.toLowerCase());
  }

  selectServicesFilter(item, queryText) {
    const textOne = item.title.toLowerCase();
    return textOne.includes(queryText.toLowerCase());
  }
  selectHourFilter(item, queryText) {
    const textOne = item.toLowerCase();

    return textOne.includes(queryText.toLowerCase());
  }
  onCreatedCustomer(customerId: number) {
    this.overrideCustomerName = "";
    this.selectCustomerForAppointment(customerId);
    this.isShowingNewCustomerForm = false;
  }

  formatTimeForDisplay(time: string): string {
    // You can implement your time formatting logic here based on user settings
    // Example: Convert "14:30" to "2:30 PM" for 12-hour format
    if (!time) return "";

    const [hours, minutes] = time.split(":");
    const hour = parseInt(hours);

    // Example condition - replace with your actual user settings check
    const use24Hour = this.user.timeFormat === "24h";

    if (use24Hour) {
      return time; // Keep 24-hour format
    } else {
      const period = hour >= 12 ? "PM" : "AM";
      const displayHour = hour % 12 || 12;
      return `${displayHour}:${minutes} ${period}`;
    }
  }

  async onChangeStaffMember(e) {
    await this.getBookingSettingsIntervals({ staffMemberId: e });
  }
  onChangeServiceMultiple(e) {
    this.overrideDuration = -1;
    this.overridePrice = -1;
    if (e.includes(-1)) {
      this.selectedServiceId = -1; //TODO: Boolean to set "Show prices... n shit"
      //@ts-ignore
      this.$refs.multipleselect.blur();
      let lastELement = e[e.length - 1];
      this.selectedServiceIds = [lastELement];
    } else {
      this.selectedServiceId = null;
    }
  }

  onChangeService(e) {
    this.overrideDuration = -1;
    this.overridePrice = -1;

    let singleSelect: any = this.$refs.singleselect;
    singleSelect.blur();
  }

  get hasSelectedValidCustomerAccount() {
    //@ts-ignore
    return !(
      this.selectedCustomerId == null ||
      this.selectedCustomerId == "" ||
      this.selectedCustomerId === 0 ||
      this.selectedCustomerId === -1
    );
  }
  get disableConfirm() {
    let validPrice = this.isValidPrice;
    let invalidTime =
      this.failedLastStartTimeValidation ||
      this.failedLastEndTimeValidation ||
      this.selectHour == null ||
      this.selectHour === "";
    let invalidCustomer = this.selectedCustomerName === "";
    let hasSelectedService = this.hasSelectedService;

    if (this.selectedServiceId == -1) {
      //Custom event
      return invalidTime;
    } else {
      //Service
      return (
        invalidTime || !validPrice || invalidCustomer || !hasSelectedService
      );
    }
  }

  get hasMultipleStaff() {
    return this.staffMembers.length > 1;
  }
}
