
import Vue from "vue";
import Component from "vue-class-component";
import { logout } from "../../Utilities/authConfig";
import { State, Getter, Action, Mutation, namespace } from "vuex-class";
import { DialogMutations, DialogType, IDialog } from "../../store/dialog";
import CompanyInfoField from "../global/CompanyInfoField.vue";
import { IUserDTO } from "@/types/AutoGenerated/reinforcedTypings";
import TextField from "../global/Textfield.vue";
@Component({
  name: "SystemSettings",
  components: {
    CompanyInfoField,
    TextField,
  },
})
export default class SystemSettings extends Vue {
  @Getter public user!: IUserDTO;
  @Action public addToDialogQueue!: Function;
  @Action public patchUser!: Function;

  languages = [
    { key: "en", name: "English", flagEmojiCode: "&#127464;&#127466;" },
    { key: "no", name: "Norsk", flagEmojiCode: "&#127475;&#127476;" },
    { key: "es", name: "Español", flagEmojiCode: "&#127462;&#127463;" },
  ];

  timeFormats = ["12h", "24h"];

  editUser: Partial<IUserDTO> = {
    language: "",
    timeFormat: "",
    timeZone: "",
  };

  mounted() {
    this.editUser.language = this.user.language;
    this.editUser.timeFormat = this.user.timeFormat;
    this.editUser.timeZone = this.user.timeZone;
    this.editUser.defaultPhoneNumberCountryCode =
      this.user.defaultPhoneNumberCountryCode;
  }

  async onLanguageChange(value: string): Promise<void> {
    try {
      await this.patchUser({ language: value });

      if (
        value != null &&
        value != "" &&
        value != localStorage.getItem("locale")
      ) {
        this.$i18n.locale = value;
        localStorage.setItem("locale", value);
      }
    } catch (error) {
      console.error(error);
    }
  }

  async onTimeFormatChange(value: string): Promise<void> {
    await this.patchUser({ timeFormat: value });
  }

  async onTimeZoneChange(value: string): Promise<void> {
    await this.patchUser({ timeZone: value });
  }

  async saveField(field: string, value: string | number): Promise<void> {
    try {
      await this.patchUser({ [field]: value });
    } catch (error) {
      console.error(error.message);
    }
  }
}
