
import { Component, Vue, Prop, Watch } from "vue-property-decorator";
import { Action, Getter } from "vuex-class";
import { actionStringUser, getterStringUser, IUser } from "../../store/user";
import {
  ISubscriptionPlan,
  ISubscriptionStatus,
  Subscription,
} from "../../types/HasSubscription";
import Loader from "../global/Loader.vue";
import Checkout from "@/components/global/Checkout.vue";

@Component({
  name: "subscription-information",
  components: { Checkout, Loader },
})
export default class SubscriptionInformation extends Vue {
  @Action(actionStringUser.GET_USER_SUBSCRIPTION_PLAN)
  getUserSubscriptionPlan: () => Promise<ISubscriptionPlan>;
  @Getter(getterStringUser.SUBSCRIPTION_PLAN)
  subscriptionPlan: ISubscriptionPlan;
  @Getter(getterStringUser.SUBSCRIPTION_STATUS)
  subscriptionStatus: ISubscriptionStatus;

  nextPayment: string | null = null;
  isLoading: boolean = false;
  @Getter user: IUser;

  async created() {
    try {
      this.isLoading = true;
      let plan: ISubscriptionPlan = await this.getUserSubscriptionPlan();

      if (plan) {
        this.nextPayment = plan.nextPayment as string | null;
      } else {
        this.nextPayment = null;
      }
    } catch (e) {
      this.isLoading = false;
    }
    this.isLoading = false;
  }

  get hasCanceledSubscriptionPlan(): boolean {
    return this.subscriptionStatus.status === Subscription.CancelNextPayment;
  }
}
