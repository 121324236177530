
import { Component, Prop, Vue } from "vue-property-decorator";
import { Getter, Mutation, Action } from "vuex-class";
import FrontPageHeader from "../../components/global/FrontPageHeader.vue";
import router from "@/router";
import App from "@/App.vue";

import CustomInput from "../../components/global/styledComponents/CustomInput.vue";
import CustomButton from "@/components/global/styledComponents/customButton.vue";
import { IDialog, DialogType, DialogMutations } from "@/store/dialog";

import firebase from "firebase/app";

const firebaseAuth = firebase.auth();
import { IUserDTO } from "@/types/AutoGenerated/reinforcedTypings";

@Component({
  name: "Login",
  components: { CustomButton, FrontPageHeader, App, CustomInput },
})
export default class Login extends Vue {
  @Action public registerUser: Function;
  @Action public postSnackbarMessage: (message: string) => void;
  @Mutation public addToDialogQueue: (dialog: IDialog) => void;

  public isShowingForgotPasswordField: boolean = false;
  public error: string = "";
  public password: string = "";
  public password2: string = "";
  public email: string = "";
  public isLoading: boolean = false;
  public isShowingRegisterUserForm: boolean = false;

  //FIREBASE REGISTER USER
  public showRegisterForm(): void {
    this.isShowingRegisterUserForm = !this.isShowingRegisterUserForm;
  }

  public async register(): Promise<void> {
    if (this.email === "" || this.password == "") {
      this.postSnackbarMessage(this.$t("login.fillEmailPassword").toString());
      return;
    }

    if (this.password !== this.password2) {
      this.postSnackbarMessage(this.$t("login.passwordsMustMatch").toString());
      return;
    }

    try {
      this.$store.commit("setIsNewUser", true);

      await firebaseAuth.createUserWithEmailAndPassword(
        this.email,
        this.password
      );
    } catch (error) {
      let errorCode = error.code;
      if (errorCode === "auth/email-already-in-use") {
        this.postSnackbarMessage(this.$t("login.emailInUse").toString());
      }
      if (errorCode === "auth/invalid-email") {
        this.postSnackbarMessage(this.$t("login.invalidEmail").toString());
      }
      if (errorCode === "auth/weak-password") {
        this.postSnackbarMessage(this.$t("login.weakPassword").toString());
      }
    }
  }

  //FIREBASE LOGIN USER
  public login(): void {
    this.isLoading = true;

    firebaseAuth
      .signInWithEmailAndPassword(this.email, this.password)
      .then((res) => {
        this.isLoading = false;

        if (res.additionalUserInfo.isNewUser) {
          router.push("/onboarding");
        } else {
          router.push("/admin");
        }
      })
      .catch((error) => {
        this.isLoading = false;
        let errorCode = error.code;
        let errorMessage = error.message;
        if (errorCode === "auth/user-not-found") {
          this.postSnackbarMessage(this.$t("login.userNotFound").toString());
          this.isLoading = false;
        }
        if (errorCode === "auth/wrong-password") {
          this.postSnackbarMessage(
            this.$t("login.wrongEmailPassword").toString()
          );
          this.isLoading = false;
        }
        if (errorCode === "auth/invalid-email") {
          this.postSnackbarMessage(this.$t("login.invalidEmail").toString());
          this.isLoading = false;
        }
      });
  }

  public googleLogin(): void {
    const provider = new firebase.auth.GoogleAuthProvider();
    provider.addScope("https://www.googleapis.com/auth/contacts.readonly");
    firebaseAuth
      .signInWithPopup(provider)
      .then((result) => {
        let user: any = result.additionalUserInfo;

        if (result.additionalUserInfo.isNewUser) {
          let newUser: Partial<IUserDTO> = {
            firstName: user.profile.name,
            lastName: user.profile.family_name,
            phoneNumber: "",
            emailAddress: user.profile.email,
            profilePictureUrl: user.profile.picture,
            appointmentDistributionInMinutes: 60,
            // @ts-ignore
            language: this.$i18n.locale,
            // @ts-ignore
            timeZone: Intl.DateTimeFormat().resolvedOptions().timeZone,
          };
          this.registerUser(newUser).then((x: IUserDTO) => {
            router.push("/onboarding");
          });
        }
        // ...
      })
      .catch(function (error) {
        // Handle Errors here.
        let errorCode = error.code;
        let errorMessage = error.message;
        // The email of the user's account used.
        let email = error.email;
        // The firebase.auth.AuthCredential type that was used.
        let credential = error.credential;
        // ...
      });
  }

  public goToLandingPage(): void {
    router.replace("/");
  }

  public forgot(): void {
    if (this.email == "") {
      let dialog: IDialog = {
        text: this.$t("login.fillEmailForReset").toString(),
      };
      this.addToDialogQueue(dialog);
    } else {
      let dialog: IDialog = {
        type: DialogType.Choice,
        text: this.$t("login.sendNewPasswordTo", {
          email: this.email,
        }).toString(),
        action: this.forgotYes,
      };
      this.addToDialogQueue(dialog);
    }
  }

  goToLogin(): void {
    this.isShowingRegisterUserForm = false;
  }

  forgotYes() {
    let vm = this;
    firebaseAuth
      .sendPasswordResetEmail(this.email)
      .then(function () {
        vm.addToDialogQueue({ text: vm.$t("login.emailSent").toString() });
      })
      .catch(function (error) {
        vm.$store.commit(DialogMutations.AddToDialogQueue);
        vm.addToDialogQueue({ text: error.message });
      });
  }

  public created() {
    let route: string = this.$route.params.register;
    if (route === "login") {
      this.isShowingRegisterUserForm = true;
    }
  }
}
