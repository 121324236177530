
import { Component, Prop, Vue, Watch } from "vue-property-decorator";
@Component({
  name: "company-info-field",
})
export default class CompanyInfoField extends Vue {
  @Prop() public currentAdminInfo: Partial<any>;
  @Prop() public label: string;
  @Prop() public description: string;
  @Prop({ default: false }) public isSwitch: boolean;
  @Prop({ default: false }) public full: boolean;
}
