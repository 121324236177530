import { i18n } from "@/main";

export const createSettingsData = () => ({
  general: [
    {
      id: 0,
      label: i18n.t("settings.companyInfo"),
      icon: "mdi-account-details",
      route: "/settings/company-info",
      disabled: false,
    },
    {
      id: 5,
      label: i18n.t("settings.subscription"),
      icon: "mdi-credit-card-outline",
      route: "/settings/subscription",
      disabled: false,
    },
    {
      id: 6,
      label: i18n.t("settings.accounts"),
      icon: "mdi-account-outline",
      route: "/settings/accounts",
      disabled: false,
    },
    {
      id: 204311,
      label: i18n.t("settings.system"),
      icon: "mdi-web",
      route: "/settings/system",
      disabled: false,
    },
    {
      id: 948328402,
      label: i18n.t("settings.auditLog"),
      icon: "mdi-format-list-bulleted-square",
      route: "/settings/audit-log",
      disabled: false,
    },
  ],
  services: [
    {
      id: 3,
      label: i18n.t("settings.services"),
      icon: "mdi-star-circle",
      route: "/settings/services",
      disabled: false,
    },
    {
      id: 20,
      label: i18n.t("settings.extraServices"),
      icon: "mdi-star-plus",
      route: "/settings/extra-services",
      disabled: true,
    },
    {
      id: 4,
      label: i18n.t("settings.categories"),
      icon: "mdi-shape",
      route: "/settings/categories",
      disabled: false,
    },
    {
      id: 43243,
      label: i18n.t("settings.resources"),
      icon: "mdi-cube-outline",
      route: "/settings/resources",
      disabled: false,
    },
    {
      id: 12,
      label: i18n.t("settings.options"),
      icon: "mdi-cog",
      route: "/settings/options",
      disabled: false,
    },
  ],
  booking: [
    {
      id: 10,
      label: i18n.t("settings.booking"),
      icon: "mdi-calendar",
      route: "/settings/booking",
      disabled: false,
    },
    {
      id: 40,
      label: i18n.t("settings.information"),
      icon: "mdi-information",
      route: "/settings/booking/information",
      disabled: false,
    },
    {
      id: 8,
      label: i18n.t("settings.alerts"),
      icon: "mdi-bell-ring",
      route: "/settings/booking/alerts",
      disabled: false,
    },
    {
      id: 11,
      label: i18n.t("settings.bookingSide"),
      icon: "mdi-link",
      route: "/settings/booking/side",
      disabled: false,
    },
    {
      id: 9,
      label: i18n.t("settings.customerInformation"),
      icon: "mdi-pen",
      route: "/settings/booking/customer-information",
      disabled: false,
    },
    {
      id: 41,
      label: i18n.t("settings.customerAccount"),
      icon: "mdi-account",
      route: "/settings/booking/customer-account",
      disabled: false,
    },
  ],
  calendar: [
    {
      id: 30,
      label: i18n.t("settings.calendar"),
      icon: "mdi-calendar-range",
      route: "/settings/calendar",
      disabled: false,
    },
  ],
  functions: [
    {
      id: 2042,
      label: i18n.t("settings.newsletter"),
      icon: "mdi-email-mark-as-unread",
      route: "/settings/functions/newsletter",
      disabled: false,
    },
    {
      id: 20,
      label: i18n.t("settings.customerAccounts"),
      icon: "mdi-lock",
      route: "/settings/functions/customer-accounts",
      disabled: true,
    },
    {
      id: 21,
      label: i18n.t("settings.invoicesAndPayments"),
      icon: "mdi-lock",
      route: "/settings/functions/invoices-and-payments",
      disabled: true,
    },
    {
      id: 23422,
      label: i18n.t("settings.customFields"),
      icon: "mdi-lock",
      route: "/settings/functions/custom-fields",
      disabled: true,
    },
    {
      id: 23142,
      label: i18n.t("settings.giftCards"),
      icon: "mdi-lock",
      route: "/settings/functions/gift-cards",
      disabled: true,
    },
    {
      id: 2323121,
      label: i18n.t("settings.customerFollowUp"),
      icon: "mdi-lock",
      route: "/settings/functions/customer-follow-up",
      disabled: true,
    },
    {
      id: 231323,
      label: i18n.t("settings.cashRegisterSystem"),
      icon: "mdi-lock",
      route: "/settings/functions/cash-register-system",
      disabled: true,
    },
    {
      id: 23,
      label: i18n.t("settings.multipleLocations"),
      icon: "mdi-lock",
      route: "/settings/functions/multiple-locations",
      disabled: true,
    },
  ],
});
