
import { Component, Prop, Vue } from "vue-property-decorator";
import CustomButton from "@/components/global/styledComponents/customButton.vue";
import { MessageType } from "@/types/messageBarTypes";

@Component({
  name: "Update-message",
  components: { CustomButton },
})
export default class UpdateMessage extends Vue {
  @Prop({ default: MessageType.UPDATE }) messageType: MessageType | undefined;
  @Prop({ default: "text" }) message: string | undefined;

  updateCache(): void {
    localStorage.removeItem("hasUpdate");
    navigator.serviceWorker.ready.then((res) => {
      res.waiting.postMessage({ action: "skipWaiting" });
    });
    window.location.reload();
  }

  purchaseSubscription(): void {
    this.$router.push("/settings/5");
  }
}
