
import { Component, Vue } from "vue-property-decorator";
import { Action, Getter } from "vuex-class";
import { ICustomer } from "../../store/customers";
import { ICustomerJournals } from "../../store/customerJournals";
import { DialogType, IDialog } from "../../store/dialog";
import { IUser } from "../../store/user";
import { Sector } from "../../types/Sector";
import CustomerTable from "./customers/CustomerTable.vue";
import CustomerSearchBar from "./customers/CustomerSearchBar.vue";
import CustomerActions from "./customers/CustomerActions.vue";
import CustomerList from "./customers/CustomerList.vue";
import EditCustomer from "./EditCustomer.vue";
import CustomerModalWindow from "./CustomerModalWindow.vue";
import CustomerModal from "./CustomerModal.vue";
import CreateCustomer from "./CreateCustomer.vue";
import Loader from "./Loader.vue";

@Component({
  name: "AdminCustomers",
  components: {
    CreateCustomer,
    CustomerModalWindow,
    EditCustomer,
    CustomerModal,
    Loader,
    CustomerTable,
    CustomerSearchBar,
    CustomerActions,
    CustomerList,
  },
})
export default class AdminCustomers extends Vue {
  public isShowingCustomerForm: boolean = false;
  public searchQuery: string = "";
  public sector: Sector | null = null;
  public editingCustomer = null;
  public viewingCustomer = null;
  selectedCustomers: Partial<ICustomer>[] = [];
  search: string = "";
  isLoading: boolean = true;
  displayChoice: boolean = false;

  //Vuex
  @Action public getCustomers: Function;
  @Action public getCurrentUser: () => Promise<IUser>;
  @Action public deleteCustomer: Function;
  @Action public postSnackbarMessage: Function;
  @Action public addToDialogQueue: Function;

  @Getter public customerJournal: ICustomerJournals[];
  @Getter public user;
  @Getter public customers;

  isMobile() {
    return /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
      navigator.userAgent
    );
  }

  public toggleCustomerForm(): void {
    this.isShowingCustomerForm = !this.isShowingCustomerForm;
  }

  public filterCustomers(): void {}

  deletedCustomer(customerId: string) {
    this.deleteCustomer(customerId);
  }

  editCustomer(customer: Partial<ICustomer>) {
    this.editingCustomer = customer;
  }

  get hasSelectedCustomers() {
    return this.selectedCustomers.length === 0;
  }

  get filteredCustomers() {
    return this.customers.filter((customer: Partial<ICustomer>) => {
      if (customer.firstName != null) {
        return customer.firstName
          .toUpperCase()
          .match(this.searchQuery.toUpperCase());
      } else {
        return false;
      }
    });
  }

  deleteSelectedCustomers() {
    let vm = this;
    let dialog: IDialog = {
      text: this.$t("customer.confirmDeleteCustomers", {
        count: vm.selectedCustomers.length,
      }).toString(),
      type: DialogType.Choice,
      header: this.$t("customer.deleteCustomerHeader").toString(),
      action: function () {
        vm.postSnackbarMessage(
          vm
            .$t("customer.customersDeleted", {
              count: vm.selectedCustomers.length,
            })
            .toString()
        );
        vm.selectedCustomers.forEach((x: Partial<ICustomer>) => {
          vm.deleteCustomer(x.id);
        });
        vm.selectedCustomers = [];
      },
    };
    this.addToDialogQueue(dialog);
  }

  onCreatedCustomer() {
    this.isShowingCustomerForm = false;
  }

  public async created(): Promise<any> {
    this.sector = this.user.sector;
    this.isLoading = true;
    await this.getCustomers();
    this.isLoading = false;
  }
}
