
import { Component, Prop, Vue, Watch } from "vue-property-decorator";
import { Getter, Action } from "vuex-class";
import { IStaffMemberDTO } from "@/types/AutoGenerated/reinforcedTypings";

@Component({
  name: "CalendarDesktopHeader",
})
export default class CalendarDesktopHeader extends Vue {
  @Prop({ default: false }) private hideMobileFooter!: boolean;
  @Prop({ required: true }) private monthName!: string;

  @Getter("staffMemberOptions", { namespace: "calendar" })
  staffMemberOptions!: Array<Partial<IStaffMemberDTO>>;

  @Getter("getCalendarType", { namespace: "calendar" })
  getCalendarType!: string;
  @Action("updateCalendarType", { namespace: "calendar" })
  updateCalendarType!: (type: string) => void;

  @Getter("getCurrentStaffMember", { namespace: "calendar" })
  getCurrentStaffMember!: number;
  @Action("updateCurrentStaffMember", { namespace: "calendar" })
  updateCurrentStaffMember!: (staffMember: number) => void;

  @Getter public mini!: boolean;
  @Getter public hasMultipleStaff!: boolean;

  @Getter("getIntervalMinutes", { namespace: "calendar" })
  getIntervalMinutes!: number;
  @Action("updateIntervalMinutes", { namespace: "calendar" })
  updateIntervalMinutes!: (minutes: number) => void;

  @Getter("calendarOptions", { namespace: "calendar" })
  calendarOptions!: Array<any>;

  intervalOptions = [15, 30, 60, 90];

  get intervalMinutes() {
    return this.getIntervalMinutes;
  }

  set intervalMinutes(value: number) {
    this.updateIntervalMinutes(value);
  }

  get currentStaffMember() {
    return this.getCurrentStaffMember;
  }

  set currentStaffMember(value: number) {
    this.updateCurrentStaffMember(value);
  }

  get calendarType() {
    return this.getCalendarType;
  }

  set calendarType(value: string) {
    this.updateCalendarType(value);
  }

  get headerStyle() {
    const width =
      window.innerWidth ||
      document.documentElement.clientWidth ||
      document.body.clientWidth;

    // No header
    if (width < 1263) {
      return {
        top: "0px",
        boxShadow: "0 4px 5px -2px rgba(0,0,0, 0.2)",
        width: "100%",
        marginLeft: "0px",
      };
    } else {
      // Has main header
      if (this.mini) {
        return {
          top: "0",
          boxShadow: "0 4px 5px -2px rgba(0,0,0, 0.2)",
          width: "calc(100% - 80px)",
          marginLeft: "80px",
        };
      } else {
        return {
          top: "0",
          boxShadow: "0 4px 5px -2px rgba(0,0,0, 0.2)",
          width: "calc(100% - 240px)",
          marginLeft: "240px",
        };
      }
    }
  }
}
