
import Vue from "vue";
import Component from "vue-class-component";
import { IUserBookingSettingsDTO } from "@/store/workingHours";
import { Getter, Action } from "vuex-class";
import { Prop, Watch } from "vue-property-decorator";
import { IDialog } from "@/store/dialog";
import {
  IStaffMemberDTO,
  IUserDTO,
} from "@/types/AutoGenerated/reinforcedTypings";
import DayWorkingHours from "./components/DayWorkingHours.vue";
import BreakTimes from "./components/BreakTimes.vue";
import Loader from "@/components/global/Loader.vue";

@Component({
  name: "WorkingHours",
  components: {
    Loader,
    DayWorkingHours,
    BreakTimes,
  },
})
export default class WorkingHours extends Vue {
  @Prop({ default: null }) staffMember: IStaffMemberDTO;

  //Vuex
  @Action public updateWorkingDays: Function;
  @Action public patchUser: Function;
  @Action public getCurrentUser: Function;
  @Action public addToDialogQueue: Function;
  @Action public updateBookingSettings: Function;
  @Action public getBookingSettings: Function;
  @Action public getPauseTimes: Function;
  @Action public postSnackbarMessage: Function;
  @Action public getAllWorkingDays: Function;
  @Action public getBookingSettingsIntervals: Function;
  @Action public deletePauseTime: Function;
  @Action public addPauseTime: Function;
  @Action public getStaffMembers: Function;

  @Getter public allWorkingDays;
  @Getter public pauseTimes;
  @Getter public user: IUserDTO;
  @Getter public staffMembers;

  public bookingSettings: IUserBookingSettingsDTO | null = null;
  public isWorkingToday = false;
  public dayIndex = 0;
  public isEditingHours = false;
  public isLoading: boolean = true;
  public error: string | null = null;
  public localStaffMember: IStaffMemberDTO | null = null;
  public isInitializing: boolean = true;
  public newPauseStart = "";
  public newPauseEnd = "";
  public selectedDay = null;
  public allHoursValid: boolean = true;

  @Watch("staffMember")
  onStaffMemberChange() {
    this.initializeWorkingHours();
  }

  @Watch("bookingSettings", { deep: true })
  async onBookingSettingsChange() {
    if (this.isInitializing) {
      return;
    }

    if (this.bookingSettings && this.allHoursValid) {
      try {
        await this.updateBookingSettings({
          staffMemberId: this.staffMemberId,
          workingHours: this.bookingSettings,
        });

        await Promise.all([
          this.getBookingSettingsIntervals({
            staffMemberId: this.staffMemberId,
          }),
          this.getBookingSettings({ staffMemberId: this.staffMemberId }),
          this.getAllWorkingDays({ staffMemberId: this.staffMemberId }),
        ]);

        this.postSnackbarMessage(this.$t("workingHours.updated").toString());
      } catch (err) {
        this.error = "Failed to save changes";
        console.error("Error saving changes:", err);
      }
    } else if (!this.allHoursValid) {
      //   let dialog: IDialog = {
      //     text: this.$t("workingHours.invalidTimeFormat").toString(),
      //   };
      //   this.addToDialogQueue(dialog);
    }
  }

  get days() {
    return [
      {
        label: this.$t("days.monday").toString(),
        weekDayName: "Monday",
        weekdayIndex: 1,
      },
      {
        label: this.$t("days.tuesday").toString(),
        weekDayName: "Tuesday",
        weekdayIndex: 2,
      },
      {
        label: this.$t("days.wednesday").toString(),
        weekDayName: "Wednesday",
        weekdayIndex: 3,
      },
      {
        label: this.$t("days.thursday").toString(),
        weekDayName: "Thursday",
        weekdayIndex: 4,
      },
      {
        label: this.$t("days.friday").toString(),
        weekDayName: "Friday",
        weekdayIndex: 5,
      },
      {
        label: this.$t("days.saturday").toString(),
        weekDayName: "Saturday",
        weekdayIndex: 6,
      },
      {
        label: this.$t("days.sunday").toString(),
        weekDayName: "Sunday",
        weekdayIndex: 0,
      },
    ];
  }

  get staffMemberId(): number {
    return this.localStaffMember?.id || 0;
  }

  get getDayOpenTime(): string {
    if (!this.selectedDay || !this.bookingSettings) return "";
    const dayMap = {
      0: "sundayOpenFromMidnight",
      1: "mondayOpenFromMidnight",
      2: "tuesdayOpenFromMidnight",
      3: "wednesdayOpenFromMidnight",
      4: "thursdayOpenFromMidnight",
      5: "fridayOpenFromMidnight",
      6: "saturdayOpenFromMidnight",
    };
    return this.bookingSettings[dayMap[this.selectedDay.weekdayIndex]] || "";
  }

  get getDayCloseTime(): string {
    if (!this.selectedDay || !this.bookingSettings) return "";
    const dayMap = {
      0: "sundayCloseFromMidnight",
      1: "mondayCloseFromMidnight",
      2: "tuesdayCloseFromMidnight",
      3: "wednesdayCloseFromMidnight",
      4: "thursdayCloseFromMidnight",
      5: "fridayCloseFromMidnight",
      6: "saturdayCloseFromMidnight",
    };
    return this.bookingSettings[dayMap[this.selectedDay.weekdayIndex]] || "";
  }

  updateDayHours({ openTime, closeTime }) {
    if (!this.selectedDay || !this.bookingSettings) return;

    const openMap = {
      0: "sundayOpenFromMidnight",
      1: "mondayOpenFromMidnight",
      2: "tuesdayOpenFromMidnight",
      3: "wednesdayOpenFromMidnight",
      4: "thursdayOpenFromMidnight",
      5: "fridayOpenFromMidnight",
      6: "saturdayOpenFromMidnight",
    };

    const closeMap = {
      0: "sundayCloseFromMidnight",
      1: "mondayCloseFromMidnight",
      2: "tuesdayCloseFromMidnight",
      3: "wednesdayCloseFromMidnight",
      4: "thursdayCloseFromMidnight",
      5: "fridayCloseFromMidnight",
      6: "saturdayCloseFromMidnight",
    };

    // Create a new object to trigger the watcher
    const newSettings = { ...this.bookingSettings };
    newSettings[openMap[this.selectedDay.weekdayIndex]] = openTime;
    newSettings[closeMap[this.selectedDay.weekdayIndex]] = closeTime;
    this.bookingSettings = newSettings;
  }

  async mounted() {
    try {
      this.isLoading = true;

      if (this.staffMember) {
        this.localStaffMember = this.staffMember;
      } else {
        const staffId = this.$route.params.staffId;
        if (!staffId) {
          throw new Error("No staff member ID provided");
        }

        if (this.staffMembers.length === 0) {
          await this.getStaffMembers();
        }

        this.localStaffMember = this.staffMembers.find(
          (staff) => staff.id == staffId
        );

        if (!this.localStaffMember) {
          throw new Error("Staff member not found");
        }
      }

      await this.initializeWorkingHours();
    } catch (err) {
      this.error = err.message || "Failed to load working hours";
      console.error("Error loading working hours:", err);
    } finally {
      this.isLoading = false;
    }
  }

  async initializeWorkingHours() {
    try {
      this.isLoading = true;
      this.error = null;
      this.isInitializing = true;

      if (!this.localStaffMember) {
        throw new Error("No staff member selected");
      }

      this.bookingSettings = await this.getBookingSettings({
        staffMemberId: this.staffMemberId,
      });

      await Promise.all([
        this.getBookingSettingsIntervals({ staffMemberId: this.staffMemberId }),
        this.getAllWorkingDays({ staffMemberId: this.staffMemberId }),
        this.getPauseTimes({ staffMemberId: this.staffMemberId }),
      ]);

      this.isWorkingToday = this.allWorkingDays[this.days[0].weekdayIndex];
      this.selectDayToPickWorkingHours(this.days[0]); // Choose monday for editing by default
    } catch (err) {
      this.error = err.message || "Failed to initialize working hours";
      console.error("Error initializing working hours:", err);
    } finally {
      this.isLoading = false;
      this.$nextTick(() => {
        this.isInitializing = false;
      });
    }
  }

  public selectDayToPickWorkingHours(selectedDay) {
    this.selectedDay = selectedDay;
    this.dayIndex = this.days.indexOf(selectedDay);
    this.updateWorkingHoursAndWorkingDaysListOnDay(selectedDay);
    this.isWorkingToday = this.allWorkingDays[this.selectedDay.weekdayIndex];
  }

  public booleanToNorwegian(value) {
    return value
      ? this.$t("boolean.yes").toString()
      : this.$t("boolean.no").toString();
  }

  public async toggleWorkingDay(newValue: boolean): Promise<void> {
    try {
      if (!this.selectedDay) return;

      let payload = {
        dayOfWeek: this.selectedDay.weekDayName,
        isWorking: newValue,
        staffMemberId: this.staffMemberId,
      };

      await this.updateWorkingDays(payload);
      this.allWorkingDays[this.dayIndex] = newValue;
    } catch (err) {
      this.error = "Failed to update working day";
      console.error("Error updating working day:", err);
    }
  }

  public async updateWorkingHoursAndWorkingDaysListOnDay(day): Promise<void> {
    if (!day) return;
    this.dayIndex = day.weekDayIndex;
    this.isWorkingToday = this.allWorkingDays[this.dayIndex];
  }

  get pauseTimesOnSelectedWeekDay() {
    if (!this.pauseTimes?.filter || !this.selectedDay) return [];

    return this.pauseTimes.filter(
      (x) => x.dayOfWeek == this.selectedDay.weekdayIndex
    );
  }

  async onDeletePause(id) {
    try {
      await this.deletePauseTime({
        id: id,
        staffMemberId: this.staffMemberId,
      });
    } catch (err) {
      this.error = "Failed to delete pause time";
      console.error("Error deleting pause time:", err);
    }
  }

  async addPause() {
    try {
      if (!this.selectedDay) return;

      const payload = {
        staffId: this.staffMemberId,
        dayOfWeek: this.selectedDay.weekdayIndex,
        start: this.newPauseStart,
        end: this.newPauseEnd,
      };

      await this.addPauseTime(payload);
      this.newPauseStart = "";
      this.newPauseEnd = "";
    } catch (err) {
      this.error = "Failed to add pause time";
      console.error("Error adding pause time:", err);
    }
  }
}
