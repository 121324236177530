
import CalendarClass from "../../../abstract-classes/Calendar";
import { Component, Prop } from "vue-property-decorator";
import { Action, Getter, Mutation } from "vuex-class";
import Loader from "@/components/global/Loader.vue";
import CustomerModal from "@/components/global/CustomerModal.vue";
import { DialogMutations, DialogType, IDialog } from "@/store/dialog";
import SelectCustomer from "@/components/global/CreateAppointment.vue";
import { getMonthName, getWeekDay } from "@/Utilities/dateUtility";
import CustomerModalWindow from "../CustomerModalWindow.vue";
import AppointmentMenu from "../../AppointmentMenu.vue";
import { CalendarInterval, IWorkingDays } from "../../../store/workingHours";
import {
  actionStringAppointments,
  IAppointmentPatch,
} from "../../../store/appointments/appointments";
import { AppointmentInfo } from "../../../store/appointments/appointmentTypings";
import {
  IAppointmentDTO,
  IAppointmentForWeeklyCalender,
  IAuditLogDTO,
  IStaffMemberDTO,
  IUserDTO,
} from "../../../types/AutoGenerated/reinforcedTypings";
import CreateAppointment from "../CreateAppointment.vue";
import AppointmentSummary from "@/components/global/AppointmentSummary.vue";
import { formatDate } from "@/types/formatDateHelper";
import { formatTimeWithFormat } from "@/Utilities/dateUtility";
import CalendarDesktopHeader from "./CalendarDesktopHeader.vue";
import CalendarSettingsDialog from "./CalendarSettingsDialog.vue";
import EventCard from "./EventCard.vue";
import CalendarMobileHeader from "./CalendarMobileHeader.vue";
import EventMenu from "./EventMenu.vue";
import { isMobile, isiPad } from "@/Utilities/deviceUtility";

@Component({
  name: "WeekCalendar",
  components: {
    AppointmentSummary,
    CreateAppointment,
    AppointmentMenu,
    CustomerModalWindow,
    SelectCustomer,
    CustomerModal,
    Loader,
    CalendarDesktopHeader,
    CalendarSettingsDialog,
    EventCard,
    CalendarMobileHeader,
    EventMenu,
  },
})
export default class WeekCalendar extends CalendarClass {
  @Prop({ default: null }) adminId: string | null;

  isLoading: boolean = true;
  isLoadingSettings: boolean = true;

  public calendarScroll = 0;

  @Action public postSnackbarMessage: Function;
  @Action getBookedAppointmentsForWeek: Function;
  @Action getBookedAppointmentsForTwoWeeks: Function;
  @Action getBookedAppointmentsForMonth: Function;
  @Getter user: IUserDTO;
  @Action public getCustomer: Function;
  @Action public selectDateToBook: Function;
  @Action public selectHourToBook: Function;
  @Action public addToDialogQueue: Function;
  @Mutation public setMini: Function;

  @Action postAuditLog: Function;

  @Action public patchAppointment: Function;

  @Getter allWorkingDays: IWorkingDays[];
  @Getter public bookingSettings;
  @Getter public bookingSettingsIntervals;
  @Action getAllWorkingDays: Function;
  @Action getBookingSettings: Function;
  @Action getBookingSettingsIntervals: Function;
  @Action(actionStringAppointments.GET_APPOINTMENT_BY_NUMBER) getAppointment: (
    appointmentNumber: number
  ) => Promise<AppointmentInfo>;

  @Action public getAppointmentsForOverview: Function;
  @Getter public appointmentsForOverview: [];

  @Action public getStaffMembers: Function;
  @Getter public staffMembers: Partial<IStaffMemberDTO>[];
  @Getter public hasMultipleStaff: boolean;
  @Getter public mini: boolean;

  flashInterval = null;
  staffMemberView = false;

  appointmentToMove = null;

  public events: Array<IAppointmentForWeeklyCalender> = [];
  public filteredEvents: Array<IAppointmentForWeeklyCalender> = [];

  @Getter("getCalendarType", { namespace: "calendar" })
  getCalendarType!: string;
  @Action("updateCalendarType", { namespace: "calendar" })
  updateCalendarType!: (type: string) => void;

  @Getter("getCurrentStaffMember", { namespace: "calendar" })
  getCurrentStaffMember!: number;
  @Action("updateCurrentStaffMember", { namespace: "calendar" })
  updateCurrentStaffMember!: (staffMember: number) => void;
  @Action("filterEvents", { namespace: "calendar" })
  filterEvents!: () => void;

  @Getter("staffMemberCategories", { namespace: "calendar" })
  staffMemberCategories!: string[];

  //Show event info? (Clicked on event)
  public bookOpen: boolean = false;

  movingAppointment = null;
  selectedEvent: IAppointmentForWeeklyCalender | null = null;
  selectedElement = null;
  selectedOpen: boolean = false;
  start = null;
  end = null;
  public focus: string = "";

  width = 0;
  height = 0;

  startTime = "";
  public highlightEvent: IAppointmentForWeeklyCalender = null;
  private hasResolvedHighlightedEvent: boolean = false;
  private defaultStaffMemberId: number = null;

  @Getter("getIntervalMinutes", { namespace: "calendar" })
  getIntervalMinutes!: number;

  @Action("updateIntervalMinutes", { namespace: "calendar" })
  updateIntervalMinutes!: (minutes: number) => void;

  get startTimeForCalendar() {
    if (this.startTime == "" || this.startTime == " ") {
      return new Date();
    } else {
      return this.startTime;
    }
  }

  get weekday() {
    // return [0]; //TODO: Maybe 1 day week calendar in Dashboard?

    if (this.user.hideIrrelevantDaysInCalendar == false) {
      return [1, 2, 3, 4, 5, 6, 0];
    } else {
      let arrayToReturn = [];
      let index = 0;
      this.allWorkingDays.forEach((isWorking) => {
        if (isWorking) {
          arrayToReturn.push(index);
        }
        index++;
      });
      if (arrayToReturn.includes(0)) {
        let index = arrayToReturn.indexOf(0);
        arrayToReturn.splice(index, 1);
        arrayToReturn.push(0);
      }
      if (arrayToReturn.length == 0) {
        return [1, 2, 3, 4, 5, 6, 0];
      } else {
        return arrayToReturn;
      }
    }
  }

  get showCalendar() {
    const validEnd =
      this.end != null ||
      this.getCalendarType === "day" ||
      this.getCalendarType === "4day";

    const showCalendar =
      this.isLoading == false &&
      this.isLoadingSettings == false &&
      this.start != null &&
      validEnd &&
      this.hasResolvedHighlightedEvent;
    return showCalendar;
  }

  get monthName() {
    //I might consider a strategy pattern. Instead of having a switch statement, I could have a strategy for each type of calendar.
    //So, a different component for each type of calendar, and then I could just call the correct strategy based on the calendar type.
    if (this.getCalendarType === "day" || this.getCalendarType === "category") {
      if (!this.start) {
        return "";
      }

      const startMonth = this.start;

      const startDay = this.start.day;

      return `${getMonthName(startMonth.month - 1, true)} ${startDay}`;
    } else {
      if (!this.start || !this.end) {
        return "";
      }

      const startMonth = this.start;
      const endMonth = this.end;
      const suffixMonth = startMonth === endMonth ? "" : endMonth;

      const startYear = this.start.year;
      const endYear = this.end.year;
      const suffixYear = startYear === endYear ? "" : endYear;

      const startDay = this.start.day;
      const endDay = this.end.day;

      if (suffixMonth.month != startMonth.month) {
        return `${getMonthName(
          startMonth.month - 1,
          true
        )} ${startDay} - ${getMonthName(
          suffixMonth.month - 1,
          true
        )} ${endDay}`;
      } else {
        return `${getMonthName(
          startMonth.month - 1,
          true
        )} ${startDay} - ${endDay}`;
      }
    }
  }

  get firstInterval() {
    if (this.getIntervalMinutes == 60) {
      return 5;
    }
    if (this.getIntervalMinutes == 90) {
      return 3;
    }
    if (this.getIntervalMinutes == 30) {
      return 11;
    }
    if (this.getIntervalMinutes == 15) {
      return 23;
    }
  }
  get intervalCount() {
    // Aim at ending on 24:00
    if (this.getIntervalMinutes == 60) {
      return "20";
    }
    if (this.getIntervalMinutes == 90) {
      return "14";
    }
    if (this.getIntervalMinutes == 30) {
      return "38";
    }
    if (this.getIntervalMinutes == 15) {
      return "74";
    }
  }

  async calendarChanged({ start, end }) {
    this.start = start;
    this.end = end;
    this.isLoading = true;
    await this.getEvents();
  }

  private searchingForAppointment(): boolean {
    const params = this.$route.params as any;
    let highlightAppointmentId = params.appointmentId;

    return highlightAppointmentId != null;
  }

  get staffMemberId() {
    return this.user.staffMemberId;
  }

  toggleFooter() {
    if (this.height < 500) {
      this.$store.commit("setHideMobileFooter", true);
    } else {
      this.$store.commit("setHideMobileFooter", false);
    }
  }
  getDimensions() {
    this.width = document.documentElement.clientWidth;
    this.height = document.documentElement.clientHeight;

    this.toggleFooter();
  }

  destroyed() {
    window.removeEventListener("resize", this.getDimensions);
  }
  public async mounted(): Promise<void> {
    const vm = this;

    if (isMobile()) {
      this.width = document.documentElement.clientWidth;
      this.height = document.documentElement.clientHeight;
      window.addEventListener("resize", this.getDimensions);
      this.toggleFooter();
    }

    if (this.staffMembers == null || this.staffMembers.length === 0) {
      await this.getStaffMembers();
    }

    if (this.searchingForAppointment()) {
      vm.flashInterval = setInterval((x) => {
        vm.flashHighlightedEvent();
      }, 1000);
    }

    if (
      this.bookingSettings == null ||
      this.allWorkingDays == null ||
      this.bookingSettingsIntervals.length === 0
    ) {
      this.isLoadingSettings = true;
      await this.getBookingSettings(); //TODO: SLows down -- should be done once in dashbaord?
      await this.getBookingSettingsIntervals();
      await this.getAllWorkingDays({ staffMemberId: this.staffMemberId }); //TODO: Slows performance down...
      this.isLoadingSettings = false;
    } else {
      this.isLoadingSettings = false;
    }

    const now = new Date();
    let startTime = {
      day: now.getDate(),
      month: now.getMonth() + 1,
      year: now.getFullYear(),
    };
    this.start = startTime;

    this.start = startTime;

    //TODO: I might consider making this in a "preference-loader" component that's run on first load. so that there's no flickering
    //because right now it renders 30, then 60
    let defaultIntervalMinutes = localStorage.getItem("calendar-zoom");
    if (defaultIntervalMinutes != null) {
      this.updateIntervalMinutes(parseInt(defaultIntervalMinutes));
    }

    let defaultMode = localStorage.getItem("calendarmode");
    if (defaultMode != null) {
      this.updateCalendarType(defaultMode);
    }

    let defaultStaffmemberview = localStorage.getItem("staffmemberview");
    if (defaultStaffmemberview != null) {
      if (defaultStaffmemberview == "true") {
        this.staffMemberView = true;
      }
    }
    let defaultCurrentstaffmember = localStorage.getItem("currentstaffmember");
    if (defaultCurrentstaffmember != null) {
      this.updateCurrentStaffMember(parseInt(defaultCurrentstaffmember));
    }

    if (!this.searchingForAppointment()) {
      this.hasResolvedHighlightedEvent = true;
      await this.getEvents(new Date());
    }
  }

  roundTime(time: string, minutesToRound) {
    let [hours, minutes] = time.split(":");
    let hoursNumber: number = parseInt(hours);
    let minutesNumber: number = parseInt(minutes);

    // Convert hours and minutes to time in minutes
    let newTime = hoursNumber * 60 + minutesNumber;

    let rounded = Math.floor(newTime / minutesToRound) * minutesToRound;
    let rHr = "" + Math.floor(rounded / 60);
    let rMin = "" + (rounded % 60);

    return rHr.padStart(2, "0") + ":" + rMin.padStart(2, "0");
  }

  gotoAppointment(num) {
    this.$store.commit("setAppointmentsSource", "kalender");
    this.$router.push("/bestillinger/" + num);
  }

  get calendarTypeForCalendar() {
    return this.getCalendarType;
  }

  filterEventsOnStaffMember() {
    this.filterEvents();
    this.filteredEvents = this.$store.getters["calendar/getFilteredEvents"];
  }
  async getEvents(providedDate?): Promise<void> {
    if (providedDate == null) {
      if (this.start == null) {
        console.warn("Start date and Provided Date is null in Get Events!");
        return;
      }
      console.log("Start:", this.start);
      let startDate = this.start.date;
      if (this.getCalendarType == "day") {
        const d = new Date(
          this.start.year,
          this.start.month - 1,
          this.start.day,
          0,
          0,
          0,
          0
        );
        startDate = d;
      }
      let date = new Date(startDate);
      if (
        this.getCalendarType === "week" ||
        this.getCalendarType === "day" ||
        this.getCalendarType === "category"
      ) {
        this.events = await this.getBookedAppointmentsForWeek(date);
      } else if (this.getCalendarType === "month") {
        this.events = await this.getBookedAppointmentsForMonth(date);
      } else if (this.getCalendarType === "4day") {
        this.events = await this.getBookedAppointmentsForTwoWeeks(date); //4 day can show next mon/tue , so we load for next week also ... kinda cheap hack
      }
    } else {
      if (
        this.getCalendarType === "week" ||
        this.getCalendarType === "day" ||
        this.getCalendarType === "category"
      ) {
        this.events = await this.getBookedAppointmentsForWeek(providedDate);
      } else if (this.getCalendarType === "month") {
        this.events = await this.getBookedAppointmentsForMonth(providedDate);
      } else if (this.getCalendarType === "4day") {
        this.events = await this.getBookedAppointmentsForTwoWeeks(providedDate); //4 day can show next mon/tue , so we load for next week also ... kinda cheap hack
      }
    }

    if (this.start != null) {
      this.isLoading = false;
    }

    await this.setStartTimeToHighlightedDateAndSetHihglightEvent();

    if (this.getCurrentStaffMember != 0 && this.staffMembers.length > 1) {
      this.filterEventsOnStaffMember();
    } else {
      this.filteredEvents = this.events;
    }

    await this.$store.dispatch("calendar/setEvents", this.events);
    this.filteredEvents = this.$store.getters["calendar/getFilteredEvents"];
  }

  async setStartTimeToHighlightedDateAndSetHihglightEvent() {
    const params = this.$route.params as any;
    let highlightAppointmentId = params.appointmentId;

    if (highlightAppointmentId != null) {
      let appointment = await this.getAppointment(highlightAppointmentId);

      const appointmentDate = new Date(
        appointment.year,
        appointment.month - 1,
        appointment.day,
        appointment.hour,
        appointment.minute,
        0
      );
      this.startTime = appointmentDate.toISOString().substr(0, 10);

      this.highlightEvent = this.events.find(
        (x) => x.appointmentNumber == highlightAppointmentId
      );
      this.hasResolvedHighlightedEvent = true;
    }
  }

  flashHighlightedEvent() {
    if (this.highlightEvent != null) {
      if (this.highlightEvent.color == "green") {
        this.highlightEvent.color = "#57c95b";
      } else {
        this.highlightEvent.color = "green";
      }
    }
  }

  async confirmMove(date, time, appointmentId) {
    let selectHourSplit = time.split(":");
    let hour: number = parseInt(selectHourSplit[0]) as number;
    let min: number = parseInt(selectHourSplit[1]) as number;

    let newDate = new Date(date);
    let fixedDate = {
      day: newDate.getDate(),
      month: newDate.getMonth() + 1,
      year: newDate.getFullYear(),
    };

    let update: Partial<IAppointmentDTO> = {
      year: fixedDate.year,
      month: fixedDate.month,
      day: fixedDate.day,
      hour: hour,
      minute: min,
    };

    const payload: IAppointmentPatch = {
      appointmentId: appointmentId,
      patch: update,
    };

    await this.patchAppointment(payload);

    await this.getEvents();
  }

  changeCategory({ category }) {
    let defaultStaff = this.staffMembers.find((x) => {
      return (x.firstName + " " + x.lastName).trim() == category.trim();
    });

    if (defaultStaff != null) this.defaultStaffMemberId = defaultStaff.id;
  }
  clickTime({ date, time }) {
    let vm = this;

    if (this.appointmentToMove != null) {
      let id = this.appointmentToMove;
      this.appointmentToMove = null;

      let useTime = this.roundTime(time, this.getIntervalMinutes);

      let displayDate = new Date(date);
      let wot = formatDate(displayDate);

      let dialog: IDialog = {
        text:
          this.$t("calendarMessages.moveAppointmentTo") +
          " " +
          wot +
          " " +
          useTime +
          "?",
        type: DialogType.Choice,
        action: function () {
          vm.confirmMove(date, useTime, id);
        },
      };

      this.$store.commit(DialogMutations.AddToDialogQueue, dialog);
      return;
    }
    if (this.selectedOpen) {
      return;
    }

    const open = () => {
      if (this.selectedOpen) {
        this.bookOpen = false;
        return;
      }
      let newDate = new Date(date);

      let selectedDateToGetAppointmentsFrom = {
        day: newDate.getDate(),
        month: newDate.getMonth(),
        year: newDate.getFullYear(),
      };
      this.selectDateToBook(selectedDateToGetAppointmentsFrom);

      console.log("time: ", time);
      console.log("Interval minutes: ", this.getIntervalMinutes);
      let useTime = this.roundTime(time, this.getIntervalMinutes);
      console.log("Use time: ", useTime);

      this.selectHourToBook(useTime); //TODO: Appointment dist

      //@ts-ignore
      this.selectedEvent = event as IAppointmentForWeeklyCalender; //TODO: WTF?

      setTimeout(() => (this.bookOpen = true), 10);
    };

    if (this.bookOpen) {
      this.bookOpen = false;
      setTimeout(open, 10);
    } else {
      setTimeout(open, 10);
    }
  }

  get getCalendarMargin() {
    var width =
      window.innerWidth ||
      document.documentElement.clientWidth ||
      document.body.clientWidth;

    //No default padding
    if (width < 1263) {
      return {
        "margin-top": "10px",
      };
    } else {
      //Has main header
      return {
        "margin-top": "40px",
      };
    }
  }

  showEvent({ nativeEvent, event }) {
    if (this.highlightEvent != null) {
      clearInterval(this.flashInterval);
      this.highlightEvent.color = "green";
    }

    // if(this.bookOpen){
    //     this.bookOpen = false;
    // }
    const open = () => {
      this.selectedEvent = event;
      this.selectedElement = nativeEvent.target;
      setTimeout(() => (this.selectedOpen = true), 10);
    };

    if (this.selectedOpen) {
      this.selectedOpen = false;
      setTimeout(open, 10);
    } else {
      open();
    }

    nativeEvent.stopPropagation();
  }

  weekdayFormat(weekday) {
    return getWeekDay(weekday.weekday);
  }
  eventFormat(event) {
    if (this.getCalendarType == "month") {
      return (
        "<strong>" +
        event.input.name +
        "</strong>  - <span>" +
        event.start.time +
        "-" +
        event.end.time +
        "</span>"
      );
    } else {
      let html = "<strong>" + event.input.name + "</strong>";
      if (this.user.showTimeInEvent) {
        console.log("True");
        html +=
          "<br> <span>" + event.start.time + "-" + event.end.time + "</span>";
      }
      console.log(
        "Show service name in event: ",
        this.user.showServiceNameInEvent
      );

      if (this.user.showServiceNameInEvent && !event.input.isCustom) {
        console.log("True");

        html += "<br> <span>" + event.input.appointmentName + "</span>";
      }

      if (this.user.showStaffMemberInEvent == 1) {
        html += "<br> <span>" + event.input.staffMemberName + "</span>";
      }
      return html;
    }
  }
  intervalFormat(interval) {
    if (isMobile()) {
      if (interval.minute != 0) {
        return "";
      } else {
        return interval.time.substring(0, 2);
      }
    } else {
      return formatTimeWithFormat(interval.hour, interval.minute, true);
    }
  }

  formatTimeWithFormatForEvent(eventInfo) {
    return formatTimeWithFormat(eventInfo.hour, eventInfo.minute, false, false);
  }

  intervalStyle(interval) {
    if (this.hasMultipleStaff && this.getCurrentStaffMember === 0) {
      return { background: "white" };
    }

    let color = "#F7F7F7";

    if (interval.time == "05:00") {
      return { background: color };
    }
    if (interval.time == "00:00") {
      return { background: color };
    }
    if (interval.time == "24:00") {
      return { background: color };
    }

    if (interval.category != null && interval.category != "") {
      let staffMember: Partial<IStaffMemberDTO> = this.staffMembers.find(
        (x) => x.firstName + " " + x.lastName == interval.category
      );

      //bookingSettingsIntervals is different because we sent "1" as staff member in the backend --- we recieve array of staff members that is formatted like the old ones

      let bookingSettingsInterval = this.bookingSettingsIntervals.find((x) => {
        return x.staffMemberId === staffMember.id;
      });
      let o = bookingSettingsInterval.intervals[interval.weekday];
      return this.getHourColorForStaffWeekdayInterval(interval, color, o);
    }

    let staffMember: Partial<IStaffMemberDTO> = this.staffMembers.find(
      (x) => x.id === this.staffMemberId
    );

    let bookingSettingsInterval = this.bookingSettingsIntervals.find((x) => {
      return x.staffMemberId === staffMember.id;
    });
    let o = bookingSettingsInterval.intervals[interval.weekday];

    return this.getHourColorForStaffWeekdayInterval(interval, color, o);
  }

  getHourColorForStaffWeekdayInterval(
    interval,
    color,
    bookingSettingsIntervals
  ) {
    let workingHoursForThisDay: CalendarInterval = bookingSettingsIntervals;
    if (workingHoursForThisDay == null) {
      return { background: color };
    }

    //TODO: Move this logic backend
    let intervalTime = new Date();
    intervalTime.setHours(interval.hour);
    intervalTime.setMinutes(interval.minute);
    intervalTime.setSeconds(0);

    //TODO: Do this operation only once if keep in front-end...
    let startTimeForThisWeekDay = new Date();
    startTimeForThisWeekDay.setHours(workingHoursForThisDay.openHour);
    startTimeForThisWeekDay.setMinutes(workingHoursForThisDay.openMinute);
    startTimeForThisWeekDay.setSeconds(0);

    let endTimeForThisWeekday = new Date();
    endTimeForThisWeekday.setHours(workingHoursForThisDay.closeHour);
    endTimeForThisWeekday.setMinutes(workingHoursForThisDay.closeMinute);
    endTimeForThisWeekday.setSeconds(0);

    if (
      intervalTime >= endTimeForThisWeekday ||
      intervalTime < startTimeForThisWeekDay
    ) {
      return { background: color };
    }

    return { background: "white" };
  }

  async addedAppointment() {
    this.bookOpen = false;
    await this.getEvents();
  }

  async onDeleted() {
    setTimeout(() => (this.selectedEvent = null), 10);
    setTimeout(() => (this.selectedElement = null), 10);
    this.selectedOpen = false;
    await this.getEvents();
  }

  async onMoved() {
    this.selectedOpen = false;

    await this.getEvents();
  }

  addAlpha(color, opacity) {
    // coerce values so ti is between 0 and 1.
    var _opacity = Math.round(Math.min(Math.max(opacity || 1, 0), 1) * 255);
    return color + _opacity.toString(16).toUpperCase();
  }
  getEventColor(event) {
    if (this.appointmentToMove === event.appointmentId) {
      return this.addAlpha(event.color, 0.5);
    } else {
      return event.color;
    }
  }

  get now() {
    const tzoffset = new Date().getTimezoneOffset() * 60000; //offset in milliseconds

    //@ts-ignore
    const now = new Date(new Date() - tzoffset);
    //Why? If it's a sunday 12:39, it's actually monday.
    //But umm
    //Super fucking edge-case, but if it's sunday 12:39, new Date().getDay will show Sunday
    //fuck it. i don't understand. it works tho.

    let nowWeekDay = now.getDay();
    let isWorkingToday = this.allWorkingDays[nowWeekDay];
    if (isWorkingToday || this.user.hideIrrelevantDaysInCalendar == false) {
      return now.toISOString().substr(0, 10);
    } else {
      //Handle edge-case, where if we don't work on this current day (say, Sunday) and the current day is Sunday,
      //And we hide non-working days in the calendar, a v-calendar bug will appear where
      //The week calendar will include Monday of the next week too...
      let traversed = 0;
      let newWeekDayIndex = 0;
      let direction = 1;

      while (traversed < 7) {
        traversed++;
        newWeekDayIndex += direction;
        if (nowWeekDay === 0) {
          newWeekDayIndex = 6;
          direction = -1;
        }
        let isWorkingInIndex = this.allWorkingDays[newWeekDayIndex];
        if (isWorkingInIndex) {
          let latestWorkingDay = new Date(
            new Date().getTime() - 24 * 60 * 60 * 1000 * traversed
          );
          return latestWorkingDay.toISOString().substr(0, 10);
        }
      }
      let now = new Date().toISOString().substr(0, 10);
      return now; //Okay... so there is no working days... uh... just return now I guess. Why use a calendar if no days? *shrug*
    }
  }

  async onSummaryClose() {
    this.selectedOpen = false;

    await this.getEvents();
  }

  moveAppointment(id) {
    this.appointmentToMove = id;
    this.selectedOpen = false;
  }

  get hideMobileFooter() {
    return this.$store.getters.hideMobileFooter;
  }

  goPrevious(e) {
    if (Math.abs(e.touchendX - e.touchstartX) > 100) {
      let calendar: any = this.$refs.calendar;
      if (calendar != null) {
        calendar.prev();
      }
    }
  }

  getFirstWordOfString(value) {
    return value.split(" ")[0];
  }

  goNext(e) {
    if (Math.abs(e.touchendX - e.touchstartX) > 100) {
      let calendar: any = this.$refs.calendar;
      if (calendar != null) {
        calendar.next();
      }
    }
  }
}
