import { render, staticRenderFns } from "./CalendarStyleSettings.vue?vue&type=template&id=60edf06a&scoped=true&"
import script from "./CalendarStyleSettings.vue?vue&type=script&lang=ts&"
export * from "./CalendarStyleSettings.vue?vue&type=script&lang=ts&"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "60edf06a",
  null
  
)

export default component.exports