
import { Component, Vue, Prop } from "vue-property-decorator";
import { ICustomer } from "../../../store/customers";
import { formatDateStringHour } from "../../../Utilities/dateUtility";

@Component({
  name: "CustomerExpandedDetails",
})
export default class CustomerExpandedDetails extends Vue {
  @Prop({ type: Array, required: true }) headers!: any[];
  @Prop({ type: Object, required: true }) customer!: Partial<ICustomer>;

  formatDateStringHour(date: Date) {
    return formatDateStringHour(date);
  }
}
