import { render, staticRenderFns } from "./EmailListSettings.vue?vue&type=template&id=13e419cb&scoped=true&"
import script from "./EmailListSettings.vue?vue&type=script&lang=ts&"
export * from "./EmailListSettings.vue?vue&type=script&lang=ts&"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "13e419cb",
  null
  
)

export default component.exports