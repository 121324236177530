
import { Component, Vue, Prop, Watch } from "vue-property-decorator";

@Component({
  name: "CustomerSearchBar",
})
export default class CustomerSearchBar extends Vue {
  @Prop({ type: String, default: "" }) readonly value!: string;

  // Computed property for two-way binding
  get internalValue(): string {
    return this.value;
  }

  set internalValue(val: string) {
    this.$emit("input", val); // Emit the input event for v-model
    this.onSearch(val); // Emit a custom event if needed
  }

  // Optional: If you still want to emit a separate event
  onSearch(value: string) {
    this.$emit("search", value);
  }
}
