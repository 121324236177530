
import Vue from "vue";
import { Component, Prop, Watch } from "vue-property-decorator";
import SettingsMobile from "../../pages/admin/SettingsMobile.vue";
import AuditLogger, { AuditLoggerType } from "../../mixins/AuditLogger";

@Component({
  name: "SettingsDialog",
  components: { SettingsMobile },
  mixins: [AuditLogger],
})
export default class SettingsDialog extends Vue implements AuditLoggerType {
  @Prop({ required: true }) value!: boolean;

  // Implement the mixin method to satisfy TypeScript
  logAudit!: (message: string, action?: number, type?: number) => void;

  get dialogVisible(): boolean {
    return this.value;
  }

  set dialogVisible(value: boolean) {
    this.$emit("input", value);
  }

  closeDialog(): void {
    this.logAudit(" closed settings dialog");
    this.dialogVisible = false;
  }
}
