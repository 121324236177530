import { render, staticRenderFns } from "./BookingPageGeneralSettings.vue?vue&type=template&id=5b77afc4&scoped=true&"
import script from "./BookingPageGeneralSettings.vue?vue&type=script&lang=ts&"
export * from "./BookingPageGeneralSettings.vue?vue&type=script&lang=ts&"


/* normalize component */
import normalizer from "!../../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "5b77afc4",
  null
  
)

export default component.exports