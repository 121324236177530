var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',[(_vm.hasNoSubscription && _vm.user.lastYearlySubscription == null)?_c('v-alert',{attrs:{"dense":"","border":"left","type":"error","icon":"mdi-alert-octagon"}},[_vm._v(" "+_vm._s(_vm.$t("subscription.noTrial"))+" ")]):_vm._e(),(_vm.hasPaymentIssues)?_c('v-alert',{attrs:{"dense":"","border":"left","type":"error","icon":"mdi-alert-octagon"}},[_vm._v(" "+_vm._s(_vm.$t("subscription.paymentIssue"))+" ")]):_vm._e(),(_vm.hasTrialPlan)?_c('v-alert',{attrs:{"dense":"","border":"left","type":"info","icon":"mdi-alert-circle-outline"}},[_vm._v(" "+_vm._s(_vm.$t("subscription.trialDaysLeft", { days: this.subscriptionStatus.daysLeftOfTrial, }))+" ")]):_vm._e(),(
      _vm.hasCanceledSubscriptionPlan && _vm.subscriptionExpirationDate.length > 5
    )?_c('v-alert',{attrs:{"dense":"","border":"left","type":"info","icon":"mdi-alert-circle-outline"}},[_vm._v(" "+_vm._s(_vm.$t("subscription.cancelledWithDate", { date: _vm.subscriptionExpirationDate, }))+" ")]):_vm._e(),(
      _vm.hasCanceledSubscriptionPlan && _vm.subscriptionExpirationDate.length <= 5
    )?_c('v-alert',{attrs:{"dense":"","border":"left","type":"info","icon":"mdi-alert-circle-outline"}},[_vm._v(" "+_vm._s(_vm.$t("subscription.cancelledWithoutDate"))+" ")]):_vm._e(),(_vm.hasActiveSubscription || _vm.user.lastYearlySubscription != null)?_c('v-alert',{attrs:{"dense":"","border":"left","type":"success","color":"green","icon":"mdi-check-circle"}},[_vm._v(" "+_vm._s(_vm.$t("subscription.active"))+" ")]):_vm._e(),(_vm.checkout)?_c('stripe-payment',{attrs:{"description":_vm.description,"amount":_vm.amount},on:{"closed":function($event){_vm.checkout = false},"recieveformdata":_vm.recievedFormData,"recievepaymenttoken":_vm.recievedPaymentToken}}):_vm._e(),(_vm.isLoading)?_c('loader'):_vm._e(),(_vm.user.lastYearlySubscription != null)?_c('v-card-text',[_vm._v(" "+_vm._s(_vm.$t("subscription.yearlyWithSMS", { date: _vm.getYearlyFinish }))+" ")]):_vm._e(),(_vm.user.lastYearlySubscription == null)?_c('v-card-text',[_c('p',[_vm._v(_vm._s(_vm.$t("subscription.monthlyCost")))]),_c('p',[_vm._v(_vm._s(_vm.$t("subscription.automaticPayment")))]),_c('p',{staticStyle:{"font-weight":"bold"}},[_vm._v(_vm._s(_vm.$t("subscription.smsCost")))]),(
        !_vm.hasActiveSubscription && !_vm.hasCanceledSubscriptionPlan && !_vm.isLoading
      )?_c('v-btn',{attrs:{"color":"success"},on:{"click":function($event){_vm.checkout = true}}},[_vm._v(" "+_vm._s(_vm.$t("subscription.purchase"))+" ")]):_vm._e(),(_vm.hasCanceledSubscriptionPlan && !_vm.isLoading)?_c('v-btn',{attrs:{"color":"success"},on:{"click":_vm.continueSubscription}},[_vm._v(" "+_vm._s(_vm.$t("subscription.reactivate"))+" ")]):_vm._e(),(_vm.hasActiveSubscription && !_vm.isLoading)?_c('v-btn',{on:{"click":_vm.triggerCancelSubscriptionModal}},[_vm._v(" "+_vm._s(_vm.$t("subscription.cancel"))+" ")]):_vm._e()],1):_vm._e(),_c('div')],1)
}
var staticRenderFns = []

export { render, staticRenderFns }