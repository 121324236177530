
"use strict";

import Vue from "vue";
import { Component } from "vue-property-decorator";
import TreatmentTypes from "@/components/Settings/Services/TreatmentTypes.vue";
import ServiceCategories from "../../components/global/ServiceCategories.vue";

//* Component definition ************************************************************

@Component({
  name: "services",
  components: { ServiceCategories, TreatmentTypes },
})

//* Class ***************************************************************************
export default class services extends Vue {}
