
import { Component, Prop, Vue } from "vue-property-decorator";

@Component({
  name: "SettingsListItem",
})
export default class SettingsListItem extends Vue {
  @Prop({ type: String, required: true }) readonly icon!: string;
  @Prop({ type: String, required: true }) readonly label!: string;
  @Prop({ type: Boolean, default: false }) readonly disabled!: boolean;

  get isMobile() {
    return /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
      navigator.userAgent
    );
  }
}
