
import { Component, Vue, Prop } from "vue-property-decorator";
import { ICustomer } from "../../../store/customers";
import { formatDateStringHour } from "../../../Utilities/dateUtility";
import CustomerExpandedDetails from "./CustomerExpandedDetails.vue";

@Component({
  name: "CustomerTable",
  components: {
    CustomerExpandedDetails,
  },
})
export default class CustomerTable extends Vue {
  @Prop({ type: Array, required: true }) customers!: Partial<ICustomer>[];
  @Prop({ type: String, default: "" }) search!: string;
  @Prop({ type: Boolean, default: false }) isMobile!: boolean;
  @Prop({ type: Array, default: () => [] }) value!: Partial<ICustomer>[];

  get tableHeaders() {
    if (this.isMobile) {
      return [
        {
          text: this.$t("customer.name"),
          align: "start",
          sortable: true,
          value: "firstName",
        },
        { text: "", value: "data-table-expand" },
      ];
    }
    return [
      {
        text: this.$t("customer.name"),
        align: "start",
        sortable: true,
        value: "firstName",
      },
      {
        text: this.$t("customer.email"),
        sortable: true,
        value: "emailAddress",
      },
      {
        text: this.$t("customer.phoneNumber"),
        sortable: true,
        value: "phoneNumber",
      },
      { text: this.$t("customer.address"), sortable: true, value: "address" },
      {
        text: this.$t("customer.postalCodeCity"),
        sortable: true,
        value: "postalCode",
      },
      { text: this.$t("customer.edit"), value: "actions", sortable: false },
      {
        text: this.$t("customer.created"),
        sortable: true,
        value: "dateAdded",
      },
    ];
  }

  formatDateStringHour(date: Date) {
    return formatDateStringHour(date);
  }
}
