
import Vue from "vue";
import Component from "vue-class-component";
import { Getter, Action } from "vuex-class";
import {
  IStaffMemberDTO,
  IUserDTO,
} from "@/types/AutoGenerated/reinforcedTypings";
import { formatDateStringHour } from "@/Utilities/dateUtility";

@Component({
  name: "EmailListSettings",
})
export default class EmailListSettings extends Vue {
  public activateEmailList: boolean = false;
  public isLoading: boolean = false;

  @Action public postSnackbarMessage!: Function;
  @Action public getEmailList!: Function;
  @Getter public emailList!: IStaffMemberDTO[];
  @Getter user!: IUserDTO;
  @Action patchUser!: Function;

  public headers = [
    { text: this.$t("email"), sortable: true, value: "email" },
    { text: this.$t("name"), sortable: true, value: "name" },
    { text: this.$t("signedUp"), sortable: true, value: "signupDate" },
  ];

  async created() {
    this.activateEmailList = this.user.activateEmailList;
    this.isLoading = true;
    await this.getEmailList();
    this.isLoading = false;
  }

  async saveEmailListSetting(value: boolean): Promise<void> {
    try {
      await this.patchUser({ activateEmailList: value });
    } catch (error) {
      console.error(error);
      // Reset to previous value on error
      this.activateEmailList = this.user.activateEmailList;
      this.postSnackbarMessage(this.$t("errors.savingFailed").toString());
    }
  }

  formatDateStringHour(date: Date): string {
    return formatDateStringHour(date);
  }

  booleanToOnOff(value: boolean): string {
    return value
      ? this.$t("boolean.on").toString()
      : this.$t("boolean.off").toString();
  }
}
