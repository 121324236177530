
import { Component, Prop, Vue } from "vue-property-decorator";

@Component({
  name: "textfield",
})
export default class Textfield extends Vue {
  @Prop() public value!: string;
  @Prop() public number!: boolean;
  @Prop({ default: false }) public disabled!: boolean;
  @Prop({ default: () => [] }) public rules!: Array<
    (v: any) => boolean | string
  >;

  private errorMessages: string[] = [];

  get textFieldStyle() {
    return {
      borderRadius: "8px",
      maxWidth: this.number ? "250px" : "250px",
      minWidth: this.number ? "250px" : "250px",
    };
  }

  get inputValue() {
    return this.value || "";
  }

  set inputValue(value: string) {
    this.$emit("input", value);
  }

  onInput(value: any) {
    this.validateInput(value);
  }

  onBlur() {
    this.$emit("blur");
    this.validateInput(this.inputValue);
  }

  private validateInput(value: any) {
    this.errorMessages = [];
    for (const rule of this.rules) {
      const result = rule(value);
      if (result !== true) {
        this.errorMessages.push(result);
        break; // Show only first error message
      }
    }
  }

  mounted() {
    // Initial validation
    this.$nextTick(() => {
      this.validateInput(this.inputValue);
    });
  }
}
