
import { Component, Vue, Prop } from "vue-property-decorator";

@Component({
  name: "CustomerActions",
})
export default class CustomerActions extends Vue {
  @Prop({ type: Boolean, required: true }) isShowingForm!: boolean;
  @Prop({ type: Boolean, required: true }) hasSelectedCustomers!: boolean;
}
