// @ts-ignore
import { ActionTree, GetterTree, MutationTree } from "vuex";
// @ts-ignore
import axios, { AxiosRequestConfig } from "axios";
import { APIURL2 } from "../../main";
import { actionStringSnackbar, mutationStringSnackbar } from "../snackbar";
import authConfig, { AuthConfig } from "../../Utilities/authConfig";
import {
  appointmentDistribution,
  AppointmentInfo,
  IAppointmentForm,
  IAppointments,
  IBookedAppointmentCustomer,
  IBookedAppointments,
  ILatestDeletedAppointment,
  selectedDate,
  selectHour,
} from "@/store/appointments/appointmentTypings";
import { deleteRequest, getRequest } from "@/Utilities/axiosHelper";
import { getCurrentMonth, getWeekNumber } from "@/Utilities/dateUtility";
import {
  CustomerAccountsMutations,
  ICustomerAccount,
} from "../customerAccounts";
import {
  AppointmentChangeAsCustomAccount,
  appointmentsState,
} from "./appointmentTypings";
import { DialogActions, DialogType, IDialog } from "../dialog";
import {
  IAppointmentDTO,
  IPostAppointmentDTO,
} from "../../types/AutoGenerated/reinforcedTypings";
import { createPatchObject } from "../../Utilities/ObjectUtility";
import { i18n } from "@/main"; // Import i18n for translations

// Mutation enums
export const enum mutationStringAppointments {
  setAppointments = "setAppointments",
  setSelectedDate = "setSelectedDate",
  setSelectedHour = "setSelectedHour", //Gradly remove
  SET_SELECTED_HOUR = "setSelectedHour",
  setAppointmentDistribution = "setAppointmentDistribution",
  setBookedAppointments = "setBookedAppointments",
  setBookedAppointmentCustomers = "setBookedAppointmentCustomers",
  setLatestDeletedAppointment = "setLatestDeletedAppointment",
  SET_ALL_APPOINTMENTS = "setAllAppointments",
  SetAppointmentsForToday = "setAppointmentsForToday",
  SetEvents = "setEvents",
  SetAppointmentsForOverview = "setAppointmentsForOverview",
}
export const enum actionStringAppointments {
  GET_ALL_APPOINTMENTS = "getAllAppointments",
  deleteAppointmentByCancelId = "deleteAppointmentByCancelId",
  GET_APPOINTMENT_BY_NUMBER = "getAppointmentByNumber",
}
export const enum getterStringAppointments {
  ALL_APPOINTMENTS = "allAppointments",
}

export interface IAppointmentPatch {
  appointmentId: number;
  patch: Partial<IAppointmentDTO>;
}

export const state: Partial<appointmentsState> = {
  appointments: null,
  selectedDate: null,
  selectHour: null,
  bookedAppointments: null,
  bookedAppointmentCustomers: null,
  latestDeletedAppointment: null,
  allAppointments: null,
  appointmentsForToday: null,
  events: [],
  appointmentsForOverview: [],
  appointmentSource: null,
};

export const getters: GetterTree<any, appointmentsState> = {
  appointments: (state) => state.appointments,
  selectedDate: (state) => state.selectedDate,
  selectHour: (state) => state.selectHour,
  bookedAppointments: (state) => state.bookedAppointments,
  bookedAppointmentCustomers: (state) => state.bookedAppointmentCustomers,
  latestDeletedAppointment: (state) => state.latestDeletedAppointment,
  allAppointments: (state) => state.allAppointments,
  appointmentsForToday: (state) => state.appointmentsForToday,
  events: (state) => state.events,
  appointmentsForOverview: (state) => state.appointmentsForOverview,
  appointmentSource: (state) => state.appointmentSource,
};

export const mutations: MutationTree<appointmentsState> = {
  setAppointments(state, payload: IAppointments) {
    state.appointments = payload;
  },
  setSelectedDate(state, payload: selectedDate) {
    state.selectedDate = payload;
  },
  setSelectedHour(state, payload: selectHour) {
    state.selectHour = payload;
  },
  setBookedAppointments(state, payload: IBookedAppointments) {
    state.bookedAppointments = payload;
  },
  setBookedAppointmentCustomers(state, payload: IBookedAppointmentCustomer) {
    state.bookedAppointmentCustomers = payload;
  },
  setLatestDeletedAppointment(state, payload: any) {
    state.latestDeletedAppointment = payload;
  },
  setAllAppointments(state, payload: AppointmentInfo[]) {
    state.allAppointments = payload;
  },
  setAppointmentsForToday(state, payload) {
    state.appointmentsForToday = payload;
  },
  setEvents(state, payload) {
    state.events = payload;
  },
  setAppointmentsForOverview(state, payload) {
    state.appointmentsForOverview = payload;
  },
  setAppointmentsSource(state, payload) {
    state.appointmentSource = payload;
  },
};

export const actions: ActionTree<appointmentsState, any> = {
  selectDateToBook({ commit }, selectedDate: selectedDate): void {
    commit(mutationStringAppointments.setSelectedDate, selectedDate);
  },
  selectHourToBook({ commit }, selectedHour: selectHour): void {
    commit(mutationStringAppointments.setBookedAppointmentCustomers, null);
    commit(mutationStringAppointments.setSelectedHour, selectedHour);
  },
  async getAppointmentsForOverview({ commit }): Promise<any> {
    let header: AxiosRequestConfig = await authConfig();

    return new Promise((resolve, reject) => {
      axios
        .get(APIURL2 + "Appointment/current", header)
        .then((res) => {
          commit(
            mutationStringAppointments.SetAppointmentsForOverview,
            res.data
          );
          resolve(res.data);
        })
        .catch((err) => {
          console.log(err);
          reject(err);
        });
    });
  },
  async downloadAppointments({}): Promise<any> {
    let header: AxiosRequestConfig = await authConfig();
    header.responseType = "blob";

    return new Promise((resolve, reject) => {
      axios
        .get(
          APIURL2 + "Appointment/current/download", //dont forget to do the funny stuff in the await.create element a and all theat junk
          header
        )
        .then((res) => {
          resolve(res.data);
        })
        .catch((err) => {
          console.log(err);
          reject(err);
        });
    });
  },

  async bookAppointment(
    { commit, dispatch },
    appointment: IPostAppointmentDTO
  ): Promise<any> {
    let header: AxiosRequestConfig = await authConfig();

    dispatch(
      actionStringSnackbar.postLoadingSnackbarMessage,
      i18n.t("appointments.creatingAppointment").toString()
    );

    return new Promise((resolve) => {
      axios
        .post(APIURL2 + "Appointment/current", appointment, header)
        .then((res) => {
          commit(mutationStringAppointments.setAppointments, res.data);
          dispatch(
            actionStringSnackbar.postSuccessSnackbarMessage,
            i18n.t("appointments.appointmentCreated").toString()
          );

          if (appointment.month == getCurrentMonth()) {
            // Update statistics for fast loading in dashboard
            dispatch("getMoneyMade", getCurrentMonth());
            dispatch("getAppointmentsDone", getCurrentMonth());
          }
          resolve(appointment);
        })
        .catch((err) => {
          if (err.response.data === "Cannot book appointment - it's occupied") {
            let dialog: IDialog = {
              text: i18n.t("appointments.overlappingAppointment").toString(),
              type: DialogType.YesNo,
              action: () => {
                // TODO: DRY WITH POST APPOINTMENT
                appointment.forceCreate = true;
                dispatch("bookAppointment", appointment).then(() => {
                  resolve(appointment);
                });
              },
            };
            dispatch(DialogActions.AddToDialogQueue, dialog);
          }
        });
    });
  },
  async updateAppointmentDistribution(
    { commit },
    appointmentDistribution: appointmentDistribution
  ) {
    let header: AxiosRequestConfig = await authConfig();

    axios
      .put(
        APIURL2 +
          "AppointmentDistribution?newDistribution=" +
          appointmentDistribution,
        {},
        header
      )
      .then(() => {
        commit(
          mutationStringAppointments.setAppointmentDistribution,
          appointmentDistribution
        );
      })
      .catch((err) => {
        console.log(err);
      });
  },

  async getBookedAppointmentsForMonth({ commit }, payload): Promise<any> {
    let header: AxiosRequestConfig = await authConfig();

    let month = payload.getMonth() + 1;

    return new Promise((resolve, reject) => {
      axios
        .get(
          APIURL2 +
            "Appointment/onMonth/forCalender/current?month=" +
            month +
            "&year=" +
            payload.getFullYear(),
          header
        )
        .then((res) => {
          commit(mutationStringAppointments.SetEvents, res.data);
          resolve(res.data);
        })
        .catch((err) => {
          console.log(err);
          reject(err);
        });
    });
  },
  async getBookedAppointmentsForTwoWeeks({ commit }, payload): Promise<any> {
    console.log("Payload for get booked appointments this week_ ", payload);
    let header: AxiosRequestConfig = await authConfig();

    const week = getWeekNumber(payload);

    return new Promise((resolve, reject) => {
      axios
        .get(
          APIURL2 +
            "Appointment/onWeek/forCalender/current?week=" +
            week +
            "&year=" +
            payload.getFullYear() +
            "&extraWeek=true",
          header
        )
        .then((res) => {
          commit(mutationStringAppointments.SetEvents, res.data);
          resolve(res.data);
        })
        .catch((err) => {
          console.log(err);
          reject(err);
        });
    });
  },
  async getBookedAppointmentsForWeek({ commit }, payload): Promise<any> {
    console.log("Payload for get booked appointments this week_ ", payload);
    let header: AxiosRequestConfig = await authConfig();

    const week = getWeekNumber(payload);

    return new Promise((resolve, reject) => {
      axios
        .get(
          APIURL2 +
            "Appointment/onWeek/forCalender/current?week=" +
            week +
            "&year=" +
            payload.getFullYear(),
          header
        )
        .then((res) => {
          commit(mutationStringAppointments.SetEvents, res.data);
          resolve(res.data);
        })
        .catch((err) => {
          console.log(err);
          reject(err);
        });
    });
  },
  async getBookedAppointmentsForDay({ commit }, payload: Date): Promise<any> {
    let header: AxiosRequestConfig = await authConfig();

    return new Promise((resolve, reject) => {
      axios
        .get(
          APIURL2 +
            "Appointment/onEvent/forCalender/current?day=" +
            payload.getDate() +
            "&month=" +
            (payload.getMonth() + 1) +
            "&year=" +
            payload.getFullYear(),
          header
        )
        .then((res) => {
          commit(mutationStringAppointments.SetAppointmentsForToday, res.data);
          resolve(res.data);
        })
        .catch((err) => {
          console.log(err);
          reject(err);
        });
    });
  },

  async getAppointmentByNumber(
    { commit },
    appointmentNumber
  ): Promise<AppointmentInfo[]> {
    let header: AxiosRequestConfig = await authConfig();

    return new Promise((resolve, reject) => {
      axios
        .get(
          APIURL2 +
            "Appointment/byNumber/current?appointmentNumber=" +
            appointmentNumber,
          header
        )
        .then((res) => {
          resolve(res.data);
        })
        .catch((err) => {
          console.log(err);
          reject(err);
        });
    });
  },

  async getAllAppointments({ commit }): Promise<AppointmentInfo[]> {
    let header: AxiosRequestConfig = await authConfig();

    return new Promise((resolve, reject) => {
      axios
        .get(APIURL2 + "Appointment/current", header)
        .then((res) => {
          commit(mutationStringAppointments.SET_ALL_APPOINTMENTS, res.data);
          resolve(res.data);
        })
        .catch((err) => {
          console.log(err);
          reject(err);
        });
    });
  },

  async getBookedAppointmentsAnonymous({ commit }, payload): Promise<string[]> {
    return new Promise((resolve, reject) => {
      axios
        .get(
          APIURL2 +
            "Appointment/OnDate/forCalender?day=" +
            payload.day +
            "&month=" +
            payload.month +
            "&year=" +
            payload.year +
            "&userId=" +
            payload.adminId,
          {}
        )
        .then((res) => {
          commit(mutationStringAppointments.setBookedAppointments, res.data);
          resolve(res.data);
        })
        .catch((err) => {
          console.log(err);
          reject(err);
        });
    });
  },

  async deleteBookedAppointment({ dispatch, commit }, payload) {
    let header: AxiosRequestConfig = await authConfig();

    return new Promise((resolve, reject) => {
      axios
        .delete(
          APIURL2 + "Appointment/current?id=" + payload.appointmentId,
          header
        )
        .then(() => {
          dispatch("getAppointmentsForOverview", payload);
          commit(
            mutationStringAppointments.setLatestDeletedAppointment,
            payload
          );
          resolve("ok");
        })
        .catch((err) => {
          console.log(err);
          reject(err);
        });
    });
  },

  async changeAppointmentAsCustomerAccount(
    { dispatch },
    appointment: AppointmentChangeAsCustomAccount
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      try {
        axios
          .put(APIURL2 + "Appointment/ChangeAsCustomerAccount", appointment)
          .then(
            (response) => {
              return resolve(response);
            },
            () => {
              dispatch(
                actionStringSnackbar.postSnackbarMessage,
                "Kunne ikke endre notat."
              );
            }
          );
      } catch (e) {
        reject(e);
      }
    });
  },

  async changeAppointmentStaff({ dispatch }, payload): Promise<any> {
    let header: AxiosRequestConfig = await authConfig();

    return new Promise((resolve, reject) => {
      try {
        axios
          .patch(
            APIURL2 +
              "Appointment/current/staffMember?staffMemberId= " +
              payload.staffMemberId +
              "&appointmentId=" +
              payload.appointmentId,
            {},
            header
          )
          .then((response) => {
            return resolve(response);
          });
      } catch (e) {
        reject(e);
      }
    });
  },

  async patchAppointment(
    { dispatch },
    payload: IAppointmentPatch
  ): Promise<any> {
    let patchPayload = createPatchObject(payload.patch);

    let header: AxiosRequestConfig = await authConfig();

    return new Promise((resolve, reject) => {
      try {
        axios
          .patch(
            APIURL2 +
              "Appointment/current?appointmentId=" +
              payload.appointmentId,
            patchPayload,
            header
          )
          .then((response) => {
            return resolve(response);
          });
      } catch (e) {
        reject(e);
      }
    });
  },

  async updateAppointment({ dispatch }, appointment): Promise<any> {
    let header: AxiosRequestConfig = await authConfig();

    return new Promise((resolve, reject) => {
      try {
        axios
          .put(APIURL2 + "Appointment/current", appointment, header)
          .then((response) => {
            return resolve(response);
          });
      } catch (e) {
        reject(e);
      }
    });
  },
  async sendVerificationCode(
    { dispatch },
    appointment: IAppointmentForm
  ): Promise<any> {
    return new Promise((resolve) => {
      axios
        .post(
          APIURL2 +
            "Appointment/verificationCode/?userId=" +
            appointment.userId,
          appointment
        )
        .then(
          (response) => {
            return resolve(response);
          },
          () => {
            dispatch(
              actionStringSnackbar.postSnackbarMessage,
              "Kunne ikke sende verifikasjonskode."
            );
          }
        );
    });
  },

  async bookAppointmentAsAnonymous(
    { dispatch },
    appointment: IAppointmentForm
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      try {
        axios
          .post(
            APIURL2 + "Appointment?userId=" + appointment.userId,
            appointment
          )
          .then(
            (response) => {
              return resolve(response);
            },
            (error) => {
              if (
                error.response.data ===
                "Cannot book appointment - it's occupied"
              ) {
                dispatch(
                  actionStringSnackbar.postSnackbarMessage,
                  i18n.t("appointments.alreadyBooked").toString()
                );
                reject(error);
              }
            }
          );
      } catch (e) {
        if (e.response.data === "Cannot book appointment - it's occupied") {
          dispatch(
            actionStringSnackbar.postSnackbarMessage,
            i18n.t("appointments.overlappingWithNext").toString()
          );
          reject(e);
        }
      }
    });
  },

  async getCustomerAccountAppointments(
    { dispatch },
    customerAccount: ICustomerAccount
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      try {
        axios
          .post(APIURL2 + "Appointment/forCustomerAccount", customerAccount, {})
          .then((response) => {
            return resolve(response.data);
          });
      } catch (e) {
        reject(e);
      }
    });
  },

  async verifyAppointment(
    { dispatch, commit },
    verificationCode
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      try {
        axios
          .post(
            APIURL2 +
              "Appointment/verify?verificationCode=" +
              verificationCode.code +
              "&userId=" +
              verificationCode.userId
          )
          .then(
            (response) => {
              commit(
                CustomerAccountsMutations.SetCustomerAccount,
                response.data
              );
              return resolve(response);
            },
            (error) => {
              dispatch(
                actionStringSnackbar.postSnackbarMessage,
                "Feil verifiseringskode"
              );
              reject(error);
            }
          );
      } catch (e) {
        dispatch(
          actionStringSnackbar.postSnackbarMessage,
          "Feil verifiseringskode"
        );
        reject(e);
      }
    });
  },
};

export const appointments = {
  actions,
  mutations,
  getters,
  state,
};
