
import { Component, Prop, Vue, Watch } from "vue-property-decorator";
import { Action, Getter } from "vuex-class";
import { onboardingProgressType } from "@/types/OnboardingProgress";
import { Sector } from "@/types/Sector";
import { IUserDTO } from "@/types/AutoGenerated/reinforcedTypings";

export interface ILocalUser {
  firstName: string;
  lastName: string;
  phoneNumber: string;
}

@Component({
  name: "Register-user",
})
export default class RegisterUser extends Vue {
  @Prop() public currentStep;

  localUser: ILocalUser = { firstName: "", lastName: "", phoneNumber: "" };
  menu: boolean = false;
  modal: boolean = false;
  hasGottenUser: boolean = false;

  @Action updateUserProfile: Function;
  @Action getCurrentUser: Function;
  @Action postSnackbarMessage: (message: string) => void;
  @Getter user: IUser;

  @Action patchUser: (payload: Partial<IUser>) => Promise<IUser>;

  validateFormFields(): boolean {
    if (this.localUser.firstName === "") {
      this.postSnackbarMessage(this.$t("login.firstNameRequired"));
      return false;
    }
    if (this.localUser.lastName === "") {
      this.postSnackbarMessage(this.$t("login.lastNameRequired"));
      return false;
    }
    if (this.localUser.phoneNumber.length !== 8) {
      this.postSnackbarMessage(this.$t("login.phoneNumberRequired"));
      return false;
    }
    return true;
  }

  clickHandler(): void {
    this.$emit("register-user-click", this.validateFormFields());
  }

  @Watch("currentStep")
  checkElValue(newval, oldVal) {
    if (oldVal === 1) {
      let update: Partial<IUserDTO> = {
        firstName: this.localUser.firstName,
        lastName: this.localUser.lastName,
        phoneNumber: this.localUser.phoneNumber,
        //@ts-ignore, I have to fix reinforced typings - or just create my own god damn python script
        language: this.$i18n.locale,
      };

      if (window.location.toString().includes("citaro")) {
        //@ts-ignore
        update.defaultPhoneNumberCountryCode = 57; // set locale to Spanish if "citaro" is in the domain. as a temp solution for the new domain
        update.timeZone = "America/Bogota"; // set locale to Spanish if "citaro" is in the domain. as a temp solution for the new domain
        update.timeFormat = "12h"; // set locale to Spanish if "citaro" is in the domain. as a temp solution for the new domain
      }

      if (this.validateFormFields()) {
        //Information for initial staff member
        this.updateUserProfile(update);
      }
    }
  }
}
